import React, { useRef, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import ppo from 'ppo'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import FullLoading from '@/components/FullLoading'
import ChatRoom from '@/components/ChatRoom/index'
import FinishBtn from '@/components/FinishBtn/index'
import Request from '@/utils/request'
import { getUserInfo, getRoomInfo } from '@/store/actions'
import { Button, PageHeader, Tag } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import type { globalTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import type { chatRoomRefProps } from '@/components/ChatRoom/index.d'
import styles from './index.module.scss'

declare global {
  interface Window { 
    mozNotification: any,
    webkitNotification: any
  }
}

export type TalkPropsTypes = {
  match: {
    params: {
      roomId?: string
    }
  },
  locale: string,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
}

// 洽谈历史页面
const HistoryPage: React.FC<TalkPropsTypes> = (props) => {
  const dispatch = useDispatch()
  const {roomInfo, } = props
  let history = useHistory()
  const [initLoading, setInitLoading] = useState(true) // 页面初始化loading
  const { locale } = props
  const chatRoomRef = useRef<chatRoomRefProps>(null)

  useEffect(() => {
    document.title = intl.get('room_types.scheduled')
    const paramRoomId = props?.match?.params?.roomId
    if(paramRoomId){
      dispatch(
        getRoomInfo(paramRoomId, (data: roomInfoTypes) => {
          const token = ppo.getUrlParam('token')
          if(token){
            dispatch(getUserInfo(token, () => {
              // 登录成功
              setInitLoading(false)
              console.log('登录成功')
            }, () => {
              // 登录失败
              history.push({
                pathname: '/error',
                state: {
                  status: 'error',
                  title: intl.get('error.login_fail.title'),
                  subTitle: intl.get('error.login_fail.sub_title'),
                }
              })
            }))
          } else {
            //未登录
            history.push({
              pathname: '/error',
              state: {
                status: 'error',
                title: intl.get('error.no_login.title'),
                subTitle: intl.get('error.no_login.sub_title'),
              }
            })
          }
        }, () => {
          history.push({
            pathname: '/error',
            state: {
              status: 'error',
              title: intl.get('error.no_room.title'),
              subTitle: intl.get('error.no_room.sub_title'),
            }
          })
        })
      )
    }
  }, [])
  
  return (
    <div className={styles['history-page']}>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={intl.get('talk_history')}
        subTitle={props.match.params.roomId || ''}
        extra={initLoading ? [] :
        [
          <Tag
            color={roomInfo?.status === 0 ? "default" : "success"}
            key="1">
            {intl.get(`room_status.${roomInfo?.status}`)}
          </Tag>,
          <FinishBtn key="2">
            {intl.get("header.end_call")}
          </FinishBtn>,
        ]}
      />
      <div className={styles['page-main']}>
        {
          initLoading?
          <FullLoading size="large"/>:
          <>
            <ChatRoom
              ref={chatRoomRef}
              locale={locale}
              roomId={roomInfo?.roomId || ''}
              userId={''}
              isEnd={true}
              enterRoomTime={new Date().getTime()}
              sendMessage={() => {}}/>
          </>
        }
      </div>
    </div>
  )
}

const stateToProps = (state: {
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  global: globalTypes,
}) => {
  const { global, userInfo, roomInfo } = state
  return {
    global,
    locale: global.locale,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null)(HistoryPage)