import React, { useEffect } from 'react'
import intl from 'react-intl-universal'
import TRTC from 'trtc-js-sdk'
import type { deviceItemTypes } from './index.d'

// 获取设备列表
const getDeviceList = async (deviceType:string) => {
  let deviceList:deviceItemTypes[] = []
  switch (deviceType) {
    case 'camera':
      deviceList = await TRTC.getCameras()
      break
    case 'microphone':
      deviceList = await TRTC.getMicrophones()
      break
    case 'speaker':
      deviceList = await TRTC.getSpeakers()
      break
    default:
      break
  }
  return deviceList
};

export type DeviceDataPropsTypes = {
  deviceType: string,
  updateDeviceList: (list:deviceItemTypes[]) => void
}

const DeviceData:React.FC<DeviceDataPropsTypes> = (props) => {
  const { deviceType, updateDeviceList } = props
  
  const effectInit = async ():Promise<void> => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: deviceType === 'microphone', video: deviceType === 'camera' });
      mediaStream.getTracks()[0].stop()
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        alert(intl.get('video_talk.allow_equipment', {name: intl.get(`video_talk.${deviceType === 'microphone' ? 'microphone' : 'camera'}`)}))
      } else if (error.name === 'NotFoundError') {
        alert(intl.get('video_talk.check_equipment', {name: intl.get(`video_talk.${deviceType === 'microphone' ? 'microphone' : 'camera'}`)}))
      }
    }
    const list = await getDeviceList(deviceType)
    updateDeviceList && updateDeviceList(list)
  }

  useEffect(() => {
    effectInit()
  }, [])

  navigator.mediaDevices.addEventListener('devicechange', async () => {
    const newList = await getDeviceList(deviceType)
    updateDeviceList && updateDeviceList(newList)
  })

  return (
    <div></div>
  )
}

export default DeviceData
