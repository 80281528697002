import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import IconFont from '@/components/IconFont'
import Loading from '@/components/Loading'
import IconButton from '@/components/IconButton'
import Request from '@/utils/request'
import type { globalTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import { Space, Tag, Dropdown, Menu} from 'antd'

import styles from './index.module.scss'

type scheduleType = {
  talk: {
    start_at: string,
    end_at: string,
    room_url: string,
  },
  user: {
    name: string,
    name_en: string,
    company_name: string,
  }
}

// 今日洽谈日程
type NegotiateTodayProps = {
  locale?: string,
}
const NegotiateToday:React.FC<NegotiateTodayProps> = (props) => {
  const {locale} = props
  const [schedules, setSchedules] = useState<scheduleType[] | null>(null)

  const getCchedules = async () => {
    console.log('locale', locale)
    const {status, data} = await Request({
      url: `schedules`,
    })
    try {
      if(status && data){
        setSchedules(data)
      }
    } catch (error) {
      console.log('getCchedules-error', error)
    }
  }
  const visibleChange = (visible: boolean) => {
    if(visible){
      getCchedules()
    } else {
      setSchedules(null)
    }
  }

  return(
    <>
      <Dropdown
        overlayStyle={{
          width: 300,
        }}
        trigger={["click", "hover"]}
        overlay={
          <Menu>
            {
              !schedules?
              <Menu.Item key="loading">
                <Loading style={{
                  display: 'block',
                  margin: '10px auto',
                }}/>
              </Menu.Item>:
              <>
                {
                  !schedules.length ?
                  <Menu.Item key="empty" style={{textAlign: 'center'}}>{intl.get('no_negotiate_today')}</Menu.Item>:
                  schedules.map((item: scheduleType, index:number) => {
                    return(
                      <Menu.Item
                        key={`id_${index}`}
                        style={{whiteSpace: 'initial'}}>
                        <div className={styles['schedule-item']}>
                          <Space className={styles['item__tip']} direction="vertical" size={4}>
                            {
                              index === 0 &&
                              <Tag color="magenta">Next</Tag>
                            }
                            <span className={styles['item__time']}>{dayjs(item.talk.start_at).format('HH:mm')}-{dayjs(item.talk.end_at).format('HH:mm')}</span>
                          </Space>
                          <div className={styles['item__info']}>
                            <h3 className={styles['user__name']}>{locale === 'en-US' ? (item.user?.name_en || item.user?.name) : item.user?.name}</h3>
                            <p className={styles['user__company']}>{item.user?.company_name}</p>
                          </div>
                        </div>
                      </Menu.Item>
                    )
                  })
                }
              </>
            }
          </Menu>
        }
        onVisibleChange={visibleChange}
      >
        <Space>
          <IconButton
            icon={<IconFont type="icon-rili"/>}
            name={intl.get('header.negotiate_today')}/>
        </Space>
      </Dropdown>
    </>
  )
}

const stateToProps = (state: {
  roomInfo: roomInfoTypes,
  userInfo: userInfoTypes,
  global: globalTypes,
}) => {
  const { userInfo, roomInfo, global } = state
  return {
    locale: global.locale,
    userInfo,
    members: roomInfo?.members || [],
  }
}

export default connect(stateToProps, null)(NegotiateToday)
