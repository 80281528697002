import intl from 'react-intl-universal'
import React, { useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'
import IconFont from '@/components/IconFont'
import { Tooltip, Space } from 'antd'
import type { userInfoTypes } from '@/store/store.d'
import type { StreamTypes, ConfigTypes, defaultBarTypes } from '../index.d'

import styles from './streamBar.module.scss'

const defaultBar = {
  hasVideo: true, // 视频
  hasAudio: true, // 音频
  subscribedAudio: true, // 话筒
  subscribedVideo: true, // 视图
  full: true, // 全屏
}

export type  StreamBarPropsTypes = {
  className?: string,
  userInfo?: userInfoTypes,
  hasPPT: boolean | string,
}

const StreamBar:React.FC<StreamBarPropsTypes> = (props) => {
  const { userInfo, hasPPT} = props

  return (
    <div className={props.className}>
      <div className={clsx(styles['bar'], hasPPT ? styles['bar__mini'] : '')}>
        {
          userInfo?
          <div className={styles['user-info']}>
            <div className={styles['user-name']}>{userInfo.name}
            {
              userInfo.type ?
              `(${intl.get(`user_type.${userInfo.type}`)})`:
              null
            }
            </div>
            <div className={styles['user-company']}>{userInfo.company}</div>
          </div>:
          null
        }
      </div>
    </div>
  )
}

export default StreamBar
