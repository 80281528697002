import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import intl from 'react-intl-universal'
import clsx from 'clsx'
import Loading from '@/components/Loading'
import FullLoading from '@/components/FullLoading'
import IconFont from '@/components/IconFont'
import FileCard from '@/components/ChatRoom/FileCard'
import BusinessCardDrawer from '@/components/BusinessCard/BusinessCardDrawer'
import type {businessCardDrawerRefProps} from '@/components/BusinessCard/index.d'
import { Button, Tooltip, Image, Switch, Space, Select, message } from 'antd'
import Chat, { Icon, Bubble, Divider, useMessages } from 'kermityu_chatui'
import { CheckCircleOutlined } from '@ant-design/icons'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import { getMd5, formatChatMsg, getObjectValue } from '@/utils'
import Request from '@/utils/request'
import emojis from '@/utils/emojis'
import type { langTypes, chatRoomRefProps } from './index.d'
import type { contentTypes, messageTypes, sendInfoTypes, chatRoomMsgTypes } from '@/utils/IMSService/index.d'
import type { MessageProps } from 'kermityu_chatui/lib/components/Message/Message.d'
// 引入 ChatUI 样式
import 'kermityu_chatui/es/styles/index.less'
import 'kermityu_chatui/dist/index.css'
// 引入定制的样式
import './chatui-theme.css'
import styles from './chatRoom.module.scss'

const { Option } = Select

export type ChatRoomProps = {
  ref: React.ReactNode,
  locale: string,
  isEnd?: boolean,
  roomId: string,
  userId: number | string,
  enterRoomTime: number,
  sendMessage: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
  navbarTop?: React.ReactNode,
}

// 表情key类型
export type emojiKeyType = keyof typeof emojis

declare type MessagesTypes = chatRoomMsgTypes[];

// 聊天室
const ChatRoom = forwardRef<chatRoomRefProps, ChatRoomProps>((props, childRef) => {
  // 可翻译语言
  const ALL_LANGS:langTypes[] = [
    {
      "key": "close",
      "name": intl.get('chat_room.close_tanslate')
    },
    {
      "key": "zh",
      "name": "中文"
    },
    {
      "key": "en",
      "name": "English"
    },
    {
      "key": "jp",
      "name": "日本語"
    },
    {
      "key": "de",
      "name": "Deutsch"
    }
  ]
  const { locale, navbarTop, sendMessage, roomId, userId, enterRoomTime, isEnd} = props
  const [pageInfo, setPageInfo] = useState<{
    page: number,
    curPage: number,
    pageSize: number,
    total: number | null,
  }>({
    page: 1,
    curPage: 1,
    pageSize: 20,
    total: null,
  }) // 历史消息分页信息
  const [uploadingImg, setUploadingImg] = useState(false) // 上传图片中
  const [uploadingFile, setUploadingFile] = useState(false) // 上传文件
  const [emojiOpen, setEmojiOpen] = useState(false) // 表情选择框
  const [targetLang, setTargetLang] = useState(localStorage.getItem(`targetLang_${roomId}`) || ALL_LANGS[0].key) // 翻译信息
  const { messages, prependMsgs, resetList, updateMsg, appendMsg, setTyping } = useMessages([])

  const imageEl = useRef<HTMLInputElement>(null)
  const fileEl = useRef<HTMLInputElement>(null)
  const messagesEl = useRef(null)
  const businessCardDrawerRef = useRef<businessCardDrawerRefProps>(null)

  useImperativeHandle(childRef, () => ({
    // 接受新消息
    acceptMessage: (msgInfo: sendInfoTypes) => {
      if(msgInfo){
        console.log('接收到新消息===msgInfo', msgInfo)
        appendMsg(formatChatMsg(msgInfo, userId, messages.length ? messages[messages.length -1].createdAt : 0))
      }
    },
    // 接受新消息
    prependMsgs: (msgs: sendInfoTypes[]) => {
      if(msgs && msgs.length){
        console.log('插入历史消息===msgs', msgs)
        prependMsgs(msgs.map((msg: sendInfoTypes)=> formatChatMsg(msg, userId, messages.length ? messages[messages.length -1].createdAt : 0)))
      }
    }
  }))

  // 翻译目标语言更改
  const targetChange = (value:string) => {
    console.log('目标语言更改', value)
    localStorage.setItem(`targetLang_${roomId}`, value)
    setTargetLang(value)
  }

  // 渲染头部
  const renderNavbar = () => {
    return(
      <>
        {
          navbarTop
        }
        <header className={styles.navbar}>
          <Space className={styles['navbar-right']} align="center">
            <div className={styles['translate-btn']}>
              <IconFont type="icon-tanlate" size={16}/>
              <span className={styles['translate-label']}>{intl.get('chat_room.target_language')}:</span>
              <Select
                defaultValue={targetLang}
                onChange={targetChange}
                bordered={false}>
                {
                  ALL_LANGS.map((item:langTypes) => {
                    return <Option key={item.key} value={item.key}>{item.name}</Option>
                  })
                }
              </Select>
            </div>
          </Space>
        </header>
      </>
    )
  }

  // 渲染消息
  const renderChatMsg = (msg: chatRoomMsgTypes) => {
    // 根据消息类型来渲染
    const { type, content, user } = msg
    const { text } = content
    switch (type) {
      case msgTypes.CHAT_TEXT:
        return(
          <Space direction="vertical">
            <Bubble content={text} />
            {
              msg?.extend?.tanlate && msg?.extend?.tanlate.target?
              <Bubble>
                {
                  !msg?.extend?.tanlate.text?
                  <div style={{display:'flex', alignItems: 'center'}}>
                    <Loading style={{display: 'flex', alignItems: 'center'}} size="small"/>
                    {intl.get('chat_room.tanslate.loading')}
                  </div>:
                  <>
                    <div>{msg?.extend?.tanlate.text}</div>
                    <div>
                      <CheckCircleOutlined style={{color: '#52c41a'}}/>
                      <span style={{fontSize: 12, marginLeft:4, color: '#999'}}>{intl.get('chat_room.tanslate.complete')}</span>
                    </div>
                  </>
                }
              </Bubble>:
              null
            }
          </Space>
        )
      case msgTypes.CHAT_EMOJI:
        const emoji = emojis[text as emojiKeyType]
        return (
          <Bubble type="image">
            <img src={emoji} alt={text} className={clsx(styles['emoji'], styles['__msg'])}/>
          </Bubble>
        )
      case msgTypes.CHAT_IMG:
        return (
          <Bubble type="image">
            <Image src={content?.fileUrl} style={{lineHeight:0, fontSize: 0}}/>
          </Bubble>
        )
      case msgTypes.CHAT_FILE:
        return (
          <FileCard fileName={content.fileName} fileSize={content.fileSize}>
            <a href={content.fileUrl} target="_blank">{intl.get('chat_room.download')}</a>
          </FileCard>
        )
      default:
        return null
    }
  }

  // 渲染消息
  const renderMessageContent = (msg:chatRoomMsgTypes) => {
    const {user, sender, content, type} = msg
    if(user && Object.keys(user).length && content){
      return(
        <div className={clsx(styles['msg-item'], styles[`___${msg.position}`])}>
          <p className={styles['__user-name']}>{user?.name}</p>
          { renderChatMsg(msg) }
        </div>
      )
    } else {
      const isBusinessCardMsg = [msgTypes.BUSINESS_CARD_APPLY, msgTypes.BUSINESS_CARD_AGREE].includes(type)
      return <Divider
        className={styles['msg-divider']}>
        {intl.get(`chat_room.${type}`, {who: isBusinessCardMsg ? content.sendName : (sender?.name || '')})}
        {
          isBusinessCardMsg &&
          <Button
            type="link"
            size="small"
            onClick={() => openCard(content.sendCtaId)}
            style={{fontSize: 12}}>
            {intl.get(`business_card.actions.${type}`)}
          </Button>
        }
      </Divider>
    }
  }

  // 发送消息
  const sendMsg = (msgType: string, content: contentTypes) => {
    setTyping(true)
    sendMessage({
      msgType,
      content,
    }, (msgInfo:sendInfoTypes | false) => {
      setTyping(false)
      if(msgInfo){
        appendMsg(formatChatMsg(msgInfo, userId, messages.length ? messages[messages.length -1].createdAt : 0))
      }
    })
  }

  // 发送文字消息
  const handleSend = (type:string, val:string) => {
    if (type === 'text' && val.trim()) {
      sendMsg(msgTypes.CHAT_TEXT, {
        text: val
      })
    }
  }

  // 发送表情消息
  const sendEmojiMsg = (e:any, emojiKey:string) => {
    if(emojiKey){
      setEmojiOpen(!emojiOpen)
      sendMsg(msgTypes.CHAT_EMOJI, {
        text: emojiKey
      })
    }
  }

  // 发送图片
  const handleSendImage = async (file: File) => {
    uploadFile('image', file)
  }

  //文件变化
  const fileChange = (event:any, type:string) => {
    const target = event.target
    const file = target.files[0]
    console.log('file', file, 'type', type)
    file && uploadFile(type, file)
  }

  // 上传文件
  const uploadFile = async (type: string, file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    type === 'image' ? setUploadingImg(true) : setUploadingFile(true)
    formData.append('file_type', type)
    console.log('formData', formData, formData.values())
    const { status, data } = await Request({
      method: 'POST',
      url: 'attach/upload',
      data: formData,
    })
    if(type === 'image'){
      setUploadingImg(false)
      if(imageEl.current) imageEl.current.value = '';
    } else {
      setUploadingFile(false)
      if(fileEl.current) fileEl.current.value = '';
    }
    if(status && data){
      sendMsg(type === 'image' ? msgTypes.CHAT_IMG : msgTypes.CHAT_FILE, {
        fileUrl: data.fileUrl,
        fileExt: data.fileExt,
        fileName: data.fileName,
        fileSize: data.fileSize,
      })
    } else {
      message.error(intl.get('chat_room.upload_fail'))
    }
  }

  // 打开表情选择
  const triggerEmoji = () => {
    setEmojiOpen(!emojiOpen)
  }

  // 渲染工具条
  const renderToolbar = () => {
    return(
      <div className={styles['toolbar']}>
        <div className={styles['toolbar-btn']} onClick={triggerEmoji}>
          <IconFont className={styles['toolbar-icon']} type="icon-emoji" size={24}/>
        </div>
        <div className={styles['toolbar-btn']}>
          <IconFont className={styles['toolbar-icon']} type="icon-img" size={24}/>
          <input
            className={styles['upload-input']}
            ref={imageEl}
            type="file"
            accept=".jpg, .jpeg, .png,"
            onChange={(e)=>{fileChange(e, 'image')}}/>
          {
            uploadingImg?
            <div className={styles['uploading']}>
              <FullLoading />
            </div>:
            null
          }
        </div>
        <div className={styles['toolbar-btn']}>
          <IconFont className={styles['toolbar-icon']} type="icon-file" size={24}/>
          <input
            className={styles['upload-input']}
            ref={fileEl}
            type="file"
            accept=".pdf,.pptx,.ppt,.xls,.xlsx,.doc,.docx"
            onChange={(e)=>{ fileChange(e, 'file') }} />
          {
            uploadingFile?
            <div className={styles['uploading']}>
              <FullLoading />
            </div>:
            null
          }
        </div>
        <div className={clsx(styles['emojis'], emojiOpen ? styles['__show'] : '')}>
          {
            Object.keys(emojis).map(key => {
              const item = emojis[key as emojiKeyType]
              return <div className={styles['emoji_item']} key={key}>
                <img src={item} alt="" className={styles['emoji']} onClick={e => sendEmojiMsg(e, key)}/>
              </div>
            })
          }
        </div>
      </div>
    )
  }

  // 翻译消息
  const tanlateMsg = async (target: string, curMsg: chatRoomMsgTypes, callback?:() => void) => {
    // console.log('curMsg', curMsg)
    updateMsg(curMsg._id, {
      ...curMsg,
      extend: {
        tanlate: {
          target,
        }
      }
    })
    // curmsg?.extend?.tanlate?.target = target
    const { data } = await Request({
      method: 'POST',
      url: 'translation/translate',
      data: {
        target,
        text: curMsg?.content?.text
      }
    })
    updateMsg(curMsg._id, {
      ...curMsg,
      extend: {
        tanlate: {
          target,
          text: data.content
        }
      }
    })
    callback && callback()
  }

  // 加载历史消息
  const getHistoryMsgs = async () => {
    console.log('handleRefresh')
    const { status, data } = await Request({
      method: 'GET',
      url: `${roomId}/messages`,
      params: {
        page: pageInfo.page,
        per_page: pageInfo.pageSize,
        message_date: enterRoomTime,
      }
    })
    // console.log('getHistoryMsgs', status, data)
    try {
      if(status){
        let msgs = data.data.filter((msg: sendInfoTypes) => {
          const msgType = msg.body.message.msgType
          return ![msgTypes.SYS_TEXT, msgTypes.PPT_SWITCH, msgTypes.PPT_START, msgTypes.PPT_END, msgTypes.ENTER_ROOM].includes(msgType)
        })
        msgs = msgs.map((msg: sendInfoTypes, index: number) => {
          const preMsg = index - 1 >= 0 ? msgs[index - 1].header.createdAt : 0
          return formatChatMsg(msg, userId, preMsg)
        })
        console.log('msgs', msgs)
        prependMsgs(msgs)
        setPageInfo(Object.assign({}, pageInfo, {
          curPage: data.current_page,
          page: pageInfo.page + 1,
          total: data.total
        }))
      }
    } catch (error) {
      console.log('getHistoryMsgs-error', error)
    }
  }

  const openCard = (ctaId:number) => {
    businessCardDrawerRef.current?.open(ctaId)
  }

  useEffect(()=> {
    getHistoryMsgs()
  }, [])

  // 监听变化
  useEffect(() => {
    if(targetLang !== ALL_LANGS[0].key){
      const hasTargeting = messages.find((msg: chatRoomMsgTypes) => msg?.extend?.tanlate && msg?.extend?.tanlate?.target && !msg?.extend?.tanlate?.text);
      if(!hasTargeting) {
        const curMsg = messages.find((msg: chatRoomMsgTypes) => msg.position === 'left' && msg.type === msgTypes.CHAT_TEXT && (!msg?.extend?.tanlate || msg?.extend?.tanlate?.target !== targetLang || !msg?.extend?.tanlate?.text));
        console.log('curMsg===messages', curMsg)
        curMsg && tanlateMsg(targetLang, curMsg)
      }
    }
  }, [targetLang, messages])

  return(
    <div className={clsx('chat-box', isEnd ? 'isEnd' : null)}>
      <Chat
        locale={locale}
        messagesRef={messagesEl}
        onRefresh={getHistoryMsgs}
        loadMoreText={(pageInfo.total === null || pageInfo?.total > pageInfo?.curPage * pageInfo?.pageSize) ? intl.get('chat_room.load_history_msg') : ''}
        renderNavbar={!isEnd ? renderNavbar : () => <></>}
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        onImageSend={handleSendImage}
        renderQuickReplies={!isEnd ? renderToolbar : () => <></>}
        placeholder={intl.get('chat_room.input_placeholder')}
      />
      <BusinessCardDrawer ref={businessCardDrawerRef}/>
    </div>
  )
})

export default ChatRoom;

