import axios from 'axios';
import { API_DOAMIN } from '@/config/index';
import {message} from 'antd';
import { getLanguage } from '@/utils'

export type resOptionsTypes = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  params?: {
    [prop:string]: any
  },
  data?: any,
}

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(
  // token
  function(config:any) {
    const token = sessionStorage.getItem("API_TOKEN")
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const Request = async function(options: resOptionsTypes) {
  let lang:any = getLanguage()
  switch(lang){
    case 'zh-CN':
      lang = 'zh'
      break;
    case 'en-US':
      lang = 'en'
      break;
    case 'ja-JP':
      lang = 'jp'
      break;
    case 'zh-hant':
      lang = 'zh_hant'
      break;
    default:
      lang = 'zh'
      break;
  }
  if(options.params){
    options.params.lang = lang
  } else {
    options.params = {lang}
  }
  if(options.data){
    if(Object.prototype.toString.call(options.data) != '[object FormData]'){
      options.data.lang = lang
    } else {
      options.data.append('lang', lang)
    }
  } else {
    options.data = {lang}
  }
  // console.log('options.params', options.params)
  // console.log('options.data', options.data)
  const response = await axios({
    method: options.method || 'GET',
    url: `${API_DOAMIN}api/v1/${options.url}`,
    params: options.params, //get 请求的参数
    data: options.data, //post 请求的参数
  });
  try {
    // console.log('response', response);
    if (response.data) {
      return response.data;
    }
  } catch (error:any) {
    // console.log('request -> error', error);
    message.error(error?.message || '')
  }
};

export default Request;
