import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import ppo from 'ppo'
import Request from '@/utils/request'
import { TALK_TYPE_PSA } from '@/config/index'

export type CheckTalkStateProps = {
  talkType: string,
  roomId?: string,
};

export type checkTalkStateRefProps = {
  stopCheck: () => void,
  startCheck: () => void,
}

let timer:any = null
let checkflag = true //是否检查
const CheckTalkState = forwardRef<checkTalkStateRefProps, CheckTalkStateProps>((props, checkTalkRef) => {
  const { talkType, roomId } = props;

  useImperativeHandle(checkTalkRef, () => ({
    // 停止计时检查
    stopCheck: () => {
      checkflag = false
      clearTimer()
    },
    startCheck: () => {
      checkflag = true
      checkState()
    }
  }))

  // 检查状态
  const checkState = async () => {
    // console.log('getTalkState')
    let params: {
      chatType: string,
      roomId?: string,
    } = {
      chatType: talkType
    }
    if(talkType === TALK_TYPE_PSA ){
      params.roomId = roomId
    }
    await Request({
      method: 'POST',
      url: `check`,
      data: params
    })
    checkflag && createTimer()
    try {
    } catch (error) {
      console.log('checkState -> error', error)
    }
  }

  // 清除定时器
  const clearTimer = () => {
    if(timer){
      window.clearTimeout(timer)
      timer = null
    }
  }

  const createTimer = () => {
    clearTimer()
    timer = setTimeout(() => {
      checkState()
    }, 5000)
  }

  useEffect(() => {
    checkState()
    return clearTimer
  }, [])

  return <></>;
})

export default CheckTalkState;
