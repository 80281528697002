import Error from '@/pages/error'
import Index from '@/pages/index/index'
import SubRoutes from '@/components/SubRoutes'
import TalkRoom from '@/pages/talk/room/index'
import Walkin from '@/pages/walkin/index'
import History from '@/pages/history/index'
import Verify from '@/pages/verify/Verify'
import PreviewCustomerJoin from '@/pages/preview_customer_join/index'
import WebviewTrtcAudio from '@/pages/lab/WebviewTrtcAudio'
import DesktopEntrance from '@/pages/desktop/DesktopEntrance'

// 页面路由数据
const routes = [
  {
    path: '/',
    exact:  true,
    component: Index,
  },
  {
    path: '/talk',
    component: SubRoutes,
    routes: [
      {
        path: '/talk',
        exact: true,
        component: Error,
      },
      {
        path: '/talk/:roomId',
        exact: true,
        component: TalkRoom,
      },
    ],
  },
  {
    path: '/walkin',
    exact: true,
    component: Walkin,
  },
  {
    path: '/verify',
    exact: true,
    component: Verify,
  },
  {
    path: '/preview_customer_join',
    exact: true,
    component: PreviewCustomerJoin,
  },
  {
    path: '/history/:roomId',
    exact: true,
    component: History,
  },
  {
    path: '/lab',
    component: SubRoutes,
    routes: [
      {
        path: '/lab/webviewlab',
        exact: true,
        component: WebviewTrtcAudio,
      }
    ]
  },
  //electron构建的桌面端
  {
    path: '/desktop',
    component: SubRoutes,
    routes: [
      {
        path: '/desktop/entrance',
        exact: true,
        component: DesktopEntrance,
      }
    ]
  },
  {
    path: '*',
    component: Error
  },
];

export default routes;