import React from 'react'
import clsx from 'clsx'
import intl from 'react-intl-universal'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import type { Swiper as SwiperClass} from 'swiper/types/index.d'
import { FreeMode, Scrollbar, Mousewheel, Navigation } from 'swiper'
import IconFont from '@/components/IconFont'
import UserType from '@/components/UserType/index'
import type { roomType } from '@/store/store.d'
import { Avatar, Empty, Badge } from 'antd'
import 'swiper/swiper.scss'
import "swiper/modules/free-mode/free-mode.scss"
import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/scrollbar/scrollbar.scss"
import styles from './index.module.scss'

export type RoomListPropsTypes = {
  rooms: roomType[],
  roomId: string,
  switchRoom: (roomId: string) => void,
}

const RoomList:React.FC<RoomListPropsTypes> = (props) => {
  const { rooms, roomId, switchRoom} = props

  return <div className={styles['rooms-list']}>
    <h3 className={styles['rooms-header']}>
      <IconFont type="icon-chat" />
      <span className={styles['header-title']}>{intl.get('walkin.rooms_list')}</span>
    </h3>
    <div className={styles['rooms-body']}>
      <Swiper
        modules={[Navigation, FreeMode, Scrollbar, Mousewheel]}
        direction="vertical"
        slidesPerView="auto"
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        className={styles['scroll-box']}>
        <SwiperSlide className={styles['scroll-slide']}>
          {
            rooms && rooms.length?
            rooms.map((item: roomType, index) => {
              return(
                <div className={clsx(styles['room-item'], item.room_id === roomId ? styles['__cur'] : null)} 
                  key={item.room_id}
                  onClick={() => switchRoom(item.room_id)}>
                  {
                    item?.msgsNum?
                    <Badge count={item?.msgsNum} overflowCount={10}>
                      <Avatar size={54} src={item.user.avatar} />
                    </Badge>:
                    <Avatar size={54} src={item.user.avatar} />
                  }
                  <div className={styles['user-info']}>
                    <div className={styles['user-hd']}>
                      <span className={styles['user-name']}>{item.user.name}</span>
                      <UserType type={item.user.type} size="small"/>
                    </div>
                    <div className={styles['user-ft']}>
                      <span className={styles['user-company']}>{item.user.company}</span>
                    </div>
                  </div>
                </div>
              )
            }):
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
}

export  default RoomList