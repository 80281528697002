import ppo from 'ppo'
import type { initParamsTypes, sendInfoTypes, noticeItemTypes } from './index.d'

const SDK_VERSION = '1.0.1' // sdk版本
const CLIENT_ID = ppo.uuid() // 生成唯一的客户端id
const INTERVALS_CONNECT =  10 * 1000 // 检查间隔时间
const INTERVALS_PING_PONG =  20 * 1000 // ping_pong间隔时间
// 提示消息
const NOTICE: {
  [prop: string]: noticeItemTypes
} = {
  SOCKET_OPEN: {
    code: 1,
    msg: '连接成功'
  },
  SOCKET_INIT: {
    code: 2,
    msg: '连接初始化成功'
  },
  SOCKET_RECONNECT: {
    code: 4,
    msg: '重新连接'
  },
  SOCKET_LOSE: {
    code: -2,
    msg: '断开连接,重连中' //登录timeout
  },
  SOCKET_LOSE_PING_OUT: {
    code: -3,
    msg: '断开连接,重连中, ping timeout' //ping out
  },
  SOCKET_LOSE_CLOSE: {
    code: -4,
    msg: 'socket 断开'
  },
}

export const sdkVersion = SDK_VERSION
export const clientId = CLIENT_ID

// 连接心跳检测
class pingPongManager {
  timeoutObj:any
  socketManager

  constructor(socketManager:any) {
    this.socketManager = socketManager
  }

  // 重置
  reset = () => {
    this.timeoutObj && clearTimeout(this.timeoutObj)
    this.start()
  }

  // 开始
  start = () => {
    this.timeoutObj = setTimeout(() => {
      this.socketManager.listeners.onConnectNotify(NOTICE.SOCKET_LOSE_PING_OUT)
      this.socketManager.socket && this.socketManager.socket.close()
      this.socketManager.connect()
    }, INTERVALS_PING_PONG)
  }

  // 停止
  stop = () => {
    this.timeoutObj && clearTimeout(this.timeoutObj);
  }
}

// socket管理中心
class ImsManager {
  socketUrl: string = '' //socket地址
  roomId: string = '' // 加入房间的roomid
  SESSION_ID:any // 用户sessionId
  SESSION_ID_GET_TIME:any// 用户sessionId获取时间
  socket:any
  connectTimer:any
  loginInfo = {
    loginPath: {},
    userInfo: {}
  }
  listeners = {
    //连接通知
    onConnectNotify: (notice:any) => {
    },
    //消息通知
    onMsgNotify: (newList:any) => {
    }
  }

  //成功
  okCallback = (res:any, sessionId: string) => {
  }
  // 失败
  failCallback = (res:any) => {
  }

  // 配置项
  options = {
    debug: true,
    logOn: true,
  }

  pingPongManager = new pingPongManager(this)

  constructor (params: initParamsTypes) {
    const { socketUrl, listeners, options } = params
    if(socketUrl){
      this.socketUrl = socketUrl
      if (listeners) this.listeners = Object.assign({}, this.listeners, listeners)
      if (options) this.options = Object.assign({}, this.options, options)
      this.connect()
    }
  }
  // 重连
  resetSocket = () => {
    if (this.socket) {
      this.socket.onmessage = () => {}
      this.socket.onclose = () => {}
      this.socket.onerror = () => {}
      this.socket.onopen = () => {}
    }
  }

  // 链接
  connect = () => {
    this.resetSocket()
    this.socket = new WebSocket(this.socketUrl)
    this.socket.onmessage = this.onMessage
    this.socket.onclose = this.onClose
    this.socket.onerror = this.onError
    this.socket.onopen = this.onOpen
    // this.connectTimer && clearTimeout(this.connectTimer)
    // this.connectTimer = setTimeout(() => {
    //   //过5s后没有连上socket
    //   if (this.checkSocketReadyState() !== WebSocket.OPEN) {
    //     this.socket && this.socket.close()
    //     this.listeners.onConnectNotify && this.listeners.onConnectNotify(NOTICE.SOCKET_LOSE)
    //     this.connect()
    //   }
    // }, INTERVALS_CONNECT)
  }

  // 停止
  stop = () => {
    this.SESSION_ID = null
    this.SESSION_ID_GET_TIME = null
    // 关闭socket
    this.socket && this.socket.close()
    // 关闭ping
    this.pingPongManager.stop()
  }

  // 检查socket状态
  checkSocketReadyState = () => {
    return this.socket && this.socket.readyState
  }

  // 发送消息
  sendMsg = (sendInfo:sendInfoTypes, callback: (msgInfo: sendInfoTypes | false) => void) => {
    if (this.checkSocketReadyState() === WebSocket.OPEN) {
      const { header, body } = sendInfo
      const { sender, message} = body
      const sendMsgInfo = {
        header:{
          msgId: ppo.uuid(), // 生成消息的唯一标识的消息id,
          version: SDK_VERSION, //版本
          roomId: header.roomId,  //房间ID
          path: header.path,  //消息路径
          createdAt: new Date().getTime(), // 发送消息的时间
          clientId: CLIENT_ID, // 客户端id
          isCurClient: true, // 是否是当前客户端发送的消息
        },
        body:{
          device: ppo.ua('lower'),
          sender,
          message,
        }
      }
      this.socket.send(JSON.stringify(sendMsgInfo))
      callback && callback(sendMsgInfo)
    } else {
      callback && callback(false)
    }
  }

  // 监听socket连接成功
  onOpen = (event:any) => {
    this.pingPongManager.reset()
    this.listeners.onConnectNotify && this.listeners.onConnectNotify(NOTICE.SOCKET_OPEN)
  }

  // 监听发送消息
  onMessage = (event:any) => {
    let msg = JSON.parse(event.data)
    this.pingPongManager.reset()
    switch (msg.type) {
      case 'ping':
        if (this.checkSocketReadyState() === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({
            type: 'pong'
          }))
        }
        break
      case 'init':
        this.listeners.onConnectNotify && this.listeners.onConnectNotify(Object.assign(NOTICE.SOCKET_INIT, {
          msg
        }))
        break
      default:
        msg.header.isCurClient = msg?.header?.clientId === CLIENT_ID
        this.listeners.onMsgNotify && this.listeners.onMsgNotify(msg)
    }
  }

  // 监听错误
  onError = (err:any) => {
    console.log(err, 'onerror')
    this.resetSocket()
  }

  // 关闭
  onClose = () => {
    this.listeners.onConnectNotify && this.listeners.onConnectNotify(NOTICE.SOCKET_LOSE_CLOSE);
  }
}

export default class IMSService {
  imsManager: any
  constructor(params: initParamsTypes) {
    this.imsManager = new ImsManager({
      ...params
    })
  }
  // 登录
  login = () => {

  }
  // 停止
  stop = () => {
    this.imsManager.stop()
  }
  // 发送消息
  sendMessage = (sendInfo:sendInfoTypes, callback:(msgInfo:sendInfoTypes | false) => void) => {
    this.imsManager.sendMsg(sendInfo, callback)
  }
}