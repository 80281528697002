import React, { useRef, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import ppo from 'ppo'
import TrtcAudio from '@/components/VideoTalk/TrtcAudio'
import PreEnter from '@/components/VideoTalk/PreEnter'
import type { globalTypes, videoTalkTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import type { videoTalkRefProps } from '@/components/VideoTalk/index.d'
import { message as antdMessage, Modal, Alert, Button } from 'antd'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import clsx from 'clsx'

const { confirm, info } = Modal

declare global {
  interface Window { 
    mozNotification: any,
    webkitNotification: any
  }
}

export type TalkPropsTypes = {
  match: {
    params: {
      roomId?: string
    }
  },
  locale: string,
  global: globalTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  videoTalk: videoTalkTypes,
}
let walkinUrl = '' // walkin链接地址
let nextTalkUrl = '' // 下场洽谈地址

// 洽谈页面
const WebviewTrtcAudio: React.FC<TalkPropsTypes> = (props) => {
  const { global, locale, userInfo, videoTalk, roomInfo } = props
  const videoTalkRef = useRef<videoTalkRefProps>(null)

  const sendMsg = () => {

  }

  return (
    <>
      {
        !videoTalk?.initState ?
        <PreEnter />:
        <TrtcAudio
          ref={videoTalkRef}
          userID={ppo.uuid()}
          roomID={'webview-trtc-audio'}
          userInfo={userInfo}
          videoTalk={videoTalk}
          roomMembers={[]}
          sendMessage={sendMsg}/>
      }
    </>
  )
}

const stateToProps = (state: {
  videoTalk: videoTalkTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  global: globalTypes,
}) => {
  const { global, videoTalk, userInfo, roomInfo } = state
  return {
    global,
    locale: global.locale,
    videoTalk,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null)(WebviewTrtcAudio)