import React, { useRef, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import ppo from 'ppo'
import dayjs from 'dayjs'
import clsx from 'clsx'
import FullLoading from '@/components/FullLoading'
import VideoTalk from '@/components/VideoTalk'
import AnonymousModal from '@/components/AnonymousModal'
import RoomList from '@/components/RoomList/index'
// import UserDetail from '@/components/UserDetail/index'
import BusinessCardDetail from '@/components/BusinessCard/BusinessCardDetail'
import Header from '@/components/Header/index'
import ControlBar from '@/components/VideoTalk/ControlBar'
import ChatRoom from '@/components/ChatRoom/index'
import IMSService from '@/utils/IMSService/index'
import EventCenter from '@/utils/IMSService/EventCenter'
import Request from '@/utils/request'
import { WS_URL, TALK_TYPE_WALKIN, CLIENT_DESKTOP_APP } from '@/config/index'
import {bindClientId, sendMessage} from '@/utils/socketMessage'
import CheckTalkState from '@/components/CheckTalkState'
import type { checkTalkStateRefProps } from '@/components/CheckTalkState'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import { getUserInfo, getRoomInfo, setRoomInfo, resetVideoTalkState, setRoomVidoeState, setPPTSate, setGlobalClientId } from '@/store/actions'
import type { globalTypes, videoTalkTypes, userInfoTypes, roomInfoTypes, roomType } from '@/store/store.d'
import type { messageTypes, sendInfoTypes, noticeItemTypes } from '@/utils/IMSService/index.d'
import type { AnonymousModalRefProps } from '@/components/AnonymousModal/index.d'
import type { videoTalkRefProps } from '@/components/VideoTalk/index.d'
import type { chatRoomRefProps } from '@/components/ChatRoom/index.d'
import type { BusinessCardDetailRefProps } from '@/components/BusinessCard/BusinessCardDetail'
import { Alert, message as antdMessage, Modal, Button  } from 'antd'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import styles from './index.module.scss'

const { confirm } = Modal

declare global {
  interface Window { 
    mozNotification: any,
    webkitNotification: any
  }
}

let chatIMSService:any; // 聊天服务

export type TalkPropsTypes = {
  match: {
    params: {
      roomId?: string
    }
  },
  locale: string,
  global: globalTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  videoTalk: videoTalkTypes,
}

let storeRoomInfo:roomInfoTypes | null = null; //暂存房间信息
let acceptModal = false; // 视频邀请弹层
let imsInit = false; // ims服务初始化
let finishModal = false; // 洽谈结束弹层
let willSwitchRoomId:string | null = null; //将要切换到的房间id
let storeRooms:roomType[] | null = null; //暂存房间列表
let storeUserInfo: userInfoTypes | null = null; //暂存用户信息
// 洽谈页面
const TalkRoom: React.FC<TalkPropsTypes> = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { global, locale, userInfo, videoTalk, roomInfo } = props
  const [initLoading, setInitLoading] = useState(true) // 页面初始化loading
  // const [finishModal, setFinishModal] = useState(false) // 会话结束弹层
  const [enterRoomTime, setEnterRoomTime] = useState(new Date().getTime()) // 自己进入房间时服务端时间
  const [rooms, setRooms] = useState<roomType[]>([]) //用户的房间列表
  const [curRoom, setCurRoom] = useState<roomType | null>(null) //当前所在房间
  const [curRoomUser, setCurRoomUser] = useState<userInfoTypes | null>(null) //当前所在房间用户信息
  const [roomInit, setRoomInit] = useState(false) // 房间初始化
  const [noRooms, setNoRooms] = useState(false) // 没有可洽谈房间
  const [socketOff, setSocketOff] = useState(false) // socket断开了
  const anonymousModalRef = useRef<AnonymousModalRefProps>(null)
  const videoTalkRef = useRef<videoTalkRefProps>(null)
  const chatRoomRef = useRef<chatRoomRefProps>(null)
  const checkTalkStatRef = useRef<checkTalkStateRefProps>(null)
  const businessCardDetailRef = useRef<BusinessCardDetailRefProps>(null)
  const clientDesktopApp = localStorage.getItem('clientDesktopApp')
  const isDesktopApp = Boolean(clientDesktopApp && clientDesktopApp === CLIENT_DESKTOP_APP) // 是否来自桌面端app

  // 发送消息
  const sendMsg = async (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void, type: string = "sendMsg", roomId?: string) => {
    console.log('sendMessage=======message', message, roomId, storeRoomInfo)
    chatIMSService && chatIMSService.sendMessage({
      header:{
        roomId: roomId || storeRoomInfo?.roomId,  //房间ID
        path: `api/chatroom/${type}`,  //消息路径
      },
      body:{
        sender: storeUserInfo || userInfo,
        message,
      }
    }, (msgInfo: sendInfoTypes | false) => {
      msgInfo && callBackFn && callBackFn(msgInfo)
    })
  }

  // 结束洽谈
  const finishTalk = async (state:{
    status: string,
    title: string,
  }) => {
    await videoTalkRef.current?.leaveVideo()
    history.push({
      pathname: '/talk',
      state
    })
  }

  // 处理ims链接消息
  const handleImsNotice = async (notice:noticeItemTypes) => {
    console.log('handleImsNotice__notice', notice)
    switch(notice.code){
      case 2:
        // 初始化成功
        console.log('连接初始化成功')
        setSocketOff(false)
        sendMsg({
          msgType: 'text',
          content: {
            text: '登录',
            talkType: TALK_TYPE_WALKIN,
          },
        }, () => {
          console.log('userInfo', userInfo)
          if(userInfo && userInfo?.type !== 4){
            // 链接成功发送进入房间
            sendMsg({
              msgType: msgTypes.ACTION_JOIN_ROOM,
              content: {
                text: '加入房间'
              },
            }, () => {
              sendJionRoom()
            }, 'joinRoom')
          }
        }, 'login')
        break
      case -3:
        console.log('断开连接,重连中, ping timeout')
        setSocketOff(true)
        break
      default:
        break
    }
  }

  // 处理ims消息
  const handleImsMessage = (msg:sendInfoTypes) => {
    //接收消息的处理
    console.log('接受到新的消息', msg, storeRoomInfo?.roomId)
    const { header, body } = msg // 消息类型
    const { message, sender } = body // 消息主体
    const { msgType, content } = message // 消息类型
    if(header?.roomId === storeRoomInfo?.roomId){
      console.log('当前房间消息==', msg)
      switch(msgType){
        case msgTypes.JOIN_ROOM:
        case msgTypes.ENTER_ROOM:
          if(!header.isCurClient) {
            antdMessage?.success(intl.get('talk.join_room', {name: sender?.name}))
            msgType === msgTypes.JOIN_ROOM && chatRoomRef.current?.acceptMessage(msg)
          } else {
            header?.serviceAt && setEnterRoomTime(header?.serviceAt);
          }
          // dispatch(addRoomMembers([sender]))
          updateRoomInfo(header?.roomId, true)
          break
        case msgTypes.EXIT_ROOM:
          if(isDesktopApp && storeUserInfo?.type !== 4){
            // 桌面端收到展商退出房间
            history.push('/verify')
          } else {
            if(!header.isCurClient) {
              antdMessage?.success(intl.get('talk.exit_room', {name: sender?.name}))
              // dispatch(removeRoomMember(sender))
              updateRoomInfo(header?.roomId, true)
            } else {
              console.log('自己结束会话');
              finishTalk({
                status: 'info',
                title: intl.get('talk.state.info.title'),
              })
            }
          }
          break
        case msgTypes.FINISH_ROOM:
          // 收到结束会话
          if(!finishModal){
            finishModal = true
            const modalConfig = {
              width: 500,
              icon: <ExclamationCircleOutlined />,
              centered: true,
              title: intl.get('end_talk.tip'),
              onOk: () => {
                finishModal = false
                dispatch(resetVideoTalkState())
                let newRooms = storeRooms ? [...storeRooms] : null;
                console.log('会话结束')
                if(newRooms && storeUserInfo?.type === 4){
                  const curRoomIndex = newRooms.findIndex((item: roomType) => item.room_id === storeRoomInfo?.roomId)
                  if(curRoomIndex >= 0){
                    newRooms.splice(curRoomIndex, 1)
                    setRooms(newRooms)
                    if(newRooms.length){
                      willSwitchRoomId = newRooms[0].room_id
                    } else {
                      dispatch(setRoomInfo(null))
                      storeRoomInfo = null
                      storeRooms = null
                      setNoRooms(true)
                    }
                  }
                } else {
                  // 非展商用户
                  if(isDesktopApp){
                    // 桌面端收到展商退出房间
                    history.push('/verify')
                  } else {
                    talkSuccess()
                  }
                }
              },
              onCancel: () => {
                finishModal = false
              }
            }
            Modal.confirm(modalConfig)
          }
          break
        case msgTypes.CHAT_TEXT:
        case msgTypes.CHAT_EMOJI:
        case msgTypes.CHAT_IMG:
        case msgTypes.CHAT_FILE:
        case msgTypes.BUSINESS_CARD_APPLY:
        case msgTypes.BUSINESS_CARD_AGREE:
          if(!header.isCurClient){
            // 聊天消息过滤掉自己发送的消息
            chatRoomRef.current?.acceptMessage(msg)
            if([msgTypes.BUSINESS_CARD_APPLY, msgTypes.BUSINESS_CARD_AGREE].includes(msgType)){
              businessCardDetailRef?.current?.updateBusinessCard()
            }
          }
          break
        case msgTypes.PPT_START:
        case msgTypes.PPT_END:
          // ppt开始结束消息
          // chatRoomRef.current?.acceptMessage(msg)
          break
        case msgTypes.PPT_SWITCH:
          issuedPptMsg(msg)
          break
        case msgTypes.VIDEO_TALK_START:
          chatRoomRef.current?.acceptMessage(msg)
          if(!header.isCurClient && !acceptModal){
            acceptModal = true
            confirm({
              width: 500,
              icon: <ExclamationCircleOutlined />,
              centered: true,
              cancelText: intl.get('video_talk.accept.cancel'),
              okText: intl.get('video_talk.accept.ok'),
              title: <h3>{intl.get('video_talk.accept.title')}</h3>,
              content: <div>{intl.get('video_talk.accept.invite_tip')}</div>,
              onOk() {
                acceptModal = false
                openVideoTalk()
                dispatch(setRoomVidoeState(true))
              },
              onCancel() {
                acceptModal = false
                dispatch(setRoomVidoeState(true))
              }
            })
          }
          break
        case msgTypes.VIDEO_TALK_END:
          chatRoomRef.current?.acceptMessage(msg)
          if(!header.isCurClient && userInfo?.type !== 4){
            EventCenter.emit('closeVideoTalk')
            dispatch(setRoomVidoeState(false))
          }
          break
        default:
          break
      }
    } else {
      !header.isCurClient && updateRoomMsgsNum(header?.roomId)
      switch(msgType){
        case msgTypes.WALKIN_JOIN:
          // 有人新加入walkin
          const newRoom = {
            room_id: header.roomId,
            user: sender,
          }
          const hasNewroom = storeRooms ? storeRooms.find((item: roomType) => item.room_id === newRoom?.room_id) : false
          const newRooms = storeRooms ? [newRoom, ...storeRooms] : [newRoom]
          if(!hasNewroom){
            setNoRooms(false)
            setRooms(newRooms)
            if(newRooms && newRooms?.length === 1){
              willSwitchRoomId = newRoom?.room_id
            }
          }
          break;
        case msgTypes.NEW_NEXT_TALK:
          checkTalkStatRef.current?.stopCheck()
          Modal.info({
            title: intl.get('talk_tip.next_talk', {
              name: content?.user?.name,
              start_at: dayjs(content?.talk?.start_at).format('HH:mm'),
              end_at: dayjs(content?.talk?.end_at).format('HH:mm')
            }),
            okText: intl.get('talk_tip.goto_jump'),
            onOk: () => {
              // console.log('跳转')
              const token = ppo.getUrlParam('token')
              const redirect = ppo.getUrlParam('redirect')
              window.location.href = `${content?.talk?.room_url}/?token=${token}&lang=${locale}&redirect=${redirect}`
            },
          });
          break;
      }
    }
  }

  // 更新房间未读消息数量
  const updateRoomMsgsNum = (roomId: string, action: 'clear' | 'add' = 'add') => {
    let newRooms = [...rooms]
    const curRoom = newRooms.find((item: roomType) => item.room_id === roomId)
    if(curRoom){
      curRoom.msgsNum = action === 'clear' ? 0 : (!curRoom?.msgsNum ? 1 : curRoom?.msgsNum + 1)
      setRooms(newRooms)
    }
  }

  //下发ppt消息
  const issuedPptMsg = (msg: sendInfoTypes) => {
    userInfo?.type !== 4 && videoTalkRef.current?.acceptMessage(msg)
  }

  // 打开视频洽谈
  const openVideoTalk = () => {
    EventCenter.emit('openVideoTalk')
  }

  //发送加入房间消息
  const sendJionRoom = () => {
    const isInRoom = roomInfo?.historyUsers ? roomInfo?.historyUsers.find((item: userInfoTypes) => userInfo.id !== item.user_id) : false
    sendMsg({
      msgType:!isInRoom ? msgTypes.JOIN_ROOM : msgTypes.ENTER_ROOM, // 不在房间发送加入房间,否则发送进入房间
    }, () => {})
  }

  // 发送进入房间
  const sendEnterMsg = () => {
    if(storeRooms && storeRooms.length){
      storeRooms.forEach((item: roomType) => {
        // 链接成功发送进入房间
        if(!item.joinRoomed){
          sendMsg({
            msgType: msgTypes.ACTION_JOIN_ROOM,
            content: {
              text: '加入房间'
            },
          }, () => {
            item.joinRoomed = true
          }, 'joinRoom', item.room_id)
        }
      })
    }
  }

  const handLocalStreamChange = (name: string) => {
    console.log('handleLocalChange===name', name)
    videoTalkRef.current?.localStreamChange(name)
  }

  // 更新房间信息
  const updateRoomInfo = (roomId: string, isUpdate:boolean = false) => {
    !isUpdate && setRoomInit(false)
    dispatch(getRoomInfo(roomId, (data: roomInfoTypes) => {
      const inviteUserType = parseInt(ppo.getUrlParam('inviteUserType'))
      const talkUser = data.historyUsers?.find((item: userInfoTypes) => item.type === (inviteUserType && inviteUserType === 4 ? 1 : 4))
      talkUser && setCurRoomUser(talkUser)
    }, () => {}, isUpdate))
  }

  // 获取walkin信息
  const getWalkinInfo = async () => {
    const { status, data } = await Request({
      url: `walkin`,
    })
    setInitLoading(false)
    try {
      if(status){
        console.log('data', data)
        if(data.length){
          setRooms(data)
          setCurRoom(data[0])
          updateRoomInfo(data[0].room_id)
        } else {
          setNoRooms(true)
          console.log('暂时无人进入')
        }
      }
    } catch (error) {
      console.log('getWalkinInfo-error', data)
    }
  }

  // 切换房间
  const switchRoom = (roomId: string) => {
    const newCurRoom = rooms.find((room: roomType) => room.room_id === roomId)
    if(newCurRoom){
      setCurRoom(newCurRoom)
      updateRoomInfo(roomId)
      updateRoomMsgsNum(roomId, 'clear')
    }
  }

  //im初始化
  const chatIMSServiceInit = () => {
    if(!imsInit){
      // 创建消息服务
      chatIMSService = new IMSService({
        socketUrl: WS_URL,
        listeners: {
          onConnectNotify:(resp:any)=>{
            EventCenter.emit('ims_connect_notify',resp);
          },
          onMsgNotify:(msg:any)=>{
            EventCenter.emit('ims_message_notify',msg);
          }
        }
      })
      EventCenter.addListener('ims_connect_notify', handleImsNotice)
      EventCenter.addListener('ims_message_notify', handleImsMessage)
      EventCenter.addListener('handleLocalStream', handLocalStreamChange)
      imsInit = true
    }
  }

  // 非展商用户进入直接进入房间
  const enterRoom = () => {
    const roomId = ppo.getUrlParam('roomId')
    if(roomId){
      setInitLoading(false)
      setCurRoom(null)
      updateRoomInfo(roomId)
    } else {
      // 没有房间号
      history.push({
        pathname: '/error',
        state: {
          status: 'error',
          title: intl.get('error.no_room.title'),
          subTitle: intl.get('error.no_room.sub_title'),
        }
      })
    }
  }

  // 洽谈成功
  const talkSuccess = () => {
    history.push({
      pathname: '/talk',
      state: {
        status: 'success',
        title: intl.get('talk.state.success.title'),
      }
    })
  }

  useEffect(() => {
    document.title = intl.get('room_types.walk_in')
    const token = ppo.getUrlParam('token')
    const redirect = ppo.getUrlParam('redirect')
    sessionStorage.setItem('REDIRECT_URL', redirect || '')
    if(token){
      dispatch(getUserInfo(token, (user: userInfoTypes) => {
        // 登录成功
        storeUserInfo = user
        if(user.type === 4){
          chatIMSServiceInit()
          getWalkinInfo()
        } else {
          enterRoom()
        }
      }, () => {
        // 登录失败
        history.push({
          pathname: '/error',
          state: {
            status: 'error',
            title: intl.get('error.login_fail.title'),
            subTitle: intl.get('error.login_fail.sub_title'),
          }
        })
      }))
    } else {
      // 匿名用户进入
      const roomId = ppo.getUrlParam('roomId')
      if(roomId){
        dispatch(getRoomInfo(roomId, (data: roomInfoTypes) => {
          if(data.isEnd){
            history.push({
              pathname: '/error',
              state: {
                status: 'error',
                title: intl.get('error.end_tip.title'),
                subTitle: intl.get('error.end_tip.sub_title'),
              }
            })
          } else {
            anonymousModalRef.current?.open()
          }
        }, () => {}, false))
      }
    }
    return () => {
      // 取消监听
      EventCenter.removeListener('ims_connect_notify', handleImsNotice)
      EventCenter.removeListener('ims_message_notify', handleImsMessage)
      EventCenter.removeListener('handleLocalStream', handLocalStreamChange)
      chatIMSService && chatIMSService.stop()
    }
  }, [])

  useEffect(() => {
    if(!roomInit && roomInfo && userInfo && (!storeRoomInfo || storeRoomInfo.roomId !== roomInfo.roomId)) {
      setRoomInit(true)
      if(roomInfo.lasPptMessage){
        // 有ppt消息
        // console.log('roomInfo.lasPptMessage', roomInfo.lasPptMessage)
        if(userInfo.type === 4){
          const { body } = roomInfo.lasPptMessage
          const { message } = body
          const { content } = message
          if(content?.imgUrl && content?.pptIndex !== null){
            console.log('展商ppt状态', content)
            dispatch(setPPTSate({
              open: content.imgUrl ? true : false,
              index: content.pptIndex,
              image: content.imgUrl,
            }))
          }
        } else {
          issuedPptMsg(roomInfo.lasPptMessage)
        }
      }
      storeRoomInfo = roomInfo
      userInfo.type === 4 ? sendJionRoom() : chatIMSServiceInit()
    }
  }, [roomInfo])

  useEffect(() => {
    if(rooms && rooms.length){
      storeRooms = rooms
      // console.log('storeRooms', storeRooms)
      sendEnterMsg()
      if(willSwitchRoomId){
        switchRoom(willSwitchRoomId)
        willSwitchRoomId = null
      }
    }
  }, [rooms])

  return (
    <>
      {
        initLoading?
        <FullLoading size="large"/>:
        <div className={styles["walkin"]}>
          {
            (userInfo?.type === 4 || videoTalk.vidoeTalkOpen)&&
            <div className={clsx(styles["sidebar"], videoTalk.vidoeTalkOpen ? styles["vidoe-open"] : null)}>
              {
                !videoTalk.vidoeTalkOpen?
                <>
                  <h3 className={styles["room_type"]}>{intl.get(`room_types.walk_in`)}</h3>
                  {
                    userInfo?.type === 4 &&
                    <RoomList rooms={rooms} roomId={curRoom?.room_id || ''} switchRoom={switchRoom}/>
                  }
                </>:
                <>
                  {
                    roomInfo?
                    <>
                      <div className={styles['videotalk-section']}>
                        <VideoTalk
                          ref={videoTalkRef}
                          userID={`${userInfo?.id}`}
                          roomID={roomInfo.roomId || ''}
                          userInfo={userInfo}
                          videoTalk={videoTalk}
                          roomMembers={roomInfo?.users || []}
                          sendMessage={sendMsg}/>
                      </div>
                      <ControlBar sendMsg={sendMsg}/>
                    </>:
                    null
                  }
                </>
              }
            </div>
          }
          <div className={styles["room-info"]}>
            {
              socketOff &&
              <Alert
                message={intl.get('socket_off')}
                type="warning"
                showIcon
                action={
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    {intl.get('refres')}
                  </Button>
                }
              />
            }
            {
              userInfo && userInfo.type === 4 &&
              <CheckTalkState ref={checkTalkStatRef} talkType={TALK_TYPE_WALKIN}/>
            }
            {
              !roomInfo?
              <>
                {
                  noRooms ? <div className={styles.no_rooms}>{intl.get('walkin.no_rooms')}</div> : <FullLoading size="large"/>
                }
              </>:
              <div className={styles['room']}>
                <Header headerType={TALK_TYPE_WALKIN} sendMsg={sendMsg}/>
                <div className={styles['room-body']}>
                  <div className={styles['chatroom-section']}>
                    <ChatRoom
                      navbarTop={
                        roomInfo?.vidoeState && !videoTalk?.vidoeTalkOpen ?
                        <Alert
                          message={intl.get('video_talk.accept.tip')}
                          type="warning"
                          showIcon
                          action={
                            <Button size="small" type="primary" onClick={openVideoTalk}>{intl.get('video_talk.accept.ok')}</Button>
                          }
                        />:
                        null
                      }
                      locale={locale}
                      ref={chatRoomRef}
                      roomId={roomInfo?.roomId || ''}
                      userId={userInfo.id}
                      enterRoomTime={enterRoomTime}
                      sendMessage={sendMsg}/>
                  </div>
                  {
                    (curRoom || curRoomUser) && !videoTalk.vidoeTalkOpen?
                    <div className={styles['detail-section']}>
                      {
                        // curRoom && <UserDetail userBasic={curRoom?.user}/>
                        curRoom &&
                        <BusinessCardDetail
                          ref={businessCardDetailRef}
                          ctaId={curRoom?.user?.cta_id}
                        />
                      }
                      {
                        // curRoomUser && rooms && !rooms.length && <UserDetail userBasic={curRoomUser}/>
                        curRoomUser &&
                        rooms &&
                        !rooms.length &&
                        <BusinessCardDetail
                          ref={businessCardDetailRef}
                          ctaId={curRoomUser?.cta_id}
                        />
                      }
                    </div>:
                    null
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
      <AnonymousModal enterRoom={enterRoom} ref={anonymousModalRef}/>
    </>
  )
}

const stateToProps = (state: {
  videoTalk: videoTalkTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  global: globalTypes,
}) => {
  const { global, videoTalk, userInfo, roomInfo } = state
  return {
    global,
    locale: global.locale,
    videoTalk,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null)(TalkRoom)