// 表情符号
export default {
    "/::)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_1.png?v=1685694002703",
    "/::~": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_2.png?v=1685694002703",
    "/::B": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_3.png?v=1685694002703",
    "/::|": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_4.png?v=1685694002703",
    "/:8-)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_5.png?v=1685694002703",
    "/::<": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_6.png?v=1685694002703",
    "/::$": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_7.png?v=1685694002703",
    "/::X": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_8.png?v=1685694002703",
    "/::Z": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_9.png?v=1685694002703",
    "/::'(": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_10.png?v=1685694002703",
    "/::-|": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_11.png?v=1685694002703",
    "/::@": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_12.png?v=1685694002703",
    "/::P": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_13.png?v=1685694002703",
    "/::D": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_14.png?v=1685694002703",
    "/::O": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_15.png?v=1685694002703",
    "/::(": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_16.png?v=1685694002703",
    "/::+": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_17.png?v=1685694002703",
    "[囧]": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_18.png?v=1685694002703",
    // "/:--b": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_18.png?v=1685694002703",
    "/::Q": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_19.png?v=1685694002703",
    "/::T": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_20.png?v=1685694002703",
    "/:,@P": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_21.png?v=1685694002703",
    "/:,@-D": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_22.png?v=1685694002703",
    "/::d": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_23.png?v=1685694002703",
    "/:,@o": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_24.png?v=1685694002703",
    "/::g": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_25.png?v=1685694002703",
    "/:|-)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_26.png?v=1685694002703",
    "/::!": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_27.png?v=1685694002703",
    "/::L": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_28.png?v=1685694002703",
    "/::>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_29.png?v=1685694002703",
    "/::,@": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_30.png?v=1685694002703",
    "/:,@f": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_31.png?v=1685694002703",
    "/::-S": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_32.png?v=1685694002703",
    "/:?": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_33.png?v=1685694002703",
    "/:,@x": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_34.png?v=1685694002703",
    "/:,@@": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_35.png?v=1685694002703",
    "/::8": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_36.png?v=1685694002703",
    "/:,@!": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_37.png?v=1685694002703",
    "/:!!!": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_38.png?v=1685694002703",
    "/:xx": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_39.png?v=1685694002703",
    "/:bye": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_40.png?v=1685694002703",
    "/:wipe": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_41.png?v=1685694002703",
    "/:dig": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_42.png?v=1685694002703",
    "/:handclap": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_43.png?v=1685694002703",
    "/:&-(": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_44.png?v=1685694002703",
    "/:B-)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_45.png?v=1685694002703",
    "/:<@": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_46.png?v=1685694002703",
    "/:@>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_47.png?v=1685694002703",
    "/::-O": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_48.png?v=1685694002703",
    "/:>-|": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_49.png?v=1685694002703",
    "/:P-(": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_50.png?v=1685694002703",
    "/::'|": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_51.png?v=1685694002703",
    "/:X-)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_52.png?v=1685694002703",
    "/::*": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_53.png?v=1685694002703",
    "/:@x": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_54.png?v=1685694002703",
    "/:8*": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_55.png?v=1685694002703",
    "/:pd": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_56.png?v=1685694002703",
    "/:<W>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_57.png?v=1685694002703",
    "/:beer": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_58.png?v=1685694002703",
    "/:basketb": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_59.png?v=1685694002703",
    "/:oo": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_60.png?v=1685694002703",
    "/:coffee": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_61.png?v=1685694002703",
    "/:eat": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_62.png?v=1685694002703",
    "/:pig": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_63.png?v=1685694002703",
    "/:rose": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_64.png?v=1685694002703",
    "/:fade": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_65.png?v=1685694002703",
    "/:showlove": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_66.png?v=1685694002703",
    "/:heart": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_67.png?v=1685694002703",
    "/:break": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_68.png?v=1685694002703",
    "/:cake": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_69.png?v=1685694002703",
    "/:li": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_70.png?v=1685694002703",
    "/:bome": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_71.png?v=1685694002703",
    "/:kn": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_72.png?v=1685694002703",
    "/:footb": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_73.png?v=1685694002703",
    "/:ladybug": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_74.png?v=1685694002703",
    "/:shit": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_75.png?v=1685694002703",
    "/:moon": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_76.png?v=1685694002703",
    "/:sun": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_77.png?v=1685694002703",
    "/:gift": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_78.png?v=1685694002703",
    "/:hug": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_79.png?v=1685694002703",
    "/:strong": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_80.png?v=1685694002703",
    "/:weak": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_81.png?v=1685694002703",
    "/:share": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_82.png?v=1685694002703",
    "/:v": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_83.png?v=1685694002703",
    "/:@)": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_84.png?v=1685694002703",
    "/:jj": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_85.png?v=1685694002703",
    "/:@@": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_86.png?v=1685694002703",
    "/:bad": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_87.png?v=1685694002703",
    "/:lvu": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_88.png?v=1685694002703",
    "/:no": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_89.png?v=1685694002703",
    "/:ok": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_90.png?v=1685694002703",
    "/:love": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_91.png?v=1685694002703",
    "/:<L>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_92.png?v=1685694002703",
    "/:jump": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_93.png?v=1685694002703",
    "/:shake": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_94.png?v=1685694002703",
    "/:<O>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_95.png?v=1685694002703",
    "/:circle": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_96.png?v=1685694002703",
    "/:kotow": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_97.png?v=1685694002703",
    "/:turn": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_98.png?v=1685694002703",
    "/:skip": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_99.png?v=1685694002703",
    "/:oY": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_100.png?v=1685694002703",
    "/:#-0": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_101.png?v=1685694002703",
    "/:hiphot": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_102.png?v=1685694002703",
    "/:kiss": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_103.png?v=1685694002703",
    "/:<&": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_104.png?v=1685694002703",
    "/:&>": "https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/emojis/Expression_105.png?v=1685694002703",
}
