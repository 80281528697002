// 全局状态
export const GLOBAL_LOCALE = 'GLOBAL_LOCALE'
export const GLOBAL_TALK_TYPE = 'GLOBAL_TALK_TYPE'
export const GLOBAL_CLIENT_ID = 'GLOBAL_CLIENT_ID'
// 视频洽谈
export const VIDEO_TALK_INIT_STATE = 'VIDEO_TALK_INIT_STATE'
export const VIDEO_TALK_SETTING_OPEN = 'VIDEO_TALK_SETTING_OPEN'
export const VIDEO_TALK_OPEN = 'VIDEO_TALK_OPEN'
export const VIDEO_TALK_CAMERA_ID = 'VIDEO_TALK_CAMERA_ID'
export const VIDEO_TALK_MICROPHONE_ID = 'VIDEO_TALK_MICROPHONE_ID'
export const VIDEO_TALK_AUDIO = 'VIDEO_TALK_AUDIO'
export const VIDEO_TALK_VIDEO = 'VIDEO_TALK_VIDEO'
export const VIDEO_TALK_MUTED_VIDEO = 'VIDEO_TALK_MUTED_VIDEO'
export const VIDEO_TALK_MUTED_AUDIO = 'VIDEO_TALK_MUTED_AUDIO'
export const VIDEO_TALK_PPT_STATE = 'VIDEO_TALK_PPT_STATE'
export const RESET_VIDEO_TALK_STATE = 'RESET_VIDEO_TALK_STATE'

// 用户信息
export const USER_INF0 = 'USER_INF0'

// 房间信息
export const ROOM_INFO = 'ROOM_INFO'
export const ADD_ROOM_USERS = 'ADD_ROOM_USERS'
export const REMOVE_ROOM_USER = 'REMOVE_ROOM_USER'
export const SET_ROOM_VIDOE_STATE = 'SET_ROOM_VIDOE_STATE'
export const UPDATE_ROOM_MEMBERS = 'UPDATE_ROOM_MEMBERS'