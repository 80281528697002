import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { connect, useDispatch } from 'react-redux'
import {
  setInitState,
  setSettingOpen,
  setCameraID,
  setMicrophoneID,
  setAudio,
  setVideo } from '@/store/actions'
import type {videoTalkTypes} from '@/store/store.d'
import clsx from 'clsx'
import { Button, Space, Switch, message } from 'antd'
import IconFont from '@/components/IconFont'
import DeviceSelect from '@/components/VideoTalk/DeviceSelect'
import styles from './pre-enter.module.scss'

// 组件参数
interface PreEnterPropsTypes extends videoTalkTypes {
  enterRoom?: () => void,
}

const PreEnter:React.FC<PreEnterPropsTypes> = (props) => {
  const dispatch = useDispatch()
  const { initState, settingOpen, enterRoom, audio, video } = props

  // 设置对话框关闭
  const handleCancel = () => {
    dispatch(setSettingOpen(false))
  }

  // 设置对话框确定
  const handleOk = () => {
    // if(!audio && !video){
    //   message.error(intl.get('pre_enter.error_tip'))
    //   return
    // }
    dispatch(setInitState(true))
    dispatch(setSettingOpen(false))
    enterRoom && enterRoom()
  }

  return(
    <div className={styles['pre-enter']}>
      <div className={styles['enter-header']}>
        {/* <h1 className={styles['enter-title']}>{intl.get('pre_enter.talk_with_who', {name: 'test'})}</h1> */}
        <h3 className={styles['enter-subtitle']}>{intl.get('pre_enter.setting_tip')}</h3>
      </div>
      <div className={styles['setting-main']}>
        <div className={styles['space__item']}>
          <Space className={clsx(styles['item__cell'], styles['__state'])}>
            <Space className={styles['cell__lable']} align="center">
              <IconFont size="large" type="icon-videocam"/>
              {intl.get('video_talk.camera')}
            </Space>
            <Switch
              defaultChecked={video}
              onChange={(checked: boolean) => dispatch(setVideo(checked))}/>
          </Space>
          <div className={styles['item__cell']}>
            <div className={styles['cell__lable']}>{intl.get('video_talk.equipment', {name: intl.get('video_talk.camera')})}</div>
            <div className={styles['cell__select']}>
              <DeviceSelect deviceType="camera" onChange={(value: string) => dispatch(setCameraID(value))}></DeviceSelect>
            </div>
          </div>
        </div>
        <div className={styles['space__item']}>
          <Space className={clsx(styles['item__cell'], styles['__state'])} align="center">
            <Space className={styles['cell__lable']} align="center">
              <IconFont size="large" type="icon-mic"/>
              {intl.get('video_talk.microphone')}
            </Space>
            <Switch
              defaultChecked={audio}
              onChange={(checked: boolean) => dispatch(setAudio(checked))}/>
          </Space>
          <div className={styles['item__cell']}>
            <div className={styles['cell__lable']}>{intl.get('video_talk.equipment', {name: intl.get('video_talk.microphone')})}</div>
            <div className={styles['cell__select']}>
              <DeviceSelect deviceType="microphone" onChange={(value: string)  => dispatch(setMicrophoneID(value))}></DeviceSelect>
            </div>
          </div>
        </div>
        <div className={clsx(styles['space__item'], styles['space__item__footer'])}>
          <Button type="primary" size="large" shape="round" onClick={handleOk}>{intl.get('pre_enter.center_talk')}</Button>
        </div>
      </div>
    </div>
  )
}

const stateToProps = (state: {
  videoTalk: videoTalkTypes
}) => {
  const { videoTalk } = state
  return {
    ...videoTalk
  }
}

export default connect(stateToProps, null)(PreEnter)