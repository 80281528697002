import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle} from 'react'
import { useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
// import DynamicRtc from './BaseRTC'
import DynamicRtc from './RTCClient'
import Stream from './Stream'
import UserCard from './Stream/userCard'
import PPT from '@/components/VideoTalk/PPT'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import type { Swiper as SwiperClass} from 'swiper/types/index.d'
import { Grid, Navigation } from 'swiper'
import 'swiper/swiper.scss'
import "swiper/modules/navigation/navigation.scss"
import 'swiper/modules/grid/grid.scss'
import type {videoTalkTypes, userInfoTypes} from '@/store/store.d'
import type {
  StreamTypes,
  ConfigTypes,
  LocalStreamTypes,
  LocalStreamConfigTypes,
  RemoteStreamConfigTypes,
  StreamBarChangeTypes,
  videoTalkRefProps,
} from './index.d'
import { setAudio, setVideo} from '@/store/actions'
import EventCenter from '@/utils/IMSService/EventCenter'
import { Image } from 'antd'
import { MAX_ACCEPT_STREAM } from '@/config'
import type { messageTypes, sendInfoTypes } from '@/utils/IMSService/index.d'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import styles from './index.module.scss'
import clsx from 'clsx'
import ppo from 'ppo'

export type VideoTalkProps = {
  userID: string,
  roomID: string,
  userInfo: userInfoTypes,
  videoTalk: videoTalkTypes,
  roomMembers: userInfoTypes [],
  sendMessage: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
}

let showPPTed = false // 是否显示过ppt

const VideoTalk = forwardRef<videoTalkRefProps, VideoTalkProps>((props, childRef) => {
  const dispatch = useDispatch()
  const { userID, roomID, userInfo, videoTalk, sendMessage, roomMembers} = props
  const {video, audio, cameraID, microphoneID, pptSate } = videoTalk
  const [useStringRoomID] = useState(true)
  const [RTC, setRTC] = useState<any>(null)
  const [localStreamConfig, setLocalStreamConfig] = useState<LocalStreamConfigTypes | null>(null)
  const [remoteStreamConfigList, setRemoteStreamConfigList] = useState<RemoteStreamConfigTypes[]>([])
  const [isJoined, setIsJoined] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [streamNum, setStreamNum] = useState(0)
  const [pptImg, setPptImg] = useState('') // ppt显示图片
  const [showPPT, setShowPPT] = useState(false) // 是否显示ppt
  const [mainSwiper, setMainSwiper] = useState<SwiperClass | null>(null)
  const [swiperWidth, setSwiperWidth] = useState<any>(0)
  const streamContainerEl = useRef<HTMLDivElement>(null)
  const streamBoxEl = useRef<HTMLDivElement>(null)

  useImperativeHandle(childRef, () => ({
    // 接受新消息
    acceptMessage: (msg: sendInfoTypes) => {
      console.log('接收到新消息===msg', msg)
      if(msg){
        const { body } = msg // 消息类型
        const { message } = body // 消息主体
        if(message?.msgType === msgTypes.PPT_SWITCH) setPptImg(message?.content?.imgUrl || '')
      }
    },
    localStreamChange: (name: string) => {
      handleLocalChange({name})
    },
    // 离开房间
    leaveVideo: async () => {
      await handleLeave()
    }
  }))

  useEffect(() => {
    setSwiperWidth(streamContainerEl?.current?.offsetWidth)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // 进入自动加入视频洽谈
  useEffect(() => {
    RTC && handleJoin()
  }, [RTC])

  // 获取可显示流的数量,来决定流布局结构
  useEffect(() => {
    let _streamNum = 0;
    if(localStreamConfig){
      _streamNum ++
    }
    remoteStreamConfigList.forEach(() => {
      _streamNum ++
    })
    // console.log('可显示流的数量', _streamNum)
    setStreamNum(_streamNum)
  }, [localStreamConfig, remoteStreamConfigList])

  useEffect(() => {
    setShowPPT(pptSate?.open || pptImg ? true : false)
  }, [pptSate, pptImg])

  // 重新推流啊
  const rePublish = async () => {
    await handleUnPublish()
    await handlePublish()
    remoteStreamConfigList.forEach( async (config: ConfigTypes) => {
      const remoteStream = config?.stream
      await RTC.handleSubscribe(remoteStream, {
        video: config.subscribedVideo,
        audio: config.subscribedAudio,
      })
    })
  }

  useEffect( () => {
    console.log('是否显示ppt', showPPT)
    if(showPPT){
      showPPTed = true
    }
    // showPPTed && rePublish()
  }, [showPPT])

  const handleJoin = async () => {
    if(RTC){
      await RTC.handleJoin()
      await RTC.handlePublish()
      !video && RTC.muteVideo()
      !audio && RTC.muteAudio()
    }
  }

  const handlePublish = async () => {
    if(RTC){
      await RTC.handlePublish()
    }
  }

  const handleUnPublish = async () => {
    if(RTC){
      await RTC.handleUnPublish()
    }
  }

  const handleLeave = async () => {
    if(RTC){
      await RTC.handleLeave()
    }
  }

  // 设置状态
  const setState = (type:string, value:boolean) => {
    switch (type) {
      case 'join':
        setIsJoined(value)
        break
      case 'publish':
        setIsPublished(value)
        break
      default:
        break
    }
  }

  // 新增用户
  const addUser = (userID: string, streamType: string) => {
    if (streamType === 'local') {
      // 本地流
      setLocalStreamConfig({
        stream: null,
        streamType,
        userID,
        hasAudio: false,
        hasVideo: false,
        mutedAudio: false,
        mutedVideo: false,
        shareDesk: false,
        audioVolume: 0,
      })
    } else {
      // 远端流
      setRemoteStreamConfigList((preList) => {
        const newRemoteStreamConfigList = preList.length > 0 ? preList.filter(streamConfig => streamConfig.userID !== userID) : []
        newRemoteStreamConfigList
          .push({
            stream: null,
            streamType: 'main',
            userID,
            hasAudio: false,
            hasVideo: false,
            mutedAudio: false,
            mutedVideo: false,
            subscribedAudio: false,
            subscribedVideo: false,
            resumeFlag: false,
            audioVolume: 0,
          });
        return newRemoteStreamConfigList;
      });
    }
  };

  // 移除用户
  const removeUser = (userID: string, streamType: string) => {
    if (streamType === 'local') {
      setLocalStreamConfig(null)
      setRemoteStreamConfigList([])
    } else {
      setRemoteStreamConfigList(preList => preList.filter(streamConfig => streamConfig.userID !== userID))
    }
  }

  // 增加流
  const addStream = (stream:any) => {
    const streamType = stream.getType()
    const userID = stream.getUserId()
    switch (streamType) {
      case 'local':
        setLocalStreamConfig({
          stream,
          streamType,
          userID,
          hasAudio: true,
          hasVideo: true,
          mutedAudio: !audio || false,
          mutedVideo: !video || false,
          shareDesk: false,
          audioVolume: 0,
        })
        break
      default: {
        // console.log('addStream====', streamNum, MAX_ACCEPT_STREAM)
        if(streamNum < MAX_ACCEPT_STREAM ){
          setRemoteStreamConfigList((preList) => {
            const newRemoteStreamConfigList = preList.length > 0
              ? preList.filter(streamConfig => !(streamConfig.userID === userID
                && streamConfig.streamType === streamType))
              : [];
            newRemoteStreamConfigList
              .push({
                stream,
                streamType,
                userID,
                hasAudio: stream.hasAudio(),
                hasVideo: stream.hasVideo(),
                mutedAudio: false,
                mutedVideo: false,
                subscribedAudio: true,
                subscribedVideo: true,
                resumeFlag: false,
                audioVolume: 0,
              });
            return newRemoteStreamConfigList
          })
        } else {
          console.log('达到最大接受流数')
        }
        break
      }
    }
  }

  // 更新流数据
  const updateStream = (stream:LocalStreamTypes) => {
    if (stream.getType() === 'local') {
      if(localStreamConfig){
        setLocalStreamConfig({
          ...localStreamConfig,
          stream,
          hasAudio: stream.hasAudio(),
          hasVideo: stream.hasVideo(),
        })
      }
    } else {
      setRemoteStreamConfigList(preList => preList.map(config => (
        config.stream === stream ? {
          ...config,
          stream,
          hasAudio: stream.hasAudio(),
          hasVideo: stream.hasVideo(),
        } : config
      )))
    }
  }

  // 更新对本地流和远端流的操作状态
  const updateStreamConfig = (userID:string, type: string, value: number) => {
    // 更新本地流配置
    if (localStreamConfig && localStreamConfig.userID === userID) {
      const config: {
        [prop: string]: any,
      } = {}
      switch (type) {
        case 'audio-volume':
          if (localStreamConfig.audioVolume === value) {
            break;
          }
          config.audioVolume = value
          break;
        case 'share-desk':
          config.shareDesk = value
          break;
        case 'uplink-network-quality':
          config.uplinkNetworkQuality = value > 0 ? 6 - value : value;
          break;
        case 'downlink-network-quality':
          config.downlinkNetworkQuality = value > 0 ? 6 - value : value;
          break;
        default:
          break;
      }
      setLocalStreamConfig((prevConfig: any) => ({
        ...prevConfig,
        ...config,
      }))
      return
    }
    // 更新远端流配置
    const config:{
      [prop: string]: any,
    } = {}
    switch (type) {
      case 'mute-audio':
        config.mutedAudio = true
        break;
      case 'unmute-audio':
        config.mutedAudio = false
        break;
      case 'mute-video':
        config.mutedVideo = true
        break;
      case 'unmute-video':
        config.mutedVideo = false
        break;
      case 'resume-stream':
        config.resumeFlag = true
        break;
      case 'audio-volume':
        if (config.audioVolume === value) {
          break
        }
        config.audioVolume = value
        break
      default:
        break
    }
    setRemoteStreamConfigList(preList => preList.map(item => (
      item.userID === userID ? { ...item, ...config } : item
    )))
  }

  // 移除流
  const removeStream = (stream:StreamTypes) => {
    const streamType = stream.getType()
    const userID = stream.getUserId()
    switch (streamType) {
      case 'local':
        setLocalStreamConfig((prevConfig: any) => ({
          ...prevConfig,
          hasAudio: false,
          hasVideo: false,
        }))
        break
      default: {
        setRemoteStreamConfigList(preList => preList
          .map(streamConfig => (streamConfig.userID === userID && streamConfig.streamType === streamType
            ? {
              ...streamConfig,
              hasAudio: false,
              hasVideo: false,
              subscribedAudio: false,
              subscribedVideo: false,
            } : streamConfig)))
        break
      }
    }
  }

  // 处理本地流 streamBar 的响应逻辑
  const handleLocalChange = async (data: StreamBarChangeTypes) => {
    if(localStreamConfig) {
      switch (data.name) {
        case 'video':
          !localStreamConfig.mutedVideo ? RTC.muteVideo() : RTC.unmuteVideo()
          dispatch(setVideo(localStreamConfig.mutedVideo))
          setLocalStreamConfig({
            ...localStreamConfig,
            mutedVideo: !localStreamConfig.mutedVideo,
          })
          break
        case 'audio':
          !localStreamConfig.mutedAudio ? RTC.muteAudio() : RTC.unmuteAudio()
          dispatch(setAudio(localStreamConfig.mutedAudio))
          setLocalStreamConfig({
            ...localStreamConfig,
            mutedAudio: !localStreamConfig.mutedAudio,
          })
          break
        default:
          break
      }
    }
  }

  // 处理远端流 streamBar 的响应逻辑
  const handleRemoteChange = async (data: StreamBarChangeTypes) => {
    const remoteStream = data.stream
    const config = remoteStreamConfigList.find(config => config.stream === remoteStream)
    if(config) {
      switch (data.name) {
        case 'subscribedVideo':
          await RTC.handleSubscribe(remoteStream, {
            video: !config.subscribedVideo,
            audio: config.subscribedAudio,
          })

          setRemoteStreamConfigList(preList => preList.map(config => (
            config.stream === remoteStream ? ({
              ...config,
              subscribedVideo: !config.subscribedVideo,
            }) : config
          )))
          break;
        case 'subscribedAudio':
          await RTC.handleSubscribe(remoteStream, {
            video: config.subscribedVideo,
            audio: !config.subscribedAudio,
          })
          setRemoteStreamConfigList(preList => preList.map(config => (
            config.stream === remoteStream ? ({
              ...config,
              subscribedAudio: !config.subscribedAudio,
            }) : config
          )))
          break
        case 'resumeFlag':
          await RTC.resumeStream(config.stream);
          setRemoteStreamConfigList(preList => preList.map(config => (
            config.stream === remoteStream ? ({
              ...config,
              resumeFlag: !config.resumeFlag,
            }) : config
          )))
          break
        default:
          break
      }
    }
  }

  // 获取房间成员信息
  const getMemberInfo = (userId: string): userInfoTypes => {
    // 未知用户
    const unknownUser = {
      type: 7, // 0: 观众, 1: 买家, 2:媒体, 3:游客 , 4: 展商
      id: ppo.uuid(),  //发送者id
      name:intl.get('unkown_user'), //用户名
      company: '', // 公司名
      avatar: 'https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/avatar/avatar_man.png?v=1685693993783' //用户头像
    }
    const curMember = roomMembers.find(item => item.id == userId) || unknownUser
    return curMember
  }

  // 页面窗口发生变化
  const onResize = () => {
    setSwiperWidth(streamContainerEl?.current?.offsetWidth)
    mainSwiper?.update()
  }

  return(
    <div className={styles["video-talk"]}>
      <DynamicRtc
        onRef={(ref:any) => setRTC(ref)}
        userID={userID}
        roomID={roomID}
        useStringRoomID={useStringRoomID}
        cameraID={cameraID}
        microphoneID={microphoneID}
        audio={true}
        video={true}
        mode='rtc'
        setState={setState}
        addUser={addUser}
        removeUser={removeUser}
        addStream={addStream}
        updateStream={updateStream}
        updateStreamConfig={updateStreamConfig}
        removeStream={removeStream}>
      </DynamicRtc>
      {/* 视频流显示区域 */}
      <div className={clsx(styles['stream-container'], showPPT ? styles['__hasPPT'] : '')} ref={streamContainerEl}>
        <Swiper
          modules={[Grid, Navigation]}
          slidesPerView={showPPT ? 4 : streamNum < 3 ? streamNum : (streamNum <= 4 ? 2 : 3)}
          grid={!showPPT ? { rows:3 } : {}}
          navigation={true}
          className={styles['stream-swiper']}
          onInit={(swiper) => setMainSwiper(swiper)}
          onSwiper={(swiper:SwiperClass) => setMainSwiper(swiper)}
          style={{width: swiperWidth}}>
          <SwiperSlide className={clsx(styles['stream-item'], styles[`stream__${streamNum}`])}>
            {
              localStreamConfig && localStreamConfig.stream && (localStreamConfig.hasAudio || localStreamConfig.hasVideo) ?
              <Stream
                hasPPT={showPPT}
                stream={localStreamConfig.stream}
                config={localStreamConfig}
                userInfo={userInfo}
                init={dom => RTC.playStream(localStreamConfig.stream, dom)}
                onChange={e => handleLocalChange(e)}>
              </Stream>:
              <UserCard hasPPT={showPPT} userInfo={userInfo}/>
            }
          </SwiperSlide>
          {
            remoteStreamConfigList.length > 0 &&
            remoteStreamConfigList.map((remoteStreamConfig: RemoteStreamConfigTypes) => {
              return(
                <SwiperSlide
                  className={clsx(styles['stream-item'], styles[`stream__${streamNum}`])}
                  key={`${remoteStreamConfig?.userID}_${remoteStreamConfig?.streamType}`}>
                  {
                    remoteStreamConfig.stream && (remoteStreamConfig.hasAudio || remoteStreamConfig.hasVideo) ?
                    <Stream
                      hasPPT={showPPT}
                      stream = {remoteStreamConfig.stream}
                      config = {remoteStreamConfig}
                      userInfo={getMemberInfo(remoteStreamConfig?.userID || '')}
                      init={dom => {
                        RTC.playStream(remoteStreamConfig.stream, dom)
                      }}
                      onChange = {(e:any) => handleRemoteChange(e)}>
                    </Stream>:
                    <UserCard hasPPT={showPPT} userInfo={getMemberInfo(remoteStreamConfig?.userID || '')}/>
                  }
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
      {
        showPPT && <div className={styles['ppt-area']}>
          {
            userInfo?.type === 4?
            <PPT sendMessage={sendMessage} pptSate={pptSate || {}}/>:
            <div className={styles['show-ppt']}>
              <Image
                width={'100%'}
                height={'100%'}
                src={pptImg}
              />
            </div>
          }
        </div>
      }
    </div>
  )
})

export default VideoTalk