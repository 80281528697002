import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import ppo from 'ppo'
import dayjs from 'dayjs'
import { connect, useDispatch } from 'react-redux'
import type { userInfoTypes, roomInfoTypes, talkMarkOptionsTypes } from '@/store/store.d'
import { TALK_TYPE_WALKIN, TALK_TYPE_PSA, } from '@/config/index'
import Request from '@/utils/request'
import { sendMessage } from '@/utils/socketMessage'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import { Modal, Button, Space, Select } from 'antd'
import {
  PoweroffOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import styles from './index.module.scss'

const CLIENT_ID = ppo.uuid() // 生成唯一的客户端id

const { confirm } = Modal
const { Option } = Select

// header参数类型
export type FinishBtnPropsTypes = {
  children?: React.ReactNode,
  userInfo?: userInfoTypes,
  roomInfo?: roomInfoTypes,
}

export type walkinSettingType = {
  walk_in_mark_options?: talkMarkOptionsTypes[],
  walk_in_mark_status?: boolean,
  walk_in_server_num?: number,
}

let showEndModal = false
let ratingStore = ''
const FinishBtn: React.FC<FinishBtnPropsTypes> = (props) => {
  const dispatch = useDispatch()
  const { children, roomInfo, userInfo } = props

  const headerType = ppo.getUrlParam('type')

  const talkSetting = roomInfo?.talk_setting
  const defalutRating= talkSetting && talkSetting?.talk_mark_options?.length ? talkSetting?.talk_mark_options[0].score : ''
  const [rating, setRating] = useState(defalutRating) // 设置评分
  const [walkinSetting, setWalkinSetting] = useState<walkinSettingType | null>() // walkin的设置
  const [canEndCall]= useState(Number(roomInfo?.status) === 2) // 可以结束洽谈
  // const [canEndCall]= useState(1) // 可以结束洽谈

  // 评分变化
  const ratingChange = (value: string) => {
    setRating(value)
  }

  // 结束洽谈
  const chatFinish = (title?: string) => {
    const modalConfig = {
      width: 500,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      title: title || intl.get("end_talk.title"),
      maskClosable: true,
      content: <div className={styles['invite-confirm']}>
        {
          headerType === TALK_TYPE_WALKIN ?
          <>
            {
              walkinSetting &&
              walkinSetting?.walk_in_mark_status &&
              walkinSetting?.walk_in_mark_options &&
              walkinSetting?.walk_in_mark_options?.length > 0 &&
              <Space direction="vertical" style={{ width: '100%' }}>
                {intl.get('end_talk.rating_tip')}
                <Select defaultValue={rating} style={{ width: '100%' }} onChange={ratingChange}>
                  {
                    walkinSetting?.walk_in_mark_options.map((item: talkMarkOptionsTypes, index: number) =>{
                      return <Option key={index} value={item.score}>{item.title}</Option>
                    })
                  }
                </Select>
              </Space>
            }
          </>:
          <>
           {
              talkSetting &&
              talkSetting?.talk_mark_status &&
              talkSetting?.talk_mark_options &&
              talkSetting?.talk_mark_options?.length > 0 &&
              <Space direction="vertical" style={{ width: '100%' }}>
                {intl.get('end_talk.rating_tip')}
                <Select defaultValue={rating} style={{ width: '100%' }} onChange={ratingChange}>
                  {
                    talkSetting?.talk_mark_options.map((item: talkMarkOptionsTypes, index: number) =>{
                      return <Option key={index} value={item.score}>{item.title}</Option>
                    })
                  }
                </Select>
              </Space>
           }
          </>
        }
      </div>,
      onOk: async () => {
        await handleFinishTalk()
      },
      onCancel() {
        showEndModal = false
      }
    }
    if(!showEndModal){
      confirm(modalConfig)
      showEndModal = true
    }
  }
  
  // 结束洽谈
  const handleFinishTalk = async () => {
    if(roomInfo?.roomId && userInfo){
      await sendMessage({
        header:{
          roomId: roomInfo?.roomId,  //房间ID
          clientId: CLIENT_ID,  //设备id
        },
        body:{
          sender: userInfo,
          message: {
            msgType: msgTypes.FINISH_ROOM,
            content: {
              rating: ratingStore,
            }
          }
        }
      }, 'end')
      showEndModal = false
      window.location.reload()
    }
  }

  // 获取walkin设置
  const getWalkinSetting = async () => {
    const {status, data} = await Request({
      url: `walkin_setting`,
    })
    try {
      console.log('data', data)
      if(status && data){
        setWalkinSetting(data)
        if(data.walk_in_mark_status && data.walk_in_mark_options.length){
          setRating(data.walk_in_mark_options[0].score)
        }
      }
    } catch (error) {
      console.log('getWalkinSetting', error)
    }
  }

  useEffect(() => {
    ratingStore = rating
  }, [rating])

  useEffect(() => {
    if(headerType === TALK_TYPE_WALKIN){
      getWalkinSetting()
    }
  }, [])

  return(
    <>
      {
        canEndCall &&
        <Button
          type="primary"
          shape="round"
          icon={<PoweroffOutlined />}
          danger
          onClick={() => chatFinish()}>
          {children}
        </Button>
      }
    </>
  )
}

const stateToProps = (state: {
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
}) => {
  const { userInfo, roomInfo } = state
  return {
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null, null, {forwardRef: true})(FinishBtn)