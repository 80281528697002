import React from 'react'
import clsx from 'clsx'
import IconFont from '@/components/IconFont'
import { Card, Flex, FlexItem, Icon, Text } from 'kermityu_chatui'
import { getExtName, prettyBytes} from '@/utils'
import styles from './index.module.scss'

export type FileCardProps = {
  className?: string,
  fileName: string,
  fileSize: number,
  children?: React.ReactNode,
}

const FileCard: React.FC<FileCardProps> = (props) => {
  const { className, fileName, fileSize, children} = props
  const ext = getExtName(fileName)
  return(
    <Card className={clsx(styles['FileCard'], className)} size="xl">
      <Flex>
        <div className={styles['FileCard-icon']} data-type={ext}>
          <IconFont size={60} type="icon-file-box"/>
          <Text className={styles['FileCard-ext']}>{ext}</Text>
        </div>
        <FlexItem className={styles['FileCard-content']}>
          <Text className={styles['FileCard-name']}>{fileName}</Text>
          <div className={styles['FileCard-meta']}>
            <span className={styles['FileCard-size']}>{prettyBytes(fileSize)}</span>
            {children}
          </div>
        </FlexItem>
      </Flex>
    </Card>
  );
};

export default FileCard;