import intl from 'react-intl-universal'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Provider } from 'react-redux'
import ppo from 'ppo'
import routes from "@/config/routes"
import FullLoading from '@/components/FullLoading'
import SubRoutes from '@/components/SubRoutes'
import { useStore } from '@/store/index'
import { getLanguage  } from '@/utils'
import { Result, Button, ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import enUS from 'antd/lib/locale/en_US'
import jaJP from 'antd/lib/locale/ja_JP'
import zhHK from 'antd/lib/locale/zh_HK'

const antdLocales = {
  "en-US": enUS,
  "zh-CN": zhCN,
  "zh-hant": zhHK,
  "ja-JP": jaJP,
}

// 项目语言包
const locales = {
  "en-US": require('@/locales/en-US.json'),
  "zh-CN": require('@/locales/zh-CN.json'),
  "zh-hant": require('@/locales/zh-hant.json'),
  "ja-JP": require('@/locales/ja-JP.json'),
}

const App = () => {
  const [language, setLanguage] = useState(getLanguage())
  const [laoding, setLaoding] = useState(true)
  const store = useStore({})
  const redirectUrl = ppo.getUrlParam('redirect') || ''
  
  useEffect(() => {
    // ConfigProvider.config({
    //   theme: {
    //     primaryColor: '#0c3372',
    //   },
    // })
    const locale = store.getState()?.global?.locale
    console.log('app====locale', locale)
    setLanguage(locale)
    intl.init({
      currentLocale: locale,
      locales,
    }).then(() => {
      console.log('多语言初始化-currentLocale', locale)
      setLaoding(false)
    })
  }, [])

  return (
    <Provider store={store}>
      {
        laoding?
        <FullLoading size="large"/>:
        <ConfigProvider locale={antdLocales[language]}>
          {
            ppo.isMobile() && !ppo.getUrlParam('debug') ?
            <Result
              status="info"
              title={intl.get('error.mobile_tip.title')}
              subTitle={intl.get('error.mobile_tip.sub_title')}
              extra={
                redirectUrl && <Button type="primary" size="large" href={redirectUrl}>{intl.get('error.back_home')}</Button>
              }
            />:
            <Router>
              <SubRoutes routes={routes}/>
            </Router>
          }
        </ConfigProvider>
      }
    </Provider>
  )
}

export default App