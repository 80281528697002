import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
import Loading from '@/components/Loading'
import IconFont from '@/components/IconFont'
import Request from '@/utils/request'
import { setPPTSate } from '@/store/actions'
import EventCenter from '@/utils/IMSService/EventCenter'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import type { Swiper as SwiperClass} from 'swiper/types/index.d'
import { Thumbs, Navigation, Scrollbar } from 'swiper'
import 'swiper/swiper.scss'
import { Select, Button, Space, Image } from 'antd'
import {
  PoweroffOutlined,
  RollbackOutlined
} from '@ant-design/icons'
import styles from './index.module.scss'
import clsx from 'clsx'
import * as msgTypes from '@/utils/IMSService/msgTypes'

// msgTypes
import type { pptSateTypes } from '@/store/store.d'


import type { contentTypes, messageTypes, sendInfoTypes } from '@/utils/IMSService/index.d'
import { update } from 'lodash-es'

const { Option } = Select

export type pptTypes = {
  images: string[],
  title: string
}

export type PPTProps = {
  pptSate: pptSateTypes,
  sendMessage: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
}

const PPT:React.FC<PPTProps> = (props) => {
  const dispatch = useDispatch()
  const { sendMessage, pptSate } = props
  const [loading, setLoading] = useState(true)
  const [ppts, setPpts] = useState<pptTypes[]>([])
  const [curPpt, setCurPpt] = useState<pptTypes | null>(null)
  const [curIndex, setCurIndex] = useState<number | null>(null)
  const [swiperWidth, setSwiperWidth] = useState<any>(0)
  const [mainSwiper, setMainSwiper] = useState<SwiperClass | null>(null)
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)
  const [initialSlide, setInitialSlide] = useState(0)
  const pptContainerEl = useRef<HTMLDivElement>(null)

  // 获取ppt数据
  const getPPT = async () => {
    setLoading(true)
    const { data } = await Request({
      method: 'GET',
      url: 'ppt',
    })
    setLoading(false)
    console.log('data', data)
    try {
      data && setPpts(data)
      if(pptSate?.image && pptSate?.index !== null){
        const index = pptSate?.index || 0
        const curPPT = data[pptSate?.index || 0]
        const imageIndex = curPPT.images.findIndex((item:string) => item === pptSate?.image) || 0
        setCurIndex(index)
        setInitialSlide(imageIndex)
        setCurPpt(curPPT)
        console.log('index', index, 'curPPT', curPPT, 'imageIndex', imageIndex)
      }
    } catch (error) {
      console.log('getPPT__error', error)
    }
  }

  // 切换ppt
  const switchPPT = (index:number) => {
    index && setCurPpt(ppts[index-1])
    sendMsg(msgTypes.PPT_START, {})
    dispatch(setPPTSate({
      index: index-1,
    }))
  }

  // 重选ppt
  const reSelectPPT = () => {
    mainSwiper?.destroy(true, true)
    thumbsSwiper?.destroy(true, true)
    setMainSwiper(null)
    setThumbsSwiper(null)
    setCurIndex(null)
    setCurPpt(null)
    sendMsg(msgTypes.PPT_SWITCH, {
      imgUrl: '',
      pptIndex: null,
    })
  }

  // 结束ppt
  const endPPT = () => {
    reSelectPPT()
    sendMsg(msgTypes.PPT_END, {})
    dispatch(setPPTSate({
      open: false,
      index: 0,
      image: '',
    }))
  }

  // 幻灯片变化
  const slideChange = (realIndex:number) => {
    setCurIndex(realIndex)
    const curImg = curPpt?.images[realIndex]
    console.log('slideChange===realIndex', realIndex, curImg)
    dispatch(setPPTSate({
      image: curImg,
      index: realIndex,
    }))
    sendMsg(msgTypes.PPT_SWITCH, {
      imgUrl: curImg,
      pptIndex: realIndex,
    })
  }

  // 发送消息
  const sendMsg = (msgType: string, content: contentTypes) => {
    console.log('content', content)
    sendMessage({
      msgType,
      content,
    }, (msgInfo:sendInfoTypes | false) => {
      msgInfo && console.log('发送ppt消息成功')
    })
  }

  useEffect(() => {
    getPPT()
  }, [])

  useEffect(() => {
    setSwiperWidth(pptContainerEl?.current?.offsetWidth)
  }, [curPpt])

  useEffect(() => {
    EventCenter.addListener('endPPT', endPPT)
    window.addEventListener('resize', onResize)
    return () => {
      EventCenter.removeListener('endPPT', endPPT)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // 页面窗口发生变化
  const onResize = () => {
    setSwiperWidth(pptContainerEl?.current?.offsetWidth)
    mainSwiper?.update()
    thumbsSwiper?.update()
  }

  return(
    <div className={styles['ppt']}>
      {
        loading?
        <Loading size="large" center={true}/>:
        <>
          {
            !curPpt?
            <Select defaultValue={0} size="large" className={styles['select-ppt']} onChange={switchPPT}>
              <Option value={0}>{intl.get('ppt.select_ppt')}</Option>
              {
                ppts.map((item:pptTypes, index:number) => <Option value={index+1} key={index+1}>{item.title}</Option>)
              }
            </Select>:
            <div className={styles['ppt-container']} ref={pptContainerEl}>
              <div className={styles['main-swiper-box']} style={{width: swiperWidth}}>
                {
                  swiperWidth?
                  <Swiper
                    className={styles['main-swiper']}
                    navigation={true}
                    modules={[Thumbs, Navigation]}
                    thumbs={{ swiper: thumbsSwiper }}
                    initialSlide={initialSlide}
                    onInit={(swiper) => {
                      setMainSwiper(swiper)
                      slideChange(swiper.realIndex)
                    }}
                    onSlideChange={(swiper) => slideChange(swiper.realIndex)}
                    onSwiper={(swiper:SwiperClass) => setMainSwiper(swiper)}>
                    {
                      curPpt.images.map((item, index) =>
                      <SwiperSlide
                        className={styles['swiper-item']}
                        key={index}>
                        <Image
                          width={'100%'}
                          height={'100%'}
                          src={item}
                        />
                      </SwiperSlide>)
                    }
                  </Swiper>:
                  null
                }
              </div>
              <div className={styles['action-bar']}>
                <div className={styles['bar-hd']}>
                  <div className={styles['hd-left']}>
                    {
                      curIndex !== null && curPpt?
                      <span className={styles['select-tip']}>
                        {curPpt.title} {curIndex +1}/{curPpt.images.length}
                      </span>:
                      null
                    }
                  </div>
                  <Space className={styles['hd-right']}>
                    <Button
                      shape="round"
                      icon={<RollbackOutlined />}
                      onClick={reSelectPPT}>
                      {intl.get('ppt.re_select')}
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      icon={<IconFont type='icon-pptend' size="small"/>}
                      danger
                      ghost
                      onClick={endPPT}>
                      {intl.get('ppt.end_share')}
                    </Button>
                  </Space>
                </div>
                <div className={styles['bar-bd']}  style={{width: swiperWidth}}>
                  {
                    swiperWidth?
                    <Swiper
                      className={styles['bar-swiper']}
                      scrollbar={{
                        hide: false,
                        draggable: true,
                      }}
                      modules={[Thumbs, Scrollbar]}
                      slidesPerView="auto"
                      spaceBetween={10}
                      onInit={(swiper) => setThumbsSwiper(swiper)}
                      onSwiper={(swiper:SwiperClass) => setThumbsSwiper(swiper)}>
                      {
                        curPpt.images.map((item, index) =>
                        <SwiperSlide
                          className={clsx(styles['swiper-item'], curIndex === index ? styles['__active'] : null)}
                          style={{backgroundImage: `url(${item})`}}
                          key={index}>
                        </SwiperSlide>)
                      }
                    </Swiper>:
                    null
                  }
                </div>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default PPT