import React from 'react'
import Loading from '@/components/Loading'

export type fullLoadingTypes = {
  style?: object
  center?: boolean
  size?: number | 'small' | 'large',
  children?: React.ReactNode
}


const FullLoading:React.FC<fullLoadingTypes> = (props) => {
  return(
    <div style={{width:'100%', height: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Loading {...props}/>
    </div>
  )
}

export default FullLoading;