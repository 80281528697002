import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import intl from 'react-intl-universal'
import { connect  } from 'react-redux'
import BusinessCard from '@/components/BusinessCard/BusinessCard'
import type { BusinessCardRefProps } from '@/components/BusinessCard/BusinessCard'
import type { globalTypes } from '@/store/store.d'
import styles from './BusinessCardDetail.module.scss'

export type BusinessCardDetailProps = {
  ctaId?: number,
  global?: globalTypes,
}

export type BusinessCardDetailRefProps = {
  updateBusinessCard: () => void,
}

const BusinessCardDetail = forwardRef<BusinessCardDetailRefProps, BusinessCardDetailProps>((props, childRef) => {
  const { ctaId, global } = props

  const businessCardref = useRef<BusinessCardRefProps>(null)

  useImperativeHandle(childRef, () => ({
    // 更新名片信息
    updateBusinessCard: () => {
      businessCardref?.current?.updateBusinessCard()
    }
  }))

  return(
    <div className={styles['business-card-detail']}>
      <div className={styles['info-hd']}>
        <h3 className={styles['hd-title']}>{intl.get('user_info.detail_title')}</h3>
      </div>
      <div className={styles['info-bd']}>
        {
          ctaId && <BusinessCard ref={businessCardref} ctaId={ctaId}/>
        }
      </div>
    </div>
  )
});

const stateToProps = (state: {
  global: globalTypes,
}) => {
  const { global } = state
  return {
    global
  }
}

export default connect(stateToProps, null, null, {forwardRef: true})(BusinessCardDetail)