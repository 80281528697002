import React, { useRef, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import ppo from 'ppo'
import dayjs from 'dayjs'
import Header from '@/components/Header/index'
import FullLoading from '@/components/FullLoading'
import CheckTalkState from '@/components/CheckTalkState'
import type { checkTalkStateRefProps } from '@/components/CheckTalkState'
import VideoTalk from '@/components/VideoTalk'
import AnonymousModal from '@/components/AnonymousModal'
import PreEnter from '@/components/VideoTalk/PreEnter'
import { WS_URL, CLIENT_DESKTOP_APP, COUNTDOWN_SECOND } from '@/config/index'
import ChatRoom from '@/components/ChatRoom/index'
import IMSService from '@/utils/IMSService/index'
import EventCenter from '@/utils/IMSService/EventCenter'
import Request from '@/utils/request'
import {bindClientId, sendMessage} from '@/utils/socketMessage'
import { TALK_TYPE_PSA } from '@/config/index'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import { getUserInfo, getRoomInfo, setPPTSate, setGlobalClientId} from '@/store/actions'
import type { globalTypes, videoTalkTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import type { messageTypes, contentTypes, sendInfoTypes, noticeItemTypes } from '@/utils/IMSService/index.d'
import type { AnonymousModalRefProps } from '@/components/AnonymousModal/index.d'
import type { videoTalkRefProps } from '@/components/VideoTalk/index.d'
import type { chatRoomRefProps } from '@/components/ChatRoom/index.d'
import type { headerRefProps } from '@/components/Header/index'
import { message as antdMessage, Modal, Alert, Button } from 'antd'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import styles from './index.module.scss'
import clsx from 'clsx'

const { confirm, info } = Modal

declare global {
  interface Window { 
    mozNotification: any,
    webkitNotification: any
  }
}

let chatIMSService:any; // 聊天服务

export type TalkPropsTypes = {
  match: {
    params: {
      roomId?: string
    }
  },
  locale: string,
  global: globalTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  videoTalk: videoTalkTypes,
}
let walkinUrl = '' // walkin链接地址
let nextTalkUrl = '' // 下场洽谈地址
let countdownTimer:any = null
// 洽谈页面
const TalkRoom: React.FC<TalkPropsTypes> = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { global, locale, userInfo, videoTalk, roomInfo } = props
  const [initLoading, setInitLoading] = useState(true) // 页面初始化loading
  const [finishModal, setFinishModal] = useState(false) // 会话结束弹层
  const [enterRoomTime, setEnterRoomTime] = useState(new Date().getTime()) // 自己进入房间时服务端时间
  const [buyerLate, setBuyerLate] = useState(false) // 买家迟到
  const [nextTalkMsg, setNextTalkMsg] = useState<contentTypes | null>(null) // 下场洽谈马上开始
  const [socketOff, setSocketOff] = useState(false) // socket断开了
  const anonymousModalRef = useRef<AnonymousModalRefProps>(null)
  const videoTalkRef = useRef<videoTalkRefProps>(null)
  const chatRoomRef = useRef<chatRoomRefProps>(null)
  const checkTalkStatRef = useRef<checkTalkStateRefProps>(null)
  const headerRef = useRef<headerRefProps>(null)
  const clientDesktopApp = localStorage.getItem('clientDesktopApp')
  const isDesktopApp = Boolean(clientDesktopApp && clientDesktopApp === CLIENT_DESKTOP_APP) // 是否来自桌面端app

  // 发送消息
  const sendMsg = async (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void, type: string = "sendMsg") => {
    console.log('sendMessage=======message', message)
    chatIMSService && chatIMSService.sendMessage({
      header:{
        roomId: roomInfo?.roomId,  //房间ID
        path: `api/chatroom/${type}`,  //消息路径
      },
      body:{
        sender: userInfo,
        message,
      }
    }, (msgInfo: sendInfoTypes | false) => {
      msgInfo && callBackFn && callBackFn(msgInfo)
    })
  }

  // 结束洽谈
  const finishTalk = async (state:{
    status: string,
    title: string,
  }) => {
    await videoTalkRef.current?.leaveVideo()
    history.push({
      pathname: '/talk',
      state
    })
  }

  // 处理ims链接消息
  const handleImsNotice = async (notice:noticeItemTypes) => {
    console.log('handleImsNotice__notice', notice)
    switch(notice.code){
      case 2:
        // 初始化成功
        // 链接成功发送进入房间
        console.log('连接初始化成功')
        setSocketOff(false)
        sendMsg({
          msgType: 'text',
          content: {
            text: '登录',
            talkType: TALK_TYPE_PSA,
          },
        }, () => {
          sendMsg({
            msgType: msgTypes.ACTION_JOIN_ROOM,
            content: {
              text: '加入房间'
            },
          }, () => {
            const isInRoom = roomInfo?.historyUsers ? roomInfo?.historyUsers.find((item: userInfoTypes) => item ? userInfo.id === item.user_id : false) : false
            // 加入房间后通知其他人
            sendMsg({
              msgType: !isInRoom ? msgTypes.JOIN_ROOM : msgTypes.ENTER_ROOM, //不在房间发送加入房间
            }, () => {
              updateRoomInfo()
            })
          }, 'joinRoom')
        }, 'login')
        break
      case -3:
        setSocketOff(true)
        console.log('断开连接,重连中, ping timeout')
        break
      default:
        break
    }
  }

  // 处理ims消息
  const handleImsMessage = (msg:sendInfoTypes) => {
    //接收消息的处理
    console.log('handleImsMessage__msg', msg)
    const { header, body } = msg // 消息类型
    const { message, sender } = body // 消息主体
    const { msgType, content } = message // 消息类型
    if(header?.roomId === roomInfo?.roomId){
      // 当前房间消息
      switch(msgType){
        case msgTypes.JOIN_ROOM:
        case msgTypes.ENTER_ROOM:
          if(!header.isCurClient) {
            antdMessage?.success(intl.get('talk.join_room', {name: sender?.name}))
            msgType === msgTypes.JOIN_ROOM && chatRoomRef.current?.acceptMessage(msg)
          } else {
            header?.serviceAt && setEnterRoomTime(header?.serviceAt);
          }
          updateRoomInfo(true)
          sender.type !== 4 && setBuyerLate(false)
          break
        case msgTypes.EXIT_ROOM:
        case msgTypes.EX_EXIT_ROOM:
          if(!header.isCurClient) {
            if(isDesktopApp && msgTypes.EX_EXIT_ROOM){
              // 桌面端收到展商退出房间
              history.push('/verify')
            } else {
              antdMessage?.success(intl.get('talk.exit_room', {name: sender?.name}))
              updateRoomInfo(true)
            }
          } else {
            // console.log('自己结束会话');
            if(msgType === msgTypes.EX_EXIT_ROOM){
              // 展商收到自己离开房间消息
              console.log('展商收到自己离开房间消息');
              info({
                width: 500,
                icon: <ExclamationCircleOutlined />,
                centered: true,
                title: intl.get('talk.state.info.title'),
                onOk(){
                  console.log('nextTalkUrl', nextTalkUrl)
                  console.log('walkinUrl', walkinUrl)
                  if(nextTalkUrl || walkinUrl){
                    window.location.href = nextTalkUrl || walkinUrl
                  } else {
                    finishTalk({
                      status: 'success',
                      title: intl.get('talk.state.info.title'),
                    })
                  }
                },
                onCancel() {
                  walkinUrl = ''
                  nextTalkUrl = ''
                }
              })
            } else {
              if(isDesktopApp){
                history.push('/verify')
              } else {
                finishTalk({
                  status: 'info',
                  title: intl.get('talk.state.info.title'),
                })
              }
            }
          }
          break
        case msgTypes.FINISH_ROOM:
          // 收到结束会话
          if(!finishModal){
            setFinishModal(true)
            let secondsToGo = COUNTDOWN_SECOND;
            let modalConfig:{
              content?: string,
              okText?: string,
              [prop: string]: any,
            } = {
              width: 500,
              icon: <ExclamationCircleOutlined />,
              centered: true,
              title: intl.get('end_talk.tip'),
              onOk(){
                console.log('nextTalkUrl', nextTalkUrl)
                console.log('walkinUrl', walkinUrl)
                if(nextTalkUrl || walkinUrl){
                  window.location.href = nextTalkUrl || walkinUrl
                } else {
                  if(isDesktopApp){
                    clearCountdownTimer()
                    history.push('/verify')
                  } else {
                    finishTalk({
                      status: 'success',
                      title: intl.get('talk.state.success.title'),
                    })
                  }
                }
              },
              onCancel() {
                walkinUrl = ''
                nextTalkUrl = ''
              }
            }
            if(isDesktopApp){
              modalConfig.content = intl.get('desktop.countdown_tip')
              modalConfig.okText = `${intl.get('desktop.back_now')}(${secondsToGo}s)`
            }
            const modal = roomInfo?.talk_setting?.talk_end_force_stop_status || isDesktopApp ? info(modalConfig) : confirm(modalConfig)
            if(isDesktopApp){
              countdownTimer = setInterval(() => {
                secondsToGo -= 1;
                if(secondsToGo <= 0){
                  clearCountdownTimer()
                  modal.destroy()
                  history.push('/verify')
                } else {
                  modal.update({
                    okText:`${intl.get('desktop.back_now')}(${secondsToGo}s)`
                  });
                }
              }, 1000);
            }
          }
          break
        case msgTypes.CHAT_TEXT:
        case msgTypes.CHAT_EMOJI:
        case msgTypes.CHAT_IMG:
        case msgTypes.CHAT_FILE:
        case msgTypes.BUSINESS_CARD_APPLY:
        case msgTypes.BUSINESS_CARD_AGREE:
          // 聊天消息过滤掉自己发送的消息
          !header.isCurClient && chatRoomRef.current?.acceptMessage(msg)
          break
        case msgTypes.PPT_START:
        case msgTypes.PPT_END:
          // ppt开始结束消息
          // chatRoomRef.current?.acceptMessage(msg)
          break
        case msgTypes.PPT_SWITCH:
          issuedPptMsg(msg)
          break
        default:
          break
      }
    } else {
      switch(msgType){
        case msgTypes.NEW_NEXT_TALK:
          setNextTalkMsg(content || null)
          break;
        case msgTypes.BUYER_LATE:
          setBuyerLate(true)
          break;
      }
    }
  }

  const clearCountdownTimer = () => {
    if(countdownTimer){
      window.clearInterval(countdownTimer)
      countdownTimer = null
    }
  }

  //下发ppt消息
  const issuedPptMsg = (msg: sendInfoTypes) => {
    console.log('ppt消息',  userInfo.type, videoTalkRef.current)
    userInfo.type !== 4 && videoTalkRef.current?.acceptMessage(msg)
  }

  const handLocalStreamChange = (name: string) => {
    console.log('handleLocalChange===name', name)
    videoTalkRef.current?.localStreamChange(name)
  }

  // 更新房间信息
  const updateRoomInfo = (isUpdate: boolean = false) => {
    if(roomInfo?.roomId){
      dispatch(
        getRoomInfo(roomInfo?.roomId, (data: roomInfoTypes) => {
          if(!isUpdate && data?.lasPptMessage){
            // 有ppt消息
            // console.log('roomInfo.lasPptMessage', roomInfo.lasPptMessage)
            if(userInfo.type === 4){
              const { body } = data?.lasPptMessage
              const { message } = body
              const { content } = message
              if(content?.imgUrl && content?.pptIndex !== null){
                console.log('展商ppt状态', content)
                dispatch(setPPTSate({
                  open: content.imgUrl ? true : false,
                  index: content.pptIndex,
                  image: content.imgUrl,
                }))
              }
            } else {
              issuedPptMsg(data?.lasPptMessage)
            }
          }
        }, () => {}, true)
      )
    }
  }

  // 进入房间
  const enterRoom = () => {
    chatIMSServiceInit()
  }

  useEffect(() => {
    document.title = intl.get('room_types.scheduled')
    const paramRoomId = props?.match?.params?.roomId
    const redirect = ppo.getUrlParam('redirect')
    sessionStorage.setItem('REDIRECT_URL', redirect || '')
    if(paramRoomId){
      dispatch(
        getRoomInfo(paramRoomId, (data: roomInfoTypes) => {
          if(data?.isBeforeEnter){
            history.push({
              pathname: '/error',
              state: {
                status: 'warning',
                title: intl.get('error.forward_start.title'),
                subTitle: intl.get('error.forward_start.sub_title'),
              }
            })
          }
          const token = ppo.getUrlParam('token')
          if(token){
            dispatch(getUserInfo(token, () => {
              // 登录成功
              setInitLoading(false)
            }, () => {
              // 登录失败
              history.push({
                pathname: '/error',
                state: {
                  status: 'error',
                  title: intl.get('error.login_fail.title'),
                  subTitle: intl.get('error.login_fail.sub_title'),
                }
              })
            }))
          } else {
            //邀请匿名用户
            if(!data.isEnd){
              setInitLoading(false)
              anonymousModalRef.current?.open()
            } else {
              history.push({
                pathname: '/error',
                state: {
                  status: 'error',
                  title: intl.get('error.end_tip.title'),
                  subTitle: intl.get('error.end_tip.sub_title'),
                }
              })
            }
          }
        }, () => {
          history.push({
            pathname: '/error',
            state: {
              status: 'error',
              title: intl.get('error.no_room.title'),
              subTitle: intl.get('error.no_room.sub_title'),
            }
          })
        })
      )
    }
    return () => {
      // 取消监听
      EventCenter.removeListener('ims_connect_notify', handleImsNotice)
      EventCenter.removeListener('ims_message_notify', handleImsMessage)
      EventCenter.removeListener('handleLocalStream', handLocalStreamChange)
      chatIMSService && chatIMSService.stop()
      clearCountdownTimer()
    }
  }, [])

  //im初始化
  const chatIMSServiceInit = () => {
    // 创建消息服务
    chatIMSService = new IMSService({
      socketUrl: WS_URL,
      listeners: {
        onConnectNotify:(resp:any)=>{
          EventCenter.emit('ims_connect_notify',resp);
        },
        onMsgNotify:(msg:any)=>{
          EventCenter.emit('ims_message_notify',msg);
        }
      }
    })
    EventCenter.addListener('ims_connect_notify', handleImsNotice)
    EventCenter.addListener('ims_message_notify', handleImsMessage)
    EventCenter.addListener('handleLocalStream', handLocalStreamChange)
  }

  // 跳转导walkin页面
  const gotoWalkin = () => {
    const token = ppo.getUrlParam('token')
    const redirect = ppo.getUrlParam('redirect')
    nextTalkUrl = ''
    walkinUrl = `/walkin?token=${token}&lang=${locale}&redirect=${redirect}`
    headerRef.current?.chatFinish(
      false,
      intl.get('end_talk.live_tip'),
      () => {},
      () => {
        walkinUrl = ''
      }
    )
  }

  // 到下场洽谈
  const gotoNextTalk = () => {
    const token = ppo.getUrlParam('token')
    const redirect = ppo.getUrlParam('redirect')
    walkinUrl = ''
    nextTalkUrl = `${nextTalkMsg?.talk?.room_url}?token=${token}&lang=${locale}&redirect=${redirect}`
    headerRef.current?.chatFinish(
      false,
      intl.get('end_talk.live_tip'),
      () => {},
      () => {
        nextTalkUrl = ''
      }
    )
  }

  return (
    <>
      {
        initLoading?
        <FullLoading size="large"/>:
        <>
          {
            !videoTalk?.initState && !roomInfo?.isEnd?
            <PreEnter enterRoom={enterRoom}/>:
            <div className={styles['talk']}>
              <Header
                ref={headerRef}
                headerType={TALK_TYPE_PSA}
                sendMsg={sendMsg}/>
              {
                socketOff &&
                <Alert
                  message={intl.get('socket_off')}
                  type="warning"
                  showIcon
                  action={
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      {intl.get('refres')}
                    </Button>
                  }
                />
              }
              {
                buyerLate &&
                <Alert
                  message={intl.get('talk_tip.buyer_lated')}
                  type="warning"
                  showIcon
                  action={
                    <Button type="primary" size="small" onClick={gotoWalkin}>
                      {intl.get('talk_tip.goto_jump')}
                    </Button>
                  }
                />
              }
              {
                nextTalkMsg &&
                <Alert
                  message={intl.get('talk_tip.next_talk', {
                    name: nextTalkMsg?.user?.name,
                    start_at: dayjs(nextTalkMsg?.talk?.start_at).format('HH:mm'),
                    end_at: dayjs(nextTalkMsg?.talk?.end_at).format('HH:mm')
                  })}
                  type="warning"
                  showIcon
                  action={
                    <Button type="primary" size="small" onClick={gotoNextTalk}>
                      {intl.get('talk_tip.goto_jump')}
                    </Button>
                  }
                />
              }
              <div className={styles['talk-layout']}>
                {
                  !roomInfo?.isEnd?
                  <div className={styles['videotalk-section']}>
                    <div className={styles['videotalk']}>
                      <VideoTalk
                        ref={videoTalkRef}
                        userID={`${userInfo?.id}`}
                        roomID={roomInfo?.roomId || ''}
                        userInfo={userInfo}
                        videoTalk={videoTalk}
                        roomMembers={roomInfo?.users || []}
                        sendMessage={sendMsg}/>
                    </div>
                  </div>:
                  null
                }
                <div className={clsx(styles['chatroom-section'], roomInfo?.isEnd ? styles['isEnd'] : null)}>
                  <ChatRoom
                    ref={chatRoomRef}
                    locale={locale}
                    roomId={roomInfo?.roomId || ''}
                    userId={userInfo.id}
                    isEnd={roomInfo?.isEnd}
                    enterRoomTime={enterRoomTime}
                    sendMessage={sendMsg}/>
                </div>
              </div>
            </div>
          }
          {
            userInfo && userInfo.type === 4 && roomInfo &&
            <CheckTalkState ref={checkTalkStatRef} talkType={TALK_TYPE_PSA} roomId={roomInfo?.roomId}/>
          }
        </>
      }
      <AnonymousModal ref={anonymousModalRef}/>
    </>
  )
}

const stateToProps = (state: {
  videoTalk: videoTalkTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
  global: globalTypes,
}) => {
  const { global, videoTalk, userInfo, roomInfo } = state
  return {
    global,
    locale: global.locale,
    videoTalk,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null)(TalkRoom)