import ppo from 'ppo'
import Request from '@/utils/request'
import type { sendMsgTypes } from '@/utils/IMSService/index.d'
import { sdkVersion, clientId } from '@/utils/IMSService/index'

export const sendMessage = async (sendMsg:sendMsgTypes, action: string = 'send') =>  {
  console.log('sendMsg', sendMsg)
  const { header, body } = sendMsg
  const { sender, message} = body
  
  const sendMsgInfo = {
    header:{
      msgId: ppo.uuid(), // 生成消息的唯一标识的消息id,
      version: sdkVersion, //版本
      roomId: header.roomId,  //房间ID
      createdAt: new Date().getTime(), // 发送消息的时间
      clientId: header.clientId || clientId, // 客户端id
      isCurClient: true, // 是否是当前客户端发送的消息
    },
    body:{
      device: ppo.ua('lower'),
      sender,
      message,
    }
  }
  const res = await Request({
    method: 'POST',
    url: `socket/message/${action}`,
    data: sendMsgInfo
  })
  console.log('sendMessage', res)
}

// 绑定设备id
export const bindClientId = async (clientId:string) =>  {
  const res = await Request({
    method: 'POST',
    url: `socket/message/bind`,
    data: {
      clientId
    }
  })
  console.log('bindClientId', res)
}