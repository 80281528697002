import React, { useState, useEffect, useRef } from 'react'
import StreamBar from './streamBar'
import clsx from 'clsx'
import { Avatar, Image } from 'antd'
import type { userInfoTypes } from '@/store/store.d'
import styles from './stream.module.scss'

export type UserCardPropsTypes = {
  userInfo?: userInfoTypes, //用户信息
  hasPPT: boolean | string,
}

const UserCard:React.FC<UserCardPropsTypes> = (props) => {
  const { userInfo, hasPPT } = props

  return (
    <div className={clsx(styles.item, hasPPT ? styles['item-mini'] : null)}>
      <div className={styles['item-view']}>
      </div>
      <div className={styles['item-cover']}>
        <Avatar size={100} src={<Image src={userInfo?.avatar} style={{ width: '100%' }} />} />
      </div>
      <StreamBar
        hasPPT={hasPPT}
        className={clsx(styles['item-control'], hasPPT ? styles['control-mini'] : null)}
        userInfo={userInfo}/>
    </div>
  )
}

export default UserCard
