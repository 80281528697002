import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import copy from 'copy-to-clipboard'
import intl from 'react-intl-universal'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Request from '@/utils/request'
import IconFont from '@/components/IconFont'
import UserType from '@/components/UserType/index'
import Loading from '@/components/Loading'
import { Modal, Button, Space, message, Avatar} from 'antd'
import type { userInfoTypes, roomInfoTypes } from '@/store/store.d'
import styles from './BusinessCard.module.scss'

type cardInfoTypes = {
  name: string,
  headimgurl: string,
  company: string,
  position: string,
  tel: string,
  email: string,
  type: number,
  cta_id: number,
  exchange_status: number,
}

type BusinessCardPropsTypes = {
  ctaId: number,
  userInfo?: userInfoTypes,
  roomInfo?: roomInfoTypes,
}

export type BusinessCardRefProps = {
  updateBusinessCard: () => void,
}

const BusinessCard = forwardRef<BusinessCardRefProps, BusinessCardPropsTypes>((props, childRef) => {
  const { ctaId, roomInfo, userInfo } = props
  const [canSee, setCanSee] = useState(false)
  const [cardInfo, setCardInfo] = useState<cardInfoTypes | null>(null)

  useImperativeHandle(childRef, () => ({
    // 更新名片信息
    updateBusinessCard: () => {
      getBusinessCardInfo(ctaId)
    }
  }))

  // 获取名片详情
  const getBusinessCardInfo = async (ctaId:number) => {
    setCardInfo(null)
    const {status, data} = await Request({
      url: `${roomInfo?.roomId}/business-card/${ctaId}`
    })
    try {
      if(status && data){
        setCanSee(data.exchange_status === 4 || ctaId === userInfo?.cta_id)
        setCardInfo({...data, cta_id: ctaId})
      }
    } catch (error) {
      console.log('getBusinessCardInfo -> error', error)
    }
  }

  // 复制文案
  const copyText = (text:string) => {
    copy(text)
    message.success(intl.get("business_card.copy_success"))
  }

  // 申请交换名片
  const exchangeCard = async (ctaId: number) => {
    Modal.confirm({
      icon: null,
      centered: true,
      title: intl.get('business_card.exchange_tip'),
      onOk: async () => {
        const { status } = await Request({
          method: "POST",
          url: `${roomInfo?.roomId}/business-card/${ctaId}`,
        })
        try {
          if(status){
            getBusinessCardInfo(ctaId)
            message.success(intl.get("business_card.success", {
              name: intl.get('business_card.exchange_apply')
            }))
          }
        } catch (error) {
          console.log('exchangeCard -> error', error);
        }
      }
    })
  }

  // 同意交换名片
  const agreeCard = async (ctaId: number, type: 1 | 2) => {
    Modal.confirm({
      icon: null,
      centered: true,
      title: intl.get(`business_card.${type === 1 ? 'agree_tip' : 'ignore_tip'}`),
      onOk: async () => {
        const { status } = await Request({
          url: `${roomInfo?.roomId}/business-card-apply/${ctaId}`,
          method: "POST",
          data: {
            type
          }
        })
        console.log('status', status)
        try {
          if(status){
            getBusinessCardInfo(ctaId)
            message.success(intl.get("business_card.success", {
              name: intl.get(type === 1 ? 'business_card.card_state.1' : 'business_card.ignore_apply')
            }))
          }
        } catch (error) {
          console.log('exchangeCard -> error', error);
        }
      }
    })
  }

  useEffect(() => {
    getBusinessCardInfo(ctaId)
  }, [ctaId])

  return(
    <>
      {
        !cardInfo?
        <Loading size="large" style={{display: 'block', margin:'8px auto'}}/>:
        <Space
          className={styles['user-info']}
          direction="vertical"
          style={{width: '100%'}}>
          <Space
            className={styles['basic-info']}
            direction="vertical"
            align="center">
            {
              cardInfo.headimgurl?
              <Avatar size={80} src={cardInfo.headimgurl}/>:
              <Avatar size={80}>{cardInfo.name&&cardInfo.name.split('')[0]}</Avatar>
            }
            <h3 className={styles['user-name']}>{cardInfo.name}</h3>
            <UserType type={cardInfo.type}/>
          </Space>
          <div className={styles['info-list']}>
            <div className={styles['list__item']} key="company">
              <IconFont
                className={styles['item__icon']}
                type="icon-company"/>
              <div className={styles['item__conent']}>
                <span className={styles['item__lable']}>{intl.get('business_card.company')}</span>
                <span className={clsx(styles['item__value'], styles['list__item_company'])} >{cardInfo.company}</span>
              </div>
            </div>
            <div className={styles['list__item']} key="position">
              <IconFont
                className={styles['item__icon']}
                type="icon-m_position"/>
              <div className={styles['item__conent']}>
                <span className={styles['item__lable']}>{intl.get('business_card.position')}</span>
                <span className={styles['item__value']}>{cardInfo.position}</span>
              </div>
            </div>
            <div className={styles['list__item']} key="tel">
              <IconFont
                  className={styles['item__icon']}
                  type="icon-m_tel"/>
              <div className={styles['item__conent']}>
                <span className={styles['item__lable']}>{intl.get('business_card.tel')}</span>
                <span className={styles['item__value']}>{canSee ? cardInfo.tel : '******'}</span>
              </div>
              {
                canSee &&
                <Button type="link" onClick={copyText.bind(this, cardInfo.tel)}>{intl.get('business_card.copy')}</Button>
              }
            </div>
            <div className={styles['list__item']} key="email">
              <IconFont
                className={styles['item__icon']}
                type="icon-m_email"/>
              <div className={styles['item__conent']}>
                <span className={styles['item__lable']}>{intl.get('business_card.email')}</span>
                <span className={styles['item__value']}>{canSee ? cardInfo.email : '******'}</span>
              </div>
              {
                canSee &&
                <Button type="link" onClick={copyText.bind(this, cardInfo.email)}>{intl.get('business_card.copy')}</Button>
              }
            </div>
          </div>
          {
            cardInfo.cta_id !== userInfo?.cta_id && (userInfo?.type !== 6 && userInfo?.type !== 7) &&
            <Space className={styles['card-actions']}>
              <Button
                type="primary"
                disabled={cardInfo.exchange_status === 2 || cardInfo.exchange_status === 4}
                onClick={() => {
                  switch(cardInfo.exchange_status){
                    case 1:
                      exchangeCard(cardInfo.cta_id);
                      break;
                    case 3:
                      agreeCard(cardInfo.cta_id, 1);
                      break;
                  }
                }}>
                {intl.get(`business_card.card_state.${cardInfo.exchange_status}`)}
              </Button>
              {
                cardInfo.exchange_status === 3 &&
                <Button
                  type="primary"
                  ghost
                  danger
                  onClick={() => agreeCard(cardInfo.cta_id, 2)}>
                  {intl.get(`business_card.ignore_apply`)}
                </Button>
              }
            </Space>
          }
        </Space>
      }
    </>
  )
})

const stateToProps = (state: {
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
}) => {
  const { userInfo, roomInfo } = state
  return {
    userInfo,
    roomInfo,
  }
}
export default connect(stateToProps, null, null, {forwardRef: true})(BusinessCard)
