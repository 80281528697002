import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle} from 'react'
import { useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
import ppo from 'ppo'
import type { AnonymousModalRefProps } from './index.d'
import { setUserInfo } from '@/store/actions'
import { Button, Modal, Space, Input, Select, message, Avatar, Image } from 'antd'
import styles from './index.module.scss'

const { Option } = Select

export type AnonymousModalProps = {
  enterRoom?: () => void,
}

let messageTip = false
const AnonymousModal = forwardRef<AnonymousModalRefProps, AnonymousModalProps>((props, childRef) => {
  const dispatch = useDispatch()
  const { enterRoom } = props
  // 用户可选头像
  const [isTranslator] = useState(ppo.getUrlParam('source') || false)
  // 用户可选头像
  const avatars = isTranslator ? [
    {
      lable: intl.get('anonymous_modal.avatars.3'),
      value: 'https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/avatar/avatar_translater.png?v=1685693993831',
    }
  ]:
  [
    {
      lable: intl.get('anonymous_modal.avatars.1'),
      value: 'https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/avatar/avatar_man.png?v=1685693993831',
    },
    {
      lable: intl.get('anonymous_modal.avatars.2'),
      value: 'https://mina-1252139118.coscd.myqcloud.com/kankan-mina/static/avatar/avatar_woman.png?v=1685693993831',
    }
  ]
  const [open, setOpen] = useState(false)
  const [avatar, setAvatar] = useState(avatars[0].value)
  const nameInputEl = useRef<any>(null)
  const companyInputEl = useRef<any>(null)

  useImperativeHandle(childRef, () => ({
    // 接受新消息
    open: () => {
      setOpen(true)
    }
  }))

  // 确定设置用户信息
  const handleOk = () => {
    const userNmae = nameInputEl.current.input.value
    const companyNmae = companyInputEl.current.input.value
    if(!userNmae && !messageTip){
      messageTip = true
      message.warning(intl.get('anonymous_modal.name_err')).then(() => {
        messageTip = false
      })
      return
    }
    if(!companyNmae  && !messageTip){
      messageTip = true
      message.warning(intl.get('anonymous_modal.company_err')).then(() => {
        messageTip = false
      })
      return
    }
    if(messageTip){
      return false
    }
    // 邀请人用户类型
    const inviteUserType = parseInt(ppo.getUrlParam('inviteUserType'))
    dispatch(setUserInfo({
      id: ppo.uuid(),  //发送者id
      type: isTranslator ? 6 : (inviteUserType ? (inviteUserType === 4 ? 8 : 9) : 7),
      name: userNmae, //用户名
      company: companyNmae, // 公司名
      avatar: avatar, // 用户头像
    }))
    setOpen(false)
    enterRoom && enterRoom()
  }

  return(
    <Modal
      className={styles['anonymous-modal']}
      title={intl.get('anonymous_modal.title')}
      visible={open}
      centered
      closable={false}
      maskClosable={false}
      onOk={handleOk}
      onCancel={()=> setOpen(false)}
      getContainer={false}
      width={400}
      footer={
        <div className={styles['modal-footer']}>
          <Button type="primary" shape="round" onClick={handleOk}>{intl.get('anonymous_modal.jion_talk')}</Button>
        </div>
      }>
      <div className={styles['invite-confirm']}>
        <Space className={styles['from-item']} direction="vertical">
          <div className={styles['item-avatar']} >
            <Avatar size={64} src={<Image src={avatar} preview={false} style={{ width: 64 }} />}/>
          </div>
          <Select
            className={styles['item-input']} 
            defaultValue={avatar}
            disabled={isTranslator}
            onChange={value => setAvatar(value)}>
            {
              avatars.map((item, index) => <Option key={index} value={item.value}>{item.lable}</Option>)
            }
          </Select>
          <Input
            ref={nameInputEl}
            className={styles['item-input']}
            placeholder={intl.get('anonymous_modal.name_tip')}
            onPressEnter={handleOk}/>
          <Input
            ref={companyInputEl}
            className={styles['item-input']}
            placeholder={intl.get('anonymous_modal.company_tip')}
            onPressEnter={handleOk}/>
        </Space>
      </div>
    </Modal>
  )
})

export default AnonymousModal