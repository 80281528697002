import React, { } from 'react'
import intl from 'react-intl-universal'
import clsx from 'clsx'
import styles from './index.module.scss'

export type UserTypePropsType = {
  type: number,
  size?: string,
}

const UserType: React.FC<UserTypePropsType> = (props) => {
  const { type, size } = props
  return <span className={clsx(styles['user-type'], styles[`size__${size}`])} data-type={type}>{intl.get(`user_type.${type}`)}</span>
}

export default UserType