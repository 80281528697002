import React, { useState } from 'react'
import ppo from 'ppo'
import intl from 'react-intl-universal'
import { MenuInfo } from 'node_modules/rc-menu/lib/interface.d'
import { LOCALE_CONFIG_MAP } from '@/config/index'
import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

//语言key类型
export type LocaleKeysType = keyof typeof LOCALE_CONFIG_MAP

const SelectLang:React.FC = () => {
  const intlOptions = intl.getInitOptions()
  const [selectedLang, setSelectedLang] = useState(intlOptions.currentLocale || '')

  const menuItemStyle = { minWidth: "160px" }
  const menuItemIconStyle = { marginRight: "8px" }

  const languageChange = (params:MenuInfo) => {
    const language = params.key
    ppo.setCookie('kankan-lang', language)
    setSelectedLang(language)
    window.location.reload()
  }

  const langMenu = (
    <Menu selectedKeys={[selectedLang]} onClick={languageChange}>
      {
        Object.keys(LOCALE_CONFIG_MAP).map((key) => {
          const localeObj: {
            lang?: string,
            label?: string,
            icon?: string,
            title?: string
          } = LOCALE_CONFIG_MAP[key as LocaleKeysType]
        return (
          <Menu.Item key={localeObj.lang} style={menuItemStyle}>
            {/* <span role="img" aria-label={localeObj?.label} style={menuItemIconStyle}>
              {localeObj?.icon}
            </span> */}
            {localeObj?.label}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return(
    <Dropdown trigger={["click"]} overlay={langMenu}>
      <div className={styles['select-lang']}>
        {/* <span className={styles['select-item']}>{LOCALE_CONFIG_MAP[selectedLang as LocaleKeysType]?.icon}</span> */}
        <span className={styles['select-item']}>{LOCALE_CONFIG_MAP[selectedLang as LocaleKeysType]?.label}</span>
        <DownOutlined className={styles['select-icon']} />
      </div>
    </Dropdown>
  )
}

export default SelectLang