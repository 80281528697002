import React from 'react'
import intl from 'react-intl-universal'
import IconFont from '@/components/IconFont'
import styles from './index.module.scss'

const Index = () => {
  return (
    <div className={styles.home}>
			<div className={styles.header}>
				<div className={styles.title}>{intl.get("index.title")}</div>
				<div className={styles.subtitle}>{intl.get("index.subtitle")}</div>
			</div>
			<div className={styles.features}>
				<div className={styles.item}>
					<IconFont className={styles.icon} type="icon-user-add" size="large" />
					<div className={styles.name}>{intl.getHTML("index.features.1")}</div>
				</div>
				<div className={styles.item}>
					<IconFont className={styles.icon} type="icon-chat" size="large" />
					<div className={styles.name}>{intl.getHTML("index.features.2")}</div>
				</div>
				<div className={styles.item}>
					<IconFont className={styles.icon} type="icon-tanlate" size="large" />
					<div className={styles.name}>{intl.getHTML("index.features.3")}</div>
				</div>
				<div className={styles.item}>
					<IconFont className={styles.icon} type="icon-ppt" size="large" />
					<div className={styles.name}>{intl.getHTML("index.features.4")}</div>
				</div>
			</div>
	  </div>
  )
}
export default Index
