/**
 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
 *
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */
const SDKAPPID = 1400323124

/**
 * 签名过期时间，建议不要设置的过短
 * <p>
 * 时间单位：秒
 * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
 */
const EXPIRETIME = 604800

/**
 * 计算签名用的加密密钥，获取步骤如下：
 *
 * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
 * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
 * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
 *
 * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
 * 文档：https://cloud.tencent.com/document/product/647/17275#Server
 */
const SECRETKEY = '538a23686e40b1bb5ef2e5f3de6b8f2575f5f496020da74da9285d583be7cbe3'

export { SDKAPPID, EXPIRETIME, SECRETKEY }

// 包含语言语言配置映射
export const LOCALE_CONFIG_MAP = {
  'en-US': {
    lang: 'en-US',
    label: 'English',
  },
  'zh-CN': {
    lang: 'zh-CN',
    label: '简体中文',
  },
  'zh-hant': {
    lang: 'zh-hant',
    label: '繁體中文',
  },
  // 'ja-JP': {
  //   lang: 'ja-JP',
  //   label: '日本',
  // },
}
// console.log('process.env', process.env)
export const DEFAULT_LOCALE = LOCALE_CONFIG_MAP['zh-CN'].lang // 默认语言
export const DOMAIN_ENV = process.env.REACT_APP_NODE_ENV || "production" //打包环境变量
console.log('DOMAIN_ENV', DOMAIN_ENV)
export const IS_PROD = DOMAIN_ENV === "production" // 是否是生产
export const API_DOAMIN = DOMAIN_ENV === "local" ?  "/" : process.env.REACT_APP_PROXY || '' // 接口地址
export const ICON_FONT_URL = '//at.alicdn.com/t/c/font_2867147_lkggcst5jq.js' //iconfont 图标库
export const WS_URL = process.env.REACT_APP_WS_URL || '' // socket 地址
export const MAX_ACCEPT_STREAM= parseInt(process.env.REACT_APP_MAX_ACCEPT_STREAM || '') // 视频洽谈最多接受流个数
export const TALK_TYPE_WALKIN = 'walkin' //walkin洽谈
export const TALK_TYPE_PSA = 'psa' //psa洽谈
export const CLIENT_DESKTOP_APP = 'CLIENT_DESKTOP_APP' //来自桌面端app标识
export const COUNTDOWN_SECOND = 5 // 提示倒计时时长