import React, { useState, forwardRef, useImperativeHandle } from 'react'
import intl from 'react-intl-universal'
import BusinessCard from '@/components/BusinessCard/BusinessCard'
import { Drawer } from 'antd'
import type { businessCardDrawerRefProps } from './index.d'

type businessCardDrawerTypes = {
}

const BusinessCardDrawer = forwardRef<businessCardDrawerRefProps, businessCardDrawerTypes>((props, childRef) => {
  const [cardCtaId, setCardCtaId] = useState<false | number>(false)

  useImperativeHandle(childRef, () => ({
    // 打开名片详情
    open: (ctaId: number) => {
      setCardCtaId(ctaId)
    },
  }))

  return(
    <Drawer
      title={intl.get('business_card.title')}
      placement="right"
      onClose={() => {
        setCardCtaId(false)
      }}
      visible={Boolean(cardCtaId)}
      width={500}>
      {
        cardCtaId && <BusinessCard ctaId = {cardCtaId}/>
      }
    </Drawer>
  )
})

export default BusinessCardDrawer
