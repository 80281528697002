import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export type LoadingProps = {
  style?: object
  center?: boolean
  size?: number | 'small' | 'large',
  children?: React.ReactNode
};

const Loading: React.FC<LoadingProps> = (props) => {
  const { center, style, size, children } = props;
  let fontSize = 20
  if (typeof size === 'number') {
    fontSize = size
  } else {
    switch (size) {
      case 'small':
        fontSize = 14
        break;
      case 'large':
        fontSize = 32
        break
    }
  }
  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;
  return (
    <Spin
      style={
        center
          ? { display: 'block', marginLeft: 'auto', marginRight: 'auto' }
          : style
      }
      indicator={antIcon}>
      {children}
    </Spin>
  );
};

export default Loading;
