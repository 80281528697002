import React, { useEffect, useState, useRef } from 'react'
import ppo from 'ppo';
import { useHistory } from 'react-router-dom'
import {Input, Button, message, Space} from 'antd'
import {CLIENT_DESKTOP_APP} from '@/config/index'
import styles from './entrance.module.scss'
import intl from 'react-intl-universal'

const DesktopEntrance = () => {
  const history = useHistory()
  const [marketId, setMarketId] = useState('')
  const [exhibitorId, setExhibitorId] = useState('')
  const inputRef = useRef(null)

  //进入验证页面
  const gotoVerify = () => {
    console.log('exhibitorId', exhibitorId)
    console.log('marketId', marketId)
    if(exhibitorId && exhibitorId){
      history.push(`/verify?marketId=${marketId}&exhibitorId=${exhibitorId}`)
    }
  }

  useEffect(() => {
    const demo = ppo.getUrlParam('demo')
    if(demo){
      localStorage.setItem('demo', demo)
    }
    localStorage.setItem('clientDesktopApp', CLIENT_DESKTOP_APP);
    (inputRef as any).current!.focus()
  }, [])

  return(
    <div className={styles['page-entrance']}>
      <Space
        className={styles['main']}
        direction="vertical"
        size="large">
        <div className={styles['logo']}><img src="/logo.png" alt="" /></div>
        <h3>{intl.get('desktop.entrance.title')}</h3>
        <Input
          ref={inputRef}
          size="large"
          placeholder={intl.get('desktop.entrance.market_id')}
          onChange={(e: any) => setMarketId(e.target.value)}
          onPressEnter={gotoVerify}
        />
        <Input
          size="large"
          placeholder={intl.get('desktop.entrance.merchant_id')}
          onChange={(e: any) => setExhibitorId(e.target.value)}
          onPressEnter={gotoVerify}
        />
        <Button
          size="large"
          type="primary"
          block
          onClick={() => gotoVerify()}
        >
          {intl.get('desktop.entrance.next')}
        </Button>
      </Space>
    </div>
  )
}

export default DesktopEntrance