import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import intl from 'react-intl-universal'
import copy from 'copy-to-clipboard'
import ppo from 'ppo'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import EventCenter from '@/utils/IMSService/EventCenter'
import { setPPTSate } from '@/store/actions'
import type {videoTalkTypes, userInfoTypes, roomInfoTypes, globalTypes, talkMarkOptionsTypes } from '@/store/store.d'
import IconFont from '@/components/IconFont'
import IconButton from '@/components/IconButton'
import SelectLang from '@/components/SelectLang/index'
import SetUpModal from '@/components/VideoTalk/SetUpModal'
import RoomMembers from '@/components/Header/RoomMembers/index'
import NegotiateToday from '@/components/Header/NegotiateToday/index'
import type { SetUpModalRefProps } from '@/components/VideoTalk/SetUpModal/index.d'
import { TALK_TYPE_WALKIN, TALK_TYPE_PSA, CLIENT_DESKTOP_APP, COUNTDOWN_SECOND } from '@/config/index'
import Request from '@/utils/request'
import { sendMessage } from '@/utils/socketMessage'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import type { messageTypes, sendInfoTypes } from '@/utils/IMSService/index.d'
import { Modal, Button, Space, Image, Select, message, Dropdown, Menu, Avatar} from 'antd'
import {
  PoweroffOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from '@ant-design/icons'
import 'animate.css';
import styles from './index.module.scss'

const { info, confirm } = Modal
const { Option } = Select

// header参数类型
export type HeaderPropsTypes = {
  headerType: string | null,
  global?: globalTypes,
  videoTalk?: videoTalkTypes,
  userInfo?: userInfoTypes,
  roomInfo?: roomInfoTypes,
  sendMsg: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
}
export type headerRefProps = {
  chatFinish: (isForcedEnd:boolean, title?: string, onOk?:() => void, onCancel?:() => void) => void
}

export type walkinSettingType = {
  walk_in_mark_options?: talkMarkOptionsTypes[],
  walk_in_mark_status?: boolean,
  walk_in_server_num?: number,
}

let showEndModal = false
let ratingStore = ''
let countdownTimer:any = null
let tipModal:any = null
const Header = forwardRef<headerRefProps, HeaderPropsTypes>((props, childRef) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { children, headerType, global, videoTalk, userInfo, roomInfo, sendMsg } = props
  const buyerLate = !roomInfo?.users?.find( item=> item.type === 1) || false

  const talkSetting = roomInfo?.talk_setting
  const defalutRating= talkSetting && talkSetting?.talk_mark_options?.length ? talkSetting?.talk_mark_options[0].score : ''
  const [countTime, setCountTime] = useState(0) // 洽谈剩余时间
  const [duration, setDuration] = useState(0) // 洽谈时长值单位分钟
  const [chatDuration, setChatDuration] = useState('') // 洽谈时长
  const [showCountdown, setShowCountdown] = useState(false) // 显示倒计时
  const [rating, setRating] = useState(defalutRating) // 设置评分
  const [walkinSetting, setWalkinSetting] = useState<walkinSettingType | null>() // walkin的设置
  const [inviteQrcode, setInviteQrcode] = useState('') // 邀请二维码
  const [canEndCall]= useState(userInfo?.type === 4 && (headerType === TALK_TYPE_WALKIN || userInfo?.id === roomInfo?.creatorId)) // 可以结束洽谈
  const setUpModalRef = useRef<SetUpModalRefProps>(null)
  const clientDesktopApp = localStorage.getItem('clientDesktopApp')
  const isDesktopApp = Boolean(clientDesktopApp && clientDesktopApp === CLIENT_DESKTOP_APP) // 是否来自桌面端app
  
  let timer:any = null

  useImperativeHandle(childRef, () => ({
    // 结束洽谈
    chatFinish: (isForcedEnd:boolean, title?: string, onOk?:() => void, onCancel?:() => void) => {
      chatFinish(isForcedEnd, title, onOk, onCancel)
    },
  }))

  // 复制文案
  const copyText = (text:string) => {
    copy(text)
    message.success(intl.get("invite_jion.copy_success"))
  }

  // 获取邀请二维码
  const getInviteQrcode = async () =>  {
    const {status, data} = await Request({
      method: 'POST',
      url: `mina/qrcode`,
      data: {
        path: `${headerType === TALK_TYPE_WALKIN ? 'pages/walkin/walkin' : 'pages/talk/talk'}?roomId=${roomInfo?.roomId}&inviteUserType=${userInfo?.type}`,
        width: 400,
      }
    })
    try {
      if(status && data.url){
        console.log('data.url', data.url)
        setInviteQrcode(data.url)
      }
    } catch (error) {
      console.log('getInviteQrcode-error', error)
    }
  }

  // 邀请其他人加入房间
  const inviteJoin = () => {
    let url = ppo.delUrlParam('token')
    url = ppo.delUrlParam('redirect', url)
    url = ppo.delUrlParam('lang', url)
    const hasOtherParam = url.includes('?')
    console.log('url', url, 'hasOtherParam', hasOtherParam)
    const inviteUrl = `${url}${hasOtherParam ? '' : '?'}${headerType === TALK_TYPE_WALKIN ? `&roomId=${roomInfo?.roomId}` : ``}&lang=${global?.locale}&inviteUserType=${userInfo?.type}`
    info({
      width: 600,
      icon: null,
      centered: true,
      title: intl.get('invite_jion.title'),
      closable: true,
      content: <div className={styles['invite-confirm']}>
        <Space direction="vertical" style={{width:'100%'}}>
          <Space direction="vertical" style={{width:'100%'}}>
            <h4>{intl.get('invite_jion.way1_title')}</h4>
            <div className={styles['invite-url']}>{inviteUrl}</div>
            <Space className={styles['invite-tip']} align="start" style={{width:'100%'}}>
              {intl.get('invite_jion.way1_tip')}
              <Button type="primary" onClick={() => copyText(inviteUrl)}>{intl.get('invite_jion.copy')}</Button>
            </Space>
          </Space>
          <Space direction="vertical" style={{width:'100%'}}>
            <h4>{intl.get('invite_jion.way2_title')}</h4>
            <Space className={styles['invite-tip']} align="start" size="large" style={{width:'100%'}}>
              <Image preview={false} width={160} src={inviteQrcode}/>
              <Space direction="vertical">
                {intl.get('invite_jion.way2_tip')}
                <Button type="primary" target="_blank" download href={inviteQrcode} >{intl.get('invite_jion.save_image')}</Button>
              </Space>
            </Space>
          </Space>
        </Space>
      </div>,
    })
  }

  // 评分变化
  const ratingChange = (value: string) => {
    setRating(value)
  }

  // 结束洽谈
  const chatFinish = (isForcedEnd:boolean = false, title?: string, onOk?:() => void, onCancel?:() => void) => {
    // console.log('chatFinish====', buyerLate && talkSetting?.forward_end_status, talkSetting?.forward_end_time, 'duration', duration)
    const modalConfig = {
      width: 500,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      title: title || intl.get(`end_talk.${canEndCall ? (headerType === TALK_TYPE_WALKIN || dayjs().isAfter(dayjs(roomInfo?.endAt)) ? 'title' : 'live_tip') : 'live_tip'}`),
      maskClosable: !isForcedEnd,
      content: <div className={styles['invite-confirm']}>
        {
          headerType === TALK_TYPE_WALKIN ?
          <>
            {
              canEndCall && walkinSetting &&
              walkinSetting?.walk_in_mark_status &&
              walkinSetting?.walk_in_mark_options &&
              walkinSetting?.walk_in_mark_options?.length > 0 &&
              <Space direction="vertical" style={{ width: '100%' }}>
                {intl.get('end_talk.rating_tip')}
                <Select defaultValue={rating} style={{ width: '100%' }} onChange={ratingChange}>
                  {
                    walkinSetting?.walk_in_mark_options.map((item: talkMarkOptionsTypes, index: number) =>{
                      return <Option key={index} value={item.score}>{item.title}</Option>
                    })
                  }
                </Select>
              </Space>
            }
          </>:
          <>
           {
              canEndCall &&
              talkSetting &&
              talkSetting?.talk_mark_status &&
              talkSetting?.talk_mark_options &&
              talkSetting?.talk_mark_options?.length > 0 &&
              <Space direction="vertical" style={{ width: '100%' }}>
                {intl.get('end_talk.rating_tip')}
                <Select defaultValue={rating} style={{ width: '100%' }} onChange={ratingChange}>
                  {
                    talkSetting?.talk_mark_options.map((item: talkMarkOptionsTypes, index: number) =>{
                      return <Option key={index} value={item.score}>{item.title}</Option>
                    })
                  }
                </Select>
              </Space>
           }
          </>
        }
      </div>,
      onOk: async () => {
        clearTimer()
        await handleFinishTalk()
        onOk && onOk()
      },
      onCancel() {
        console.log('onCancel')
        showEndModal = false
        onCancel && onCancel()
      }
    }
    if(!showEndModal){
      isForcedEnd ? info(modalConfig) : confirm(modalConfig)
      showEndModal = true
    }
  }
  
  // 结束洽谈
  const handleFinishTalk = async () => {
    if(roomInfo?.roomId && userInfo){
      await sendMessage({
        header:{
          roomId: roomInfo?.roomId,  //房间ID
          clientId: global?.clientId,  //设备id
        },
        body:{
          sender: userInfo,
          message: {
            msgType: canEndCall ?
              (
                headerType === TALK_TYPE_WALKIN || dayjs().isAfter(dayjs(roomInfo?.endAt)) ?
                msgTypes.FINISH_ROOM :
                msgTypes.EX_EXIT_ROOM
              ):
              msgTypes.EXIT_ROOM,
            content: {
              rating: ratingStore,
            }
          }
        }
      }, 'end')
      showEndModal = false
    }
  }

  // 更新洽谈时间
  const updateChatTime = () => {
    const now = dayjs().valueOf()
    const startAt = roomInfo?.startAt ? dayjs(roomInfo?.startAt) : now
    const endAt = roomInfo?.endAt ? dayjs(roomInfo?.endAt) : now + (talkSetting?.step || 0)
    const diffMinute = dayjs(now).diff(startAt, 'minute')
    const diffSecond = dayjs(now).diff(startAt, 'second') - diffMinute*60
    setDuration(diffMinute)
    setChatDuration(`${diffMinute> 0 && diffMinute < 10 ? 0 : ''}${diffMinute> 0 ? diffMinute : '00'}:${diffSecond > 0 && diffSecond < 10 ? 0 : ''}${diffSecond > 0 ? diffSecond : '00'}`)
    // console.log('endAt', dayjs(endAt).format('YYYY-MM-DDTHH:mm:ss'))
    if(talkSetting?.countdown_status){
      // 结束时间前1分钟出现倒计时
      const countdownTimeStartAt = dayjs(endAt).subtract(1, 'minute')
      if(dayjs().isAfter(dayjs(countdownTimeStartAt))){
        const countSecond = 60 - dayjs(now).diff(countdownTimeStartAt, 'second')
        setCountTime(countSecond)
      }
    }
    // 开启强制结束
    if(userInfo?.type === 4 && headerType === TALK_TYPE_PSA && talkSetting?.talk_end_force_stop_status && dayjs().isAfter(dayjs(endAt))){
      chatFinish(true)
    }
    if(!tipModal && isDesktopApp && userInfo?.type !== 4 && headerType === TALK_TYPE_PSA && dayjs().isAfter(dayjs(endAt))){
      let secondsToGo = COUNTDOWN_SECOND;
      tipModal = info({
        width: 500,
        icon: <ExclamationCircleOutlined />,
        centered: true,
        title: intl.get('end_talk.tip'),
        content:intl.get('desktop.countdown_tip'),
        okText: `${intl.get('desktop.back_now')}(${secondsToGo}s)`,
        onOk(){
          clearCountdownTimer()
          history.push('/verify')
        },
      })
      countdownTimer = setInterval(() => {
        secondsToGo -= 1;
        if(secondsToGo <= 0){
          clearCountdownTimer()
          tipModal.destroy()
          tipModal = null
          history.push('/verify')
        } else {
          tipModal.update({
            okText:`${intl.get('desktop.back_now')}(${secondsToGo}s)`
          });
        }
      }, 1000);
    }
  }

  const clearCountdownTimer = () => {
    if(countdownTimer){
      window.clearInterval(countdownTimer)
      countdownTimer = null
    }
  }

  // ppt演示状态更新
  const hadlePPtState = () => {
    if(!videoTalk?.pptSate?.open){
      dispatch(setPPTSate({
        open: true
      }))
    } else {
      EventCenter.emit('endPPT')
    }
  }

  const handleLocalStream = (name: string) => {
    EventCenter.emit('handleLocalStream', name)
  }

  // 清除定时器
  const clearTimer = () => {
    if(timer){
      clearInterval(timer)
      timer = null
    }
  }

  // 打开视频洽谈
  const openVideoTalk = () => {
    setUpModalRef?.current?.open()
  }

  // 获取walkin设置
  const getWalkinSetting = async () => {
    const {status, data} = await Request({
      url: `walkin_setting`,
    })
    try {
      console.log('data', data)
      if(status && data){
        setWalkinSetting(data)
        if(data.walk_in_mark_status && data.walk_in_mark_options.length){
          setRating(data.walk_in_mark_options[0].score)
        }
      }
    } catch (error) {
      console.log('getWalkinSetting', error)
    }
  }

  useEffect(() => {
    ratingStore = rating
  }, [rating])
  
  useEffect(() => {
    if(countTime > 0 && !showCountdown){
      // console.log('开始倒计时')
      setShowCountdown(true)
    }
    if(countTime <= 0 && showCountdown){
      console.log('结束倒计时')
      setShowCountdown(false)
    }
  }, [countTime])

  useEffect(() => {
    if(!roomInfo?.isEnd){
      getInviteQrcode()
      updateChatTime()
      timer = setInterval(() => {
        updateChatTime()
      }, 1000)
    }
    if(headerType === TALK_TYPE_WALKIN){
      getWalkinSetting()
    }
    EventCenter.addListener('openVideoTalk', openVideoTalk)
    return () => {
      clearTimer()
      clearCountdownTimer()
      EventCenter.removeListener('openVideoTalk', openVideoTalk)
    }
  }, [])

  return(
    <div className={clsx(styles.header, styles[`type__${headerType}`])}>
      <div className={styles.left}>
        <Space size="middle">
          {
            (headerType === TALK_TYPE_PSA || userInfo?.type !== 4) &&
            <Button style={{cursor: 'default'}}>
              {intl.get(`room_types.${headerType === TALK_TYPE_PSA ? "scheduled" : "walk_in"}`)}
            </Button>
          }
          <SelectLang />
          {
            headerType === TALK_TYPE_PSA && !roomInfo?.isEnd &&
            <>
              <Space>
                <IconFont type="icon-time"/>
                {chatDuration}
              </Space>
              {
                showCountdown &&
                <div className={clsx('animate__animated animate__pulse animate__infinite animate__slow', styles['countdown-box'])}>
                  <IconFont
                    className={clsx('animate__animated animate__rotate animate__infinite animate__slow', styles['countdown-icon'])}
                    type="icon-shalou"
                  />
                  <div className={styles['countdown__text']}>
                    <span className={styles['countdown-num']}>{countTime}</span>
                    <span className={styles['countdown-cell']}>s</span>
                  </div>
                </div>
              }
              <RoomMembers />
            </>
          }
        </Space>
      </div>
      <div className={styles.center}>
        {
          headerType === TALK_TYPE_WALKIN?
          <Space align="center">
            <Space>
              <IconFont type="icon-time"/>
              {chatDuration}
            </Space>
            <RoomMembers />
          </Space>:
          null
        }
      </div>
      <div className={styles.right}>
        <Space size="middle">
          <Space>
            {
              userInfo?.type === 4 &&
              <NegotiateToday />
            }
            {
              userInfo?.type === 4 && headerType === TALK_TYPE_WALKIN && !videoTalk?.settingOpen && !videoTalk?.vidoeTalkOpen &&
              <IconButton
                icon={<IconFont type="icon-videocam"/>}
                name={intl.get("header.video_talk")}
                onClick={openVideoTalk}/>
            }
            {
              userInfo?.type === 4 && videoTalk?.initState && headerType !== TALK_TYPE_WALKIN &&
              <IconButton
                icon={<IconFont type="icon-ppt"/>}
                name={!videoTalk?.pptSate?.open ? intl.get('header.share_ppt') : intl.get('header.close_ppt')}
                onClick={hadlePPtState}/>
            }
            {
              (userInfo?.type === 4 || userInfo?.type === 1) && !roomInfo?.isEnd &&
              <IconButton
                icon={<IconFont type="icon-user-add"/>}
                name={intl.get('header.invite_join')}
                onClick={inviteJoin}/>
            }
            {/* <IconButton
              icon={<IconFont type="icon-help"/>}
              name={intl.get('header.help_guide')}
              onClick={() => {}}/> */}
          </Space>
          {
            !roomInfo?.isEnd &&
            <div className={styles.line}></div>
          }
          {
            videoTalk?.initState && headerType !== TALK_TYPE_WALKIN?
            <Space>
              <IconButton
                icon={<IconFont type={videoTalk?.video ? "icon-videocam" : "icon-videocam-off"} size="large"/>}
                onClick={() => handleLocalStream('video')}/>
              <IconButton
                icon={<IconFont type={videoTalk?.audio ? "icon-mic" : "icon-mic-off"} size="large"/>}
                onClick={() => handleLocalStream('audio')}/>
            </Space>:
            null
          }
          {
            !roomInfo?.isEnd &&
            <Button
              className={styles['finish-btn']}
              type="primary"
              shape="round"
              icon={<PoweroffOutlined />}
              danger
              onClick={() => chatFinish()}>
              <span className={styles['finish-text']}>
                {
                  intl.get(`header.${
                    canEndCall ?
                    (
                      headerType === TALK_TYPE_WALKIN || dayjs().isAfter(dayjs(roomInfo?.endAt)) ?
                      'end_call':
                      'live_room'
                    ):
                    'live_room'
                  }`)
                }
              </span>
            </Button>
          }
          {
            roomInfo?.isEnd && isDesktopApp &&
            <Button
              className={styles['finish-btn']}
              type="primary"
              danger
              icon={<PoweroffOutlined />}
              onClick={() => history.push('/verify')}
            >
              <span className={styles['finish-text']}>{intl.get('desktop.quit')}</span>
            </Button>
          }
        </Space>
      </div>
      <SetUpModal userInfo={userInfo} roomInfo={roomInfo} videoTalk={videoTalk} ref={setUpModalRef} sendMsg={sendMsg} />
    </div>
  )
})

const stateToProps = (state: {
  global: globalTypes,
  videoTalk: videoTalkTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
}) => {
  const { global, videoTalk, userInfo, roomInfo } = state
  return {
    global,
    videoTalk,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null, null, {forwardRef: true})(Header)