import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import { Button, Result } from 'antd'
import { CLIENT_DESKTOP_APP } from '@/config/index'

export type ErrorPageProps = {
  location: {
    query: {
      status?: 'success' | 'error' | 'info' | 'warning' | 404 | 403 | 500,
      title?: string,
      subTitle?: string,
    }
  }
}


const ErrorPage: React.FC<ErrorPageProps> = (props:any) => {
  const state = props.location.state
  const redirectUrl = sessionStorage.getItem('REDIRECT_URL')
  const clientDesktopApp = localStorage.getItem('clientDesktopApp')
  const isDesktopApp = Boolean(clientDesktopApp && clientDesktopApp === CLIENT_DESKTOP_APP) // 是否来自桌面端app

  return(
    <Result
      status={state?.status || 404}
      title={state?.title || intl.get(`error.${state?.status || 404}.title`)}
      subTitle={state?.subTitle || intl.get(`error.${state?.status || 404}.sub_title`)}
      extra={
        isDesktopApp ?
        <Link to={'/verify'}><Button type="primary">{intl.get('desktop.quit')}</Button></Link>:
        <>
          {
            redirectUrl ?
            <Button type="primary" href={decodeURIComponent(redirectUrl)}>{intl.get('error.back_home')}</Button>:
            <Link to={'/'}><Button type="primary">{intl.get('error.back_home')}</Button></Link>
          }
        </>
      }
    />
  );
}

export default ErrorPage;
