import React, { useState, useEffect, useRef } from 'react'
import StreamBar from './streamBar'
import clsx from 'clsx'
import { message, Avatar, Image } from 'antd'
import type { userInfoTypes } from '@/store/store.d'
import type {
  StreamTypes,
  StreamBarChangeTypes,
  ConfigTypes,
  defaultBarTypes,
} from '../index.d'
import styles from './stream.module.scss'

export type StreamPropsTypes = {
  type?: 'local' | 'main',
  stream: StreamTypes | null, // 流对象
  config: ConfigTypes, // 设置流当前的属性状态 video: 视频 audio: 音频
  setting?: defaultBarTypes, // 设置对应的 streamBarIcon 是否显示
  init: (props: any) => void, // 将 dom 元素回调
  onChange: (props: StreamBarChangeTypes) => void, // 用户操作 streamBar 的回调
  userInfo?: userInfoTypes, //用户信息
  hasPPT: boolean | string,
}

const Stream:React.FC<StreamPropsTypes> = (props) => {
  const { type, init, stream, onChange, userInfo, hasPPT } = props
  const [statusInit, setStatusInit] = useState(false)
  const [config, setConfig] = useState<any>(() => ({ ...props.config }))
  const refItem = useRef<HTMLDivElement | null>(null)

  const handleState = (handle: (prevValue:{}) => void, value:{}) => {
    handle((prevValue:{}) => ({ ...prevValue, ...value }))
  }

  useEffect(() => {
    if (init && !statusInit) {
      init(refItem.current)
      if(stream){
        const current = {
          userID: stream.getUserId(),
          type: stream.getType(),
        }
        handleState(setConfig, current)
      }
      setStatusInit(true)
    }
    handleState(setConfig, { ...props.config })
  }, [type, init, stream, props, statusInit])

  return (
    <div className={clsx(styles.item, hasPPT ? styles['item-mini'] : null)}>
      <div ref={refItem} className={styles['item-view']}>
      </div>
      {
        (config.type === 'main' && !config.subscribedVideo) || (!config.hasVideo || (config.hasVideo && config.mutedVideo))?
        <div className={styles['item-cover']}>
          <Avatar size={100} src={<Image src={userInfo?.avatar} style={{ width: '100%' }} />} />
        </div>:
        null
      }
      <StreamBar
        hasPPT={hasPPT}
        className={clsx(styles['item-control'], hasPPT ? styles['control-mini'] : null)}
        userInfo={userInfo}/>
    </div>
  )
}

export default Stream
