import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { ICON_FONT_URL } from '@/config/index'

const CustomIcon = createFromIconfontCN({
  scriptUrl: ICON_FONT_URL,
})

export type IconFontProps = {
  className?: string,
  type: string,
  size?: number | 'small' | 'large',
  style?: {},
  onClick?: (e:any) => void,
}

const IconFont: React.FC<IconFontProps> = (props) => {
  const { className, type, size, style, onClick } = props
  let fontSize = 20
  if (typeof size === 'number') {
    fontSize = size
  } else {
    switch (size) {
      case 'small':
        fontSize = 14
        break
      case 'large':
        fontSize = 32
        break
    }
  }
  return <CustomIcon className={className || ''} style={Object.assign({fontSize: fontSize, lineHeight: 0}, style)} type={type} onClick={onClick}/>
}

export default IconFont
