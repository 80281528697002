import { Route, Switch } from "react-router-dom";

const RouterWithSubRoutes = (route: any) => (
  <Route
    exact={route.exact || false}
    path={route.path}
    render={(props:any) => (
      <route.component {...props} routes={route.routes} />
    )}
  />
);

const SubRoutes = (props:any) => {
  const {routes} = props
  return(
    <Switch>
      {
        routes.map((route:any, i:number) => (
          <RouterWithSubRoutes key={i} {...route} />
        ))
      }
    </Switch>
  )
}

export default SubRoutes