import React, { useRef, useEffect, useState} from 'react'
import { connect, useDispatch } from 'react-redux'
import intl from 'react-intl-universal'
import IconButton from '@/components/IconButton'
import IconFont from '@/components/IconFont'
import EventCenter from '@/utils/IMSService/EventCenter'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import { setPPTSate, setVidoeTalkOpen, setRoomVidoeState } from '@/store/actions'
import type {videoTalkTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import type { messageTypes, sendInfoTypes } from '@/utils/IMSService/index.d'
import { Space, Button } from 'antd'
import {
  PoweroffOutlined,
} from '@ant-design/icons'
import styles from './index.module.scss'

// 参数类型
export type ControlBarPropsTypes = {
  videoTalk?: videoTalkTypes,
  userInfo?: userInfoTypes,
  roomInfo?: roomInfoTypes,
  membersNum?: number,
  sendMsg: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
}

const ControlBar: React.FC<ControlBarPropsTypes> = (props) => {
  const dispatch = useDispatch()
  const { videoTalk, userInfo, sendMsg} = props

  // ppt演示状态更新
  const hadlePPtState = () => {
    if(!videoTalk?.pptSate?.open){
      dispatch(setPPTSate({
        open: true
      }))
    } else {
      EventCenter.emit('endPPT')
    }
  }

  // 摄像头和音频的处理
  const handleLocalStream = (name: string) => {
    EventCenter.emit('handleLocalStream', name)
  }

  // 关闭视频洽谈
  const closeVideoTalk = async () => {
    await EventCenter.emit('finishVideoTalk')
    dispatch(setVidoeTalkOpen(false))
    if(userInfo?.type === 4){
      dispatch(setRoomVidoeState(false))
      sendMsg({
        msgType: msgTypes.VIDEO_TALK_END,
      })
    }
  }

  useEffect(() => {
    EventCenter.addListener('closeVideoTalk', closeVideoTalk)
    return () => {
      EventCenter.removeListener('closeVideoTalk', closeVideoTalk)
    }
  }, [])

  return(
    <div className={styles['control-bar']}>
      <Space className={styles['bar-main']} align="center">
        {
          userInfo?.type === 4 &&
          <IconButton
            icon={<IconFont className={styles['btn-icon']} type="icon-ppt" size={24}/>}
            name={intl.get(`header.${!videoTalk?.pptSate?.open ? 'share_ppt' : 'close_ppt'}`)}
            onClick={hadlePPtState}/>
        }
        <IconButton
          icon={<IconFont className={styles['btn-icon']} type={videoTalk?.video ? "icon-videocam" : "icon-videocam-off"} size={24}/>}
          name={intl.get(`video_talk.${videoTalk?.video ? 'close' : 'open'}`, {name: intl.get('video_talk.camera')})}
          onClick={() => handleLocalStream('video')}/>
        <IconButton
          icon={<IconFont className={styles['btn-icon']} type={videoTalk?.audio ? "icon-mic" : "icon-mic-off"} size={24}/>}
          name={intl.get(`video_talk.${videoTalk?.audio ? 'close' : 'open'}`, {name: intl.get('video_talk.audio')})}
          onClick={() => handleLocalStream('audio')}/>
      </Space>
      <Button
        className={styles['finish-btn']}
        type="primary"
        shape='round'
        icon={<PoweroffOutlined />}
        danger
        ghost
        onClick={closeVideoTalk}>
        <span className={styles['finish-text']}>{intl.get(`header.${userInfo?.type === 4 ? 'close_video_talk' : 'quit_video_talk'}`)}</span>
      </Button>
    </div>
  )
}

const stateToProps = (state: {
  videoTalk: videoTalkTypes,
  userInfo: userInfoTypes,
  roomInfo: roomInfoTypes,
}) => {
  const { videoTalk, userInfo, roomInfo } = state
  return {
    videoTalk,
    userInfo,
    roomInfo,
  }
}

export default connect(stateToProps, null)(ControlBar)