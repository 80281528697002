import React, { useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { connect, useDispatch } from 'react-redux'
import IconFont from '@/components/IconFont'
import Loading from '@/components/Loading'
import BusinessCardDrawer from '@/components/BusinessCard/BusinessCardDrawer'
import { updateRoomMembers } from '@/store/actions'
import type {businessCardDrawerRefProps} from '@/components/BusinessCard/index.d'
import type { userTypes, userInfoTypes, roomInfoTypes } from '@/store/store.d'
import { Space, Image, Dropdown, Menu, Avatar} from 'antd'
import { DownOutlined, } from '@ant-design/icons'
import styles from './index.module.scss'

// 房间成员组件
type RoomMembersPropsTypes = {
  userInfo?: userInfoTypes,
  roomId?: string,
}
const RoomMembers:React.FC<RoomMembersPropsTypes> = (props) => {
  const dispatch = useDispatch()
  const { userInfo, roomId } = props
  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState<userTypes[]>([])
  const businessCardDrawerRef = useRef<businessCardDrawerRefProps>(null)

  const openCard = (ctaId:number) => {
    if(userInfo?.type !== 7 && userInfo?.type !== 6){
      businessCardDrawerRef.current?.open(ctaId)
    }
  }

  // 显示下拉的时候更新在线房间用户列表
  const visibleChange = (visible: boolean) => {
    if(visible){
      setLoading(true)
      dispatch(updateRoomMembers(roomId || '', (data: userTypes[]) => {
        setLoading(false)
        setMembers(data)
      }))
    } else {
      setLoading(false)
    }
  }

  return(
    <>
      <Dropdown
        overlayStyle={{
          width: 300,
        }}
        onVisibleChange={visibleChange}
        overlay={
          loading?
          <div className={styles['loading-box']}><Loading /></div>:
          <Menu>
            {
              members && members.map((user: userTypes, index:number) => {
                const canViewDetail = user.ctaId > 0 && [0, 1, 2, 3, 4].includes(user.type) && userInfo?.type && [0, 1, 2, 3, 4].includes(userInfo.type)
                return(
                  <Menu.Item
                    key={`${user.ctaId}_${index}`}
                    onClick={() => canViewDetail && openCard(user.ctaId)}
                    style={{cursor: canViewDetail ? 'pointer' : 'default'}}
                  >
                    <div className={styles['user-item']}>
                      <Space className={styles['item__basic']}>
                        <Avatar size={30} src={
                          <Image preview={false} src={user?.avatar} style={{ width: '100%' }} />
                        } />
                        {`${user?.name}(${intl.get(`user_type.${user.type}`)})`}
                      </Space>
                      {
                        canViewDetail &&
                        <IconFont className={styles['item__icon']} type="icon-card"/>
                      }
                    </div>
                  </Menu.Item>
                )
              })
            }
          </Menu>
        }>
        <Space>
          <IconFont type="icon-user"/>
          {intl.get('header.member_list')}
          <DownOutlined />
        </Space>
      </Dropdown>
      <BusinessCardDrawer ref={businessCardDrawerRef}/>
    </>
  )
}

const stateToProps = (state: {
  roomInfo: roomInfoTypes,
  userInfo: userInfoTypes,
}) => {
  const { userInfo, roomInfo } = state
  return {
    userInfo,
    roomId: roomInfo.roomId,
  }
}

export default connect(stateToProps, null)(RoomMembers)
