// 系统消息
export const SYS_TEXT = "text" // 系统文字消息
export const WALKIN_JOIN = "WALKIN_JOIN" // 加入walkin消息
export const BUYER_LATE = "BUYER_LATE" // 买家迟到消息
export const BUYER_LATE_ENTER = "BUYER_LATE_ENTER" // 买家迟到进入房间消息
export const NEW_NEXT_TALK = "NEW_NEXT_TALK" // 下场洽谈马上开始
export const BUSINESS_CARD_APPLY = "BUSINESS_CARD_APPLY" // 收到名片申请
export const BUSINESS_CARD_AGREE = "BUSINESS_CARD_AGREE" // 收到同意名片申请

// 加入房间动作不会广播
export const ACTION_JOIN_ROOM = "ACTION_JOIN_ROOM" 

// 房间消息
export const JOIN_ROOM = "JOIN_ROOM" // 加入房间
export const ENTER_ROOM = "ENTER_ROOM" // 进入房间
export const EXIT_ROOM = "EXIT_ROOM" // 退出房间
export const FINISH_ROOM = "FINISH_ROOM" // 结束会话
export const EX_EXIT_ROOM = "EX_EXIT_ROOM" // 展商退出房间

// 聊天消息
export const CHAT_TEXT = "CHAT_TEXT" // 聊天文字消息
export const CHAT_EMOJI = "CHAT_EMOJI" // 聊天表情消息
export const CHAT_IMG = "CHAT_IMG" // 聊天图片消息
export const CHAT_FILE = "CHAT_FILE" // 聊天文件消息

// 视频消息
export const VIDEO_TALK_START = "VIDEO_TALK_START" // 视频洽谈开始
export const VIDEO_TALK_END = "VIDEO_TALK_END" // 视频洽谈结束

// ppt消息
export const PPT_START = "PPT_START" // 开始分享ppt
export const PPT_SWITCH = "PPT_SWITCH" // 切换ppt
export const PPT_END = "PPT_END" // 结束分享ppt
