import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import Request from '@/utils/request'
import ppo from 'ppo'
import intl from 'react-intl-universal'
import {CLIENT_DESKTOP_APP} from '@/config/index'
import SelectLang from '@/components/SelectLang/index'
import Loading from '@/components/Loading'
import QRCode from 'qrcode.react'
import { useRequest } from 'ahooks'
import type { globalTypes  } from '@/store/store.d'
import {Input, Button, message, Space} from 'antd'
import clsx from "clsx"
import styles from './verify.module.scss'

type marketInfoType = {
  logo: string,
  id: number,
  url: string,
  market_url: string,
  mina_name: string,
}

type exhibitorInfoType = {
  logo: string,
  connect_company_name: string,
  connect_company_name_en: string,
  connect_id: number,
}

export type VerifyPropsTypes = {
  locale: string,
}

const Verify: React.FC<VerifyPropsTypes> = (props) => {
  const history = useHistory()
  const { locale } = props
  const [code, setCode] = useState('')
  const [hasExhibitor, setHasExhibitor] = useState(false)
  const [marketInfo, setMarketInfo] = useState<marketInfoType | null>(null)
  const [exhibitorInfo, setExhibitorInfo] = useState<exhibitorInfoType | null>(null)
  const [scanMark, setScanMark] = useState<string | null>(null) // 扫码标记唯一key
  const inputRef = useRef(null)
  // const [isDemoScanQrcode] = useState(false)
  const [isDemoScanQrcode] = useState(localStorage.getItem('demo') === 'scan_qrcode')
  
  // 检查扫码状态
  const checkScanCodeStatu = async () => {
    return await Request({
      // url: `offline_walkin/check_login/${scanMark}?debug=1&type=0`
      url: `offline_walkin/check_login/${scanMark}`
    });
  }

  const { data, run, cancel } = useRequest(checkScanCodeStatu, {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false,
  });

  // 进入洽谈
  const enter = async () => {
    if (!code.trim()) {
      message.warning(intl.get('home.no_input'))
    } else {
      const { status, data} = await Request({
        url: `code/check`,
        method: 'POST',
        data: { code }
      })
      try {
        if (status && data) {
          console.log('enter', data)
          location.href = data.url
          // props.history.push(data.url)
        } else {
          message.warning(intl.get('home.error_code'))
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  // 获取展会信息
  const getMarketInfo = async (marketId: number) => {
    try {
      const {data, status} = await Request({
        url: `market_info/${marketId}`
      });
      if (status && data) {
        setMarketInfo(data)
      }
    } catch (error:any) {
      console.log('getMarketInfo -> error', error);
    }
  }

  // 获取展商信息
  const getExhibitorInfo = async (marketId: number, exhibitorId: number) => {
    try {
      const {data, status} = await Request({
        url: `market_merchant_info/${marketId}/${exhibitorId}`
      });
      if (status && data) {
        setExhibitorInfo(data)
      }
    } catch (error:any) {
      console.log('getExhibitorInfo -> error', error);
    }
  }

  // 跟新扫码标记
  const updateScanMark = () => {
    if(marketInfo && exhibitorInfo){
      setScanMark(`${marketInfo.id}${exhibitorInfo.connect_id}${dayjs().format('HHmmss')}`)
    }
  }

  useEffect(() => {
    if(marketInfo && exhibitorInfo && isDemoScanQrcode){
      updateScanMark()
      run()
    }
    if(marketInfo && inputRef){
      (inputRef as any).current!.focus()
    }
  }, [marketInfo, exhibitorInfo])
  
  useEffect(() => {
    if(data && data.status && data.data){
      const { type, url } = data.data
      switch(type){
        case 0:
          break;
        case 1:
          message.success(intl.get(`desktop.check_scan_status.${type}`))
          cancel()
          window.location.href = url
          break;
        default:
          message.error(intl.get(`desktop.check_scan_status.${type}`))
          updateScanMark()
          break;
      }
    }
  }, [data])

  useEffect(() => {
    console.log('isDemoScanQrcode', isDemoScanQrcode)
    const clientDesktopApp = localStorage.getItem('clientDesktopApp')
    if(clientDesktopApp && clientDesktopApp === CLIENT_DESKTOP_APP){
      // 来自桌面端app
      const marketId = ppo.getUrlParam('marketId') || localStorage.getItem('marketId')
      const exhibitorId = ppo.getUrlParam('exhibitorId') || localStorage.getItem('exhibitorId')
      if(marketId && exhibitorId){
        setHasExhibitor(true)
        localStorage.setItem('marketId', marketId)
        localStorage.setItem('exhibitorId', exhibitorId)
        getMarketInfo(marketId)
        getExhibitorInfo(marketId, exhibitorId)
      }
    }
    return () => {
      cancel()
    }
  }, [])

  return (
    <div className={styles['chat-verify']}>
      <div className={styles['verify-hd']}>
        <div className={styles['hd__content']}>
          {
            marketInfo &&
            <img className={styles['logo']} src={marketInfo.logo} alt="" />
          }
        </div>
        <SelectLang />
      </div>
      <div className={styles['content']}>
        <Space direction="vertical" size="large" >
          <div className={styles['logo']}>
            <img className={styles['logo__img']} src={!hasExhibitor ? "/logo.png" : exhibitorInfo ? exhibitorInfo?.logo : ""} alt="" />
            {
              hasExhibitor && exhibitorInfo &&
              <h3 className={styles['company__name']}>{exhibitorInfo[locale === 'en-US' ? 'connect_company_name_en' : 'connect_company_name']}</h3>
            }
          </div>
          {
            hasExhibitor && !marketInfo &&
            <Loading size="large"/>
          }
        </Space>
        <div className={styles['entry-ways']}>
          {
            (!hasExhibitor || marketInfo) &&
            <Space direction="vertical" className={styles['entry_item']} size="large">
              <h4>{intl.get(hasExhibitor ? 'desktop.code_tip' : 'home.input_code')}</h4>
              <Input ref={inputRef} size="large" onChange={(e: any) => setCode(e.target.value)} onPressEnter={enter}/>
              <Button size="large" type="primary" block onClick={() => enter()}>{intl.get('home.enter')}</Button>
            </Space>
          }
          {
            isDemoScanQrcode &&
            hasExhibitor &&
            marketInfo &&
            exhibitorInfo &&
            scanMark &&
            <Space direction="vertical" className={clsx(styles['entry_item'], styles['__qrcode'])}>
              <h4>{intl.get('desktop.qrcode_tip', { mina_name: marketInfo.mina_name})}</h4>
              <QRCode
                value={`${marketInfo.market_url}/${marketInfo.url}/talk_scan?connect_id=${exhibitorInfo.connect_id}&scan_mark=${scanMark}`}
                size={160}
              />
            </Space>
          }
        </div>
      </div>
    </div>
  )
}

const stateToProps = (state: {
  global: globalTypes,
}) => {
  const { global } = state
  return {
    locale: global.locale,
  }
}

export default connect(stateToProps, null)(Verify)
