import React, { useRef, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'
import ppo from 'ppo'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import FullLoading from '@/components/FullLoading'
import { Button, Result, message } from 'antd'
import Request from '@/utils/request'
import type {translationInfoTypes} from './index.d'

type PreviewCustomerJoinPropsTypes = {

}

let timer:any = null;
const PreviewCustomerJoin: React.FC<PreviewCustomerJoinPropsTypes> = (props) => {
  const history = useHistory()
  const [translationInfo, setTranslationInfo] = useState<translationInfoTypes | null>(null)
  const [roomEnd, setRoomEnd] = useState(false) // 房间已结束

  const getTranslationInfo = async () => {
    const token = ppo.getUrlParam('token')
    if(token){
      const { status, data} = await Request({
        url: `translation/join`,
        method: 'POST',
        data: { token }
      })
      try {
        if (status && data) {
          setTranslationInfo(data)
        }
      } catch (error) {
        console.log('getTranslationInfo->error', error)
      }
    }
  }

  const getRoomInfo = async () => {
    if(translationInfo){
      const roomId = translationInfo.talkInfo.room_id
      const {status, data } = await Request({
        url: `rooms/${roomId}`,
      })
      try {
        if (status && data) {
          console.log(data)
          const { history_users, status } = data
          const merchantEnter = history_users.find((item: {type: number}) => item.type === 4)
          if(status && merchantEnter){
            history.push(`/talk/${roomId}?source=translation`)
          } else {
            if(!status){
              setRoomEnd(true)
            } else {
              loopQuery()
            }
          }
        } else {
          loopQuery()
        }
      } catch (error) {
        console.log('getRoomInfo->error', error)
      }
    }
  }

  const clearTimer = () => {
    if(timer){
      window.clearTimeout(timer)
      timer = null
    }
  }

  const loopQuery = () => {
    timer = setTimeout(() => {
      getRoomInfo()
    }, 8000)
  }

  useEffect(() => {
    translationInfo && getRoomInfo()
  }, [translationInfo])

  useEffect(() => {
    getTranslationInfo()
    return clearTimer
  }, [])

  return(
    <>
      {
        !translationInfo ?
        <FullLoading size="large" />:
        <Result
          status="info"
          title={intl.get('preview_customer_join.title', {
            'user_name': translationInfo.userTalkLog.user.name,
            'company_name': translationInfo.userTalkLog.merchant.connect_company_name,
          })}
          subTitle={intl.get('preview_customer_join.subtitle', {
            'start_at': translationInfo.userTalkLog.talk.original_start_at,
            'end_at': translationInfo.userTalkLog.talk.original_end_at,
          })}
          extra={
            [36, 37].includes(translationInfo.userTalkLog.status)?
            <Button>{intl.get('preview_customer_join.status_error')}</Button>:
            <h3>{intl.get(`preview_customer_join.${roomEnd ? 'end' : 'wait'}`)}</h3>
          }
        />
      }
    </>
  );
}

export default PreviewCustomerJoin