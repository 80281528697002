import React, { useState, forwardRef, useImperativeHandle} from 'react'
import intl from 'react-intl-universal'
import { connect, useDispatch } from 'react-redux'
import {
  setVidoeTalkOpen,
  setSettingOpen,
  setCameraID,
  setMicrophoneID,
  setAudio,
  setVideo } from '@/store/actions'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import type {videoTalkTypes, userInfoTypes, roomInfoTypes} from '@/store/store.d'
import type { messageTypes, sendInfoTypes } from '@/utils/IMSService/index.d'
import clsx from 'clsx'
import { Modal, Button, Space, Switch, message } from 'antd'
import IconFont from '@/components/IconFont'
import DeviceSelect from '@/components/VideoTalk/DeviceSelect'
import styles from './index.module.scss'
import type { SetUpModalRefProps } from '@/components/VideoTalk/SetUpModal/index.d'
import room from '@/pages/talk/room'

// 组件参数
interface SetUpModalPropsTypes extends videoTalkTypes {
  roomInfo?: roomInfoTypes,
  userInfo?: userInfoTypes,
  videoTalk?: videoTalkTypes,
  sendMsg: (message: messageTypes, callBackFn?: (msgInfo: sendInfoTypes | false) => void) => void
}

const SetUpModal = forwardRef<SetUpModalRefProps, SetUpModalPropsTypes>((props, childRef) => {
  const dispatch = useDispatch()
  const { userInfo, videoTalk, sendMsg, roomInfo} = props

  useImperativeHandle(childRef, () => ({
    // 接受新消息
    open: () => {
      dispatch(setSettingOpen(true))
    }
  }))

  // 设置对话框关闭
  const handleCancel = () => {
    dispatch(setSettingOpen(false))
  }

  // 设置对话框确定
  const handleOk = () => {
    dispatch(setSettingOpen(false))
    dispatch(setVidoeTalkOpen(true))
    if(userInfo?.type === 4 && !roomInfo?.vidoeState){
      sendMsg({
        msgType: msgTypes.VIDEO_TALK_START,
      })
    }
  }

  return(
    <Modal
      title={intl.get('pre_enter.setting_tip')}
      visible={videoTalk?.settingOpen}
      width={600}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className={styles['pre-enter']}>
        <div className={styles['setting-main']}>
          <div className={styles['space__item']}>
            <Space className={clsx(styles['item__cell'], styles['__state'])}>
              <Space className={styles['cell__lable']} align="center">
                <IconFont size="large" type="icon-videocam"/>
                {intl.get('video_talk.camera')}
              </Space>
              <Switch
                defaultChecked={videoTalk?.video}
                onChange={(checked: boolean) => dispatch(setVideo(checked))}/>
            </Space>
            <div className={styles['item__cell']}>
              <div className={styles['cell__lable']}>{intl.get('video_talk.equipment', {name: intl.get('video_talk.camera')})}</div>
              <div className={styles['cell__select']}>
                <DeviceSelect deviceType="camera" onChange={(value: string) => dispatch(setCameraID(value))}></DeviceSelect>
              </div>
            </div>
          </div>
          <div className={styles['space__item']}>
            <Space className={clsx(styles['item__cell'], styles['__state'])} align="center">
              <Space className={styles['cell__lable']} align="center">
                <IconFont size="large" type="icon-mic"/>
                {intl.get('video_talk.microphone')}
              </Space>
              <Switch
                defaultChecked={videoTalk?.audio}
                onChange={(checked: boolean) => dispatch(setAudio(checked))}/>
            </Space>
            <div className={styles['item__cell']}>
              <div className={styles['cell__lable']}>{intl.get('video_talk.equipment', {name: intl.get('video_talk.microphone')})}</div>
              <div className={styles['cell__select']}>
                <DeviceSelect deviceType="microphone" onChange={(value: string)  => dispatch(setMicrophoneID(value))}></DeviceSelect>
              </div>
            </div>
          </div>
          <div className={clsx(styles['space__item'], styles['space__item__footer'])}>
            <Button type="primary" size="large" shape="round" onClick={handleOk}>{intl.get('pre_enter.start_video_talk')}</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default SetUpModal