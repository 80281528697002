import ppo from 'ppo'
import dayjs from 'dayjs'
import SparkMD5 from 'spark-md5'
import { DEFAULT_LOCALE } from '@/config/index'
import * as msgTypes from '@/utils/IMSService/msgTypes'
import type { sendInfoTypes, chatRoomMsgTypes } from '@/utils/IMSService/index.d'

/**
 * @description: 判断是否是小程序
 * @param {*}
 * @return {Boolean} 是否是小程序端
 */
export const isMina = ():boolean => {
  return ppo.isWeixin() && ppo.ua('l').includes('miniprogram')
}

/**
 * @description: 判断是否是移动端
 * @param {*}
 * @return {Boolean} 是否是移动端
 */
 export const isMobile = ():boolean => {
  return ppo.isMobile()
}

// 文件的类型
export type fileTypes = {
  size: number,
  prototype: {
    mozSlice?: (file: any, start:number, end: number) => any,
    webkitSlice?: (file: any, start:number, end: number) => any,
    slice?: (file: any, start:number, end: number) => any,
  }
}

/**
 * @description: 获取文件拓展名
 * @param {string} filename
 * @return {*}
 */
export const getExtName = (filename: string) => {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
}


/**
 * @description: 获取文件大小
 * @param {number} bytes
 * @param {2} decimals
 * @return {string} 返回文件的大小
 */
const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const K = 1024;
export const prettyBytes = (bytes: number, decimals?: 2): string => {
  if (bytes < 1) {
    return `${bytes} ${UNITS[0]}`
  }
  const dm = decimals
  const i = Math.floor(Math.log(bytes) / Math.log(K))
  return `${parseFloat((bytes / Math.pow(K, i)).toFixed(dm))}${UNITS[i]}`
};

// 获取文件的md5
export const getMd5 = (file: fileTypes, cal:(prop: any) => void)=>{
  let fileReader = new FileReader();
  let blobSlice = file.prototype?.mozSlice || file.prototype?.webkitSlice || file.prototype?.slice,
      chunkSize = 2097152,
      // read in chunks of 2MB
      chunks = Math.ceil(file.size / chunkSize), currentChunk = 0,
      spark = new SparkMD5();
  fileReader.onload = (e: any)=>{
    spark.appendBinary(e.target.result);
    currentChunk++;
    if(currentChunk < chunks){
      loadNext();
    }else{
      cal(spark.end());
    }
  }
  let loadNext = ()=>{
    let start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;
    // fileReader.readAsBinaryString(blobSlice.call(file, start, end));
  }
  loadNext()
}

// 格式化聊天消息
export const formatChatMsg = (msg: sendInfoTypes, userId: number | string, preMsgCreatedAt: number = 0):chatRoomMsgTypes => {
  const { header, body } = msg
  const { sender } = body
  const msgType = body.message.msgType
  return {
    _id: header.msgId || ppo.uuid(),
    type: msgType,
    content: body.message.content,
    createdAt: header.createdAt,
    sender,
    user: [msgTypes.CHAT_TEXT, msgTypes.CHAT_EMOJI, msgTypes.CHAT_IMG, msgTypes.CHAT_FILE].includes(msgType) ? sender : undefined,
    position: header.isCurClient && sender.id == userId ? 'right' : 'left',
    hasTime: !preMsgCreatedAt ? true : dayjs(preMsgCreatedAt).add(1, 'minute').isBefore(header.createdAt),
  }
}

/**
 * @description: 获取对象的某个值 这里声明了两个泛型 T 和 K T 代表函数第一个参数的类型，K 代表函数第二个参数的类型这个类型指向第一个参数类型中包含的key的值
 * @param {Object} T 
 * @param {String} K
 * @return {*}
 */
export const getObjectValue = <T, K extends keyof T>(obj:T, key:K):T[K] => {
  return obj[key]
}

/**
 * 功能: 获取浏览器语言, 默认返回 'zh-CN'（中文）
 */
type includesLang =  'zh-CN' | 'zh-hant' | 'en-US' | 'zh-CN';
export const getLanguage = ():includesLang => {
  let language = ppo.getCookie('kankan-lang') || ppo.getUrlParam('lang') || navigator.language || DEFAULT_LOCALE
  language = language.replace(/_/, '-').toLowerCase()
  switch(language){
    case 'zh-cn':
    case 'zh':
      language = 'zh-CN'
      break;
    case 'zh-hk':
    case 'zh-tw':
    case 'zh-hant':
    case 'zh_hant':
      language = 'zh-hant'
      break;
    case 'ja-jp':
    case 'ja':
    case 'jp':
      language = 'ja-JP'
      break;
    case 'en':
    case 'en-us':
    case 'en-gb':
      language = 'en-US'
      break;
    default:
      language = 'zh-CN'
      break;
  }
  return language
}