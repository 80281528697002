import React from 'react'
import clsx from 'clsx'
import styles from './index.module.scss'

export type IconButtonProps = {
  icon: React.ReactNode
  className?: string
  name?: string,
  onClick?: () => void,
};

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { className, icon, name, onClick } = props
  return(
    <div
      className={clsx(styles['icon-button'], className)}
      onClick={onClick}>
      { icon }
      <div className={styles.__text}>{name}</div>
    </div>
  );
};

export default IconButton;
