import Request from '@/utils/request'
import dayjs from 'dayjs'
import * as types from './types'
import type {
  globalDispatch,
  videoTalkDispatch,
  userInfoTypes,
  userInfoDispatch,
  roomInfoTypes,
  roomInfoDispatch,
  pptSateTypes,
  userTypes,
} from './store.d'
import { indexOf } from 'lodash-es'

// 切换语言
export const switchLocale = (locale: string) => (dispatch: globalDispatch) => {
  dispatch({
    type: types.GLOBAL_LOCALE,
    payload: {
      locale,
    },
  })
}

// 切换洽谈方式
export const switchTalkType = (talkType: number) => (dispatch: globalDispatch) => {
  dispatch({
    type: types.GLOBAL_TALK_TYPE,
    payload: {
      talkType,
    },
  })
}

// 设置设备id
export const setGlobalClientId= (clientId: string) => (dispatch: globalDispatch) => {
  dispatch({
    type: types.GLOBAL_CLIENT_ID,
    payload: {
      clientId,
    },
  })
}

// 设置视频洽谈状态
export const setInitState = (initState: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_INIT_STATE,
    payload: {
      initState,
    },
  })
}

// 设置视频洽谈设置打开状态
export const setSettingOpen = (settingOpen: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_SETTING_OPEN,
    payload: {
      settingOpen,
    },
  })
}

// 设置视频洽谈打开状态
export const setVidoeTalkOpen = (vidoeTalkOpen: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_OPEN,
    payload: {
      vidoeTalkOpen,
    },
  })
}


// 设置视频洽谈摄像头id
export const setCameraID = (cameraID: string) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_CAMERA_ID,
    payload: {
      cameraID,
    },
  })
}

// 设置麦克风id
export const setMicrophoneID = (microphoneID: string) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_MICROPHONE_ID,
    payload: {
      microphoneID,
    },
  })
}

// 设置视频洽谈音频状态
export const setAudio = (audio: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_AUDIO,
    payload: {
      audio,
    },
  })
}

// 设置视频洽谈视频状态
export const setVideo = (video: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_VIDEO,
    payload: {
      video,
    },
  })
}

// 设置视频洽谈视频静音状态
export const setMutedVideo = (mutedVideo: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_MUTED_VIDEO,
    payload: {
      mutedVideo,
    },
  })
}

// 设置视频洽谈音频静音状态
export const setMutedAudio = (mutedAudio: boolean) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_MUTED_AUDIO,
    payload: {
      mutedAudio,
    },
  })
}

// 设置ppt打开状态
export const setPPTSate = (pptSate: pptSateTypes) => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.VIDEO_TALK_PPT_STATE,
    payload: {
      pptSate,
    },
  })
}

// 重置视频状态
export const resetVideoTalkState = () => (dispatch: videoTalkDispatch) => {
  dispatch({
    type: types.RESET_VIDEO_TALK_STATE,
    payload: {
    },
  })
}

// 设置用户信息
export const setUserInfo = (userInfo: userInfoTypes) => (dispatch: userInfoDispatch) => {
  dispatch({
    type: types.USER_INF0,
    payload: {
      ...userInfo,
    },
  })
}

// 获取用户信息
export const getUserInfo = (token: string, success?: (user: userInfoTypes) => void, fail?: () => void) => async (dispatch: userInfoDispatch) => {
  // 登录用户
  const {status, data } = await Request({
    method: 'POST',
    url: `auth/login`,
    data: {
      token
    }
  })
  try {
    if(status && data){
      data.api_token && sessionStorage.setItem("API_TOKEN", data.api_token)
      dispatch({
        type: types.USER_INF0,
        payload: {
          id: data.id,  //发送者id
          cta_id: data.cta_id,  //用户cta_id
          type: data.type || 0,
          name: data.name || '', //用户名
          company: data.company || '', // 公司名
          avatar: data.avatar || '', // 用户头像
        },
      })
      success && success(data)
    } else {
      console.error('登录失败')
      fail && fail()
    }
  } catch (error) {
    console.log('getUserInfo-error', error)
  }
}

// 获取房间信息信息
export const getRoomInfo = (roomId: string, success?: (roomInfo: roomInfoTypes) => void, fail?: () => void, isUpdate: boolean = false) => async (dispatch: roomInfoDispatch) => {
  if(!isUpdate){
    dispatch({
      type: types.ROOM_INFO,
      payload: null,
    })
  }
  const {status, data } = await Request({
    url: `rooms/${roomId}`,
  })
  try {
    if(status && data){
      const talkSetting = data.talk_setting
      let isBeforeEnter = false
      // 调试增加
      // data.start_at = dayjs('2023-02-23 10:52:00').valueOf()
      // data.end_at = dayjs(data.start_at).add(10, 'minute')
      if(talkSetting){
        if(!talkSetting?.forward_start_status){
          if(dayjs().isBefore(dayjs(data?.start_at))){
            isBeforeEnter = true
          }
        } else {
          if(talkSetting?.forward_start_time !== 0 && dayjs().isBefore(dayjs(data?.start_at).subtract(talkSetting?.forward_start_time, 'minute'))){
            isBeforeEnter = true
          }
        }
      }
      const now = dayjs().valueOf()
      let roomInfo:{
        isEnd?: boolean,
        [prop:string]: any
      } = {
        avatar: data.avatar || '', // 创建者头像
        creatorId: data.creator_id || 0, // 创建者头像
        name: data.name || "", // 创建者名字
        roomId: data.room_id || "",// 房间头像
        startAt: data?.start_at || now, // 开始时间
        endAt: data.end_at || 0, // 结束时间
        status: data.status || 0, // 房间状态
        userId: data.user_id || 0, // 创建者用户id,
        lasPptMessage: data.last_ppt_message,
        users: data.users,
        historyUsers: data.history_users,
        vidoeState: Boolean(data.video_state),
        talk_setting: talkSetting,
        isBeforeEnter: isBeforeEnter,
      }
      if(!isUpdate){
        const isEnd = !data.status && dayjs().isAfter(dayjs(data.end_at))
        roomInfo.isEnd = isEnd
      }
      console.log('store->roomInfo', roomInfo)
      // 调试
      // roomInfo.startAt = dayjs().valueOf()
      // roomInfo.talk_setting.forward_end_time = 1
      // roomInfo.endAt = dayjs().add(0.1, 'minute')
      // roomInfo.talk_setting.countdown_status = false;
      dispatch({
        type: types.ROOM_INFO,
        payload: roomInfo,
      })
      success && success(roomInfo)
    } else {
      // 房间号失效或是不存在
      console.log('房间号失效或是不存在')
      fail && fail()
    }
  } catch (error) {
    console.log('error', error)
  }
}

// 设置房间信息
export const setRoomInfo = (roomInfo: null) => (dispatch: roomInfoDispatch) => {
  dispatch({
    type: types.ROOM_INFO,
    payload: roomInfo,
  })
}

// 设置房间视频洽谈状态
export const setRoomVidoeState = (vidoeState: boolean) => (dispatch: roomInfoDispatch) => {
  dispatch({
    type: types.SET_ROOM_VIDOE_STATE,
    payload: {
      vidoeState,
    },
  })
}

// 增加房间成员
export const addRoomMembers = (users: userInfoTypes[]) => (dispatch: roomInfoDispatch) => {
  dispatch({
    type: types.ADD_ROOM_USERS,
    payload: {
      users,
    },
  })
}

// 删除房间成员
export const removeRoomMember = (user: userInfoTypes) => (dispatch: roomInfoDispatch) => {
  dispatch({
    type: types.REMOVE_ROOM_USER,
    payload: {
      user,
    },
  })
}

// 更新房间成员
export const updateRoomMembers = (roomId: string, callback?:(data: userTypes[]) => void) => async(dispatch: roomInfoDispatch) => {
  const {status, data} = await Request({
    url: `${roomId}/business-card`
  })
  try {
    if(status && data){
      console.log('updateRoomMembers->data', data)
      dispatch({
        type: types.UPDATE_ROOM_MEMBERS,
        payload: {
          members: data
        },
      })
      callback && callback(data)
    }
  } catch (error) {
    console.log('updateRoomMembers -> error', error)
  }
}