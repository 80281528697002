// 改善美容 RTC 客户端
import RTC from './BaseRTC.js'
import RTCBeautyPlugin from 'rtc-beauty-plugin'
import {message} from 'antd'
  
export default class RTCClient extends RTC {
  async handlePublish() {
    if (!this.isJoined || this.isPublishing || this.isPublished) {
      return;
    }
    this.isPublishing = true;
    !this.localStream && (await this.initLocalStream());
    try {
      if(this.localStream.video_){
        this.beautyPlugin = new RTCBeautyPlugin();
        this.beautyPlugin.setBeautyParam({ beauty: 0.5, brightness: 0.5, ruddy: 0.5 });
        const stream = this.beautyPlugin.generateBeautyStream(this.localStream);
        await this.client.publish(stream);
      } else {
        await this.client.publish(this.localStream);
      }
      // message.success('publish localStream success!');
      this.isPublishing = false;
      this.isPublished = true;
      this.setState && this.setState('publish', this.isPublished);
    } catch (error) {
      this.isPublishing = false;
      // console.error('publish localStream failed', error);
      // message.error('publish localStream failed!');
    }
  }
}
