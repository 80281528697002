import { combineReducers } from 'redux'
import { uniqBy } from 'lodash-es'
import * as types from './types'
import type {
  userInfoTypes,
  globalDispatchParams,
  videoTalkDispatchParams,
  userInfoDispatchParams,
  roomInfoDispatchParams,
} from './store.d'
import { getLanguage, isMobile } from '@/utils'
import { clientId } from '@/utils/IMSService/index'

// 初始全局状态
const initialGlobalState = {
  locale: getLanguage(), // 本地语言,
  isMobile: isMobile(),
  talkType: isMobile() ? 2 : 1,  // 当前洽谈方式 1: all; 2: video; 3: text
  clientId,
}

// 全局Reducer
const globalReducer = (state = initialGlobalState, params: globalDispatchParams) => {
  const { type, payload } = params
  let newState = JSON.parse(JSON.stringify(state))  
  switch (type) {
    case types.GLOBAL_LOCALE:
      newState.locale = payload.locale
      break
    case types.GLOBAL_TALK_TYPE:
      newState.talkType = payload.talkType
      break
    case types.GLOBAL_CLIENT_ID:
      newState.clientId = payload.clientId
      break
    default:
      break
  }
  return newState;
}

// 初始视频洽谈状态
const initialVideoTalkState = {
  initState: false, // 设置初始化
  vidoeTalkOpen: false, // 视频洽谈状态是否打开
  settingOpen: false, // 设置弹层打开状态
  cameraID: '',// 摄像头id
  microphoneID: '', //麦克风id
  audio: true, //音频状态
  video: true, //视频状态,
  mutedVideo: false, //关闭视频,
  mutedAudio: false, //关闭音频,
  pptSate: {
    open: false,
    index: 0, //所选ppt
    image: '',//所选ppt图片
  }, //PPT状态,
}

// 视频洽谈Reducer
const videoTalkReducer = (state = initialVideoTalkState, params: videoTalkDispatchParams) => {
  const { type, payload } = params
  let newState = JSON.parse(JSON.stringify(state))
  switch (type) {
    case types.VIDEO_TALK_INIT_STATE:
      newState.initState = payload.initState
      break
    case types.VIDEO_TALK_SETTING_OPEN:
      newState.settingOpen = payload.settingOpen
      break
    case types.VIDEO_TALK_OPEN:
      newState.vidoeTalkOpen = payload.vidoeTalkOpen
      break
    case types.VIDEO_TALK_CAMERA_ID:
      newState.cameraID = payload.cameraID
      break
    case types.VIDEO_TALK_MICROPHONE_ID:
      newState.microphoneID = payload.microphoneID
      break
    case types.VIDEO_TALK_AUDIO:
      newState.audio = payload.audio
      break
    case types.VIDEO_TALK_VIDEO:
      newState.video = payload.video
      break
    case types.VIDEO_TALK_MUTED_VIDEO:
      newState.mutedVideo = payload.mutedVideo
      break
    case types.VIDEO_TALK_MUTED_AUDIO:
      newState.mutedAudio = payload.mutedAudio
      break
    case types.VIDEO_TALK_PPT_STATE:
      newState.pptSate = Object.assign({}, newState.pptSate, payload.pptSate)
      break
    case types.RESET_VIDEO_TALK_STATE:
      newState = initialVideoTalkState
      break
    default:
      break
  }
  return newState
}

// 初始用户信息
const initialUserInfoState = null

// 用户信息Reducer
const userInfoReducer = (state = initialUserInfoState, params: userInfoDispatchParams) => {
  const { type, payload } = params
  let newState = JSON.parse(JSON.stringify(state))
  switch (type) {
    case types.USER_INF0:
      newState = payload ? {
        ...payload
      } : null
      break
    default:
      break
  }
  return newState;
}

// 初始房间信息
const initialRoomInfoState = null

// 房间信息Reducer
const roomInfoReducer = (state = initialRoomInfoState, params: roomInfoDispatchParams) => {
  const { type, payload } = params
  let newState = JSON.parse(JSON.stringify(state))
  switch (type) {
    case types.ROOM_INFO:
      newState = payload ? {...newState, ...payload} : null
      break
    case types.ADD_ROOM_USERS:
      if(payload?.users){
        newState.users = uniqBy([...newState.users, ...payload.users], 'id')
      }
      break
    case types.REMOVE_ROOM_USER:
      if(payload?.user){
        newState.users = newState.users.filter((item: userInfoTypes) => payload?.user?.id !== item.id)
      }
      break
    case types.SET_ROOM_VIDOE_STATE:
      newState.vidoeState = payload?.vidoeState
      break
    case types.UPDATE_ROOM_MEMBERS:
      if(newState){
        newState.members = payload?.members
      } else {
        newState = {members: payload?.members}
      }
      break
    default:
      break
  }
  return newState;
}

// 联合reducers
const reducers = {
  global: globalReducer,
  videoTalk: videoTalkReducer,
  userInfo: userInfoReducer,
  roomInfo: roomInfoReducer,
}

export default combineReducers(reducers)