import React, { useState, useEffect } from 'react'
import type { DeviceSelectTypes, deviceItemTypes} from './index.d'
import DynamicDeviceData from './DeviceData'
import { Select } from 'antd'
const { Option } = Select

const DeviceSelect:React.FC<DeviceSelectTypes> = (props) => {
  const { deviceType , onChange } = props
  const [deviceList, setDeviceList] = useState<deviceItemTypes[]>([])
  const [activeDevice, setActiveDevice] = useState<deviceItemTypes | null>(null)
  const [activeDeviceId, setActiveDeviceId] = useState('')

  const updateDeviceList = (list:deviceItemTypes[]) => {
    setDeviceList((prevList:deviceItemTypes[]) => {
      if (prevList.length === 0 && list.length) {
        setActiveDevice(list[0])
        setActiveDeviceId(list[0].deviceId)
      }
      return list;
    });
  };

  useEffect(() => {
    if (activeDevice && activeDevice.deviceId) {
      onChange && onChange(activeDevice.deviceId)
    }
  }, [activeDevice])

  const handleChange = (deviceID: string) => {
    const activeDevice = deviceList.find((item:deviceItemTypes) => item.deviceId === deviceID)
    if(activeDevice){
      setActiveDevice(activeDevice)
      setActiveDeviceId(deviceID)
    }
  }

  return (
    <div style={{width: '100%'}}>
      <DynamicDeviceData
        deviceType={deviceType}
        updateDeviceList={updateDeviceList}>
      </DynamicDeviceData>
      {
        <Select value={activeDeviceId} style={{width: '100%'}} onChange={handleChange}>
          {
            deviceList.map((item:deviceItemTypes, index) => <Option value={item.deviceId} key={index}>{item.label}</Option>)
          }
        </Select>
      }
    </div>
  )
}

export default DeviceSelect
